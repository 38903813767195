import React, { Fragment, useState, useEffect } from "react";
import { getCustomerorderslist, deleteOrderDetails } from "../../utils/api";
import { Modal, Button } from "react-bootstrap";
import { FaSortUp, FaSortDown, FaSort } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const OrderList = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [email, setEmail] = useState("");
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const loginDataFromStorage = localStorage.getItem("userDetails");
  const parsedLoginData = JSON.parse(loginDataFromStorage);

  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateError, setDateError] = useState("");
  const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   document.title = "Order Lists"; // Set the document title
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const loginDataFromStorage = localStorage.getItem("userDetails");
        if (loginDataFromStorage) {
          const parsedLoginData = JSON.parse(loginDataFromStorage);
          setEmail(parsedLoginData.reseller_email);
          const response = await getCustomerorderslist(
            parsedLoginData.reseller_email,
            parsedLoginData.reseller_type
          );
          setOrders(response.data.data_result);
          setFilteredOrders(response.data.data_result);
        }
      } catch (error) {
        console.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSearchInputChange = (value) => {
    setSearchInput(value.toLowerCase());
    const filteredList = orders.filter((order) => {
      if (order) {
        return (
          (order.domain_name &&
            order.domain_name.toLowerCase().includes(value)) ||
          (order.case_name && order.case_name.toLowerCase().includes(value)) ||
          (order.customer_name &&
            order.customer_name.toLowerCase().includes(value)) ||
          (order.order_id && order.order_id.toLowerCase().includes(value))
        );
      }
      return false;
    });
    setFilteredOrders(filteredList);
  };

  const handleReset = () => {
    setSearchInput("");
    setStartDate(null);
    setEndDate(null);
    setFilteredOrders(orders);
  };

  const handleEndDateChange = (date) => {
    if (date) {
      const endDateWithTime = new Date(date);
      endDateWithTime.setHours(23, 59, 59, 999); // Set time to 23:59:59
      setEndDate(endDateWithTime);
    } else {
      setEndDate(null);
    }
  };

  const filterByDateRange = () => {
    if (startDate && endDate) {
      // Check if endDate is before startDate
      if (endDate < startDate) {
        setDateError("End date cannot be less than the start date."); // Set error message
        return;
      }

      setDateError(""); // Clear error message if dates are valid

      const filteredList = orders.filter((order) => {
        const orderDate = new Date(order.order_date);
        return orderDate >= startDate && orderDate <= endDate;
      });
      setFilteredOrders(filteredList);
    } else {
      setDateError(""); // Clear error message if no dates are selected
      setOrders(filteredOrders);
    }
  };
  const handleSort = (column) => {
    let sortedList = [...filteredOrders];
    if (sortColumn === column) {
      sortedList.reverse(); // Toggle the sorting order
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      sortedList.sort((a, b) => {
        if (a[column] < b[column]) return -1;
        if (a[column] > b[column]) return 1;
        return 0;
      });
      setSortColumn(column);
      setSortOrder("asc");
    }
    setFilteredOrders(sortedList);
  };

  const handleViewClick = (order) => {
    setSelectedOrder(order);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedOrder(null);
  };
  const handleDeleteOrder = async (order_id) => {
    try {
      setLoading(true);
      await deleteOrderDetails(order_id);
      setOrders((prevOrders) =>
        prevOrders.filter((order) => order.order_id !== order_id)
      );
      setFilteredOrders((prevFilteredOrders) =>
        prevFilteredOrders.filter((order) => order.order_id !== order_id)
      );
      window.alert("Order deleted successfully.");
      handleCloseModal(true);
    } catch (error) {
      console.error("Failed to delete order:", error);
    } finally {
      setLoading(false);
    }
  };

  const thStyles = {
    position: "sticky",
    top: 0,
    backgroundColor: "#f1f1f1",
    zIndex: 100,
  };

  return (
    <Fragment>
      <style>
        {`
          .sortable-header {
            cursor: pointer;
            position: relative;
          }

          .sort-icon {
            margin-left: 5px;
            color: #6c757d; /* Default color */
            transition: color 0.2s ease;
          }

          .sortable-header:hover .sort-icon {
            color: #007bff; /* Change this to your preferred hover color */
          }

          .table-responsive {
            max-height: 500px;
            overflow-y: auto;
          }
            .date-filter-container {
            display: flex;
            align-items: center;
            margin-left: 700px;
            gap: 10px;
            margin-bottom: 20px;
            position: relative;
            z-index: 1000; /* Ensure date picker is above other elements */
          }

          .date-filter-container .form-control {
            width: 150px;
          }

          .date-filter-container button {
            margin-left: 17px;
          }
            .text-danger {
            color: #dc3545;
          }
        `}
      </style>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="mb-1">Order List</h4>
            <br />
            <div className="search-container mb-3">
              <input
                type="text"
                placeholder="Search"
                value={searchInput}
                onChange={(e) => handleSearchInputChange(e.target.value)}
                style={{ float: "left" }}
              />
              <button
                className="btn btn-primary ml-2"
                onClick={handleReset}
                style={{ float: "left" }}
              >
                Reset
              </button>
              <div className="date-filter-container" style={{ float: "right" }}>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText="Start Date"
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                />
                <DatePicker
                  selected={endDate}
                  onChange={handleEndDateChange}
                  placeholderText="End Date"
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                />
                <button className="btn btn-primary" onClick={filterByDateRange}>
                  Filter
                </button>
              </div>
            </div>
            {dateError && <div className="text-danger mt-2">{dateError}</div>}
            <div
              className="table-responsive"
              style={{ maxHeight: "500px", overflowY: "auto" }}
            >
              <table className="table table-hover sticky-header">
                <thead>
                  <tr>
                    <th style={thStyles}>Order ID</th>
                    <th
                      style={thStyles}
                      className="sortable-header"
                      onClick={() => handleSort("customer_name")}
                    >
                      Customer Name
                      {sortColumn === "customer_name" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" /> // Default sort icon
                      )}
                    </th>
                    <th
                      style={thStyles}
                      className="sortable-header"
                      onClick={() => handleSort("domain_name")}
                    >
                      Domain Name
                      {sortColumn === "domain_name" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" /> // Default sort icon
                      )}
                    </th>
                    <th style={thStyles}>Order Date</th>
                    <th style={thStyles}>Order Total (with GST)</th>
                    <th style={thStyles}>Profit</th>
                    {/* <th style={thStyles}>Reseller ID</th> */}
                    <th style={thStyles}>Case Name</th>
                    <th style={thStyles}>Payment Status</th>
                    {/* <th style={thStyles}>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {filteredOrders.map((order) => (
                    <tr
                      key={order.order_id}
                      onClick={() => handleViewClick(order)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{order.order_id}</td>
                      <td>{order.customer_name}</td>
                      <td>{order.domain_name}</td>
                      <td>{new Date(order.order_date).toLocaleDateString()}</td>
                      <td>{order.final_price_wt_gst.toLocaleString()}</td>{" "}
                      <td>{order.discounted_price.toLocaleString()}</td>{" "}
                      {/* Format the price */}
                      {/* <td>{order.reseller_id}</td> */}
                      <td>{order.case_name}</td>
                      <td>Paid</td>
                      {/* <td>{renderActionButtons(order)}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {selectedOrder && (
        <Modal show={showModal} onHide={handleCloseModal} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>
              Order Details{" - "}
              {selectedOrder.order_id}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="form-sample">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      Customer Name:
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="customer_name"
                        value={selectedOrder.customer_name}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      Domain Name:
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="domain_name"
                        value={selectedOrder.domain_name}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      Customer Address:
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="customer_address"
                        value={selectedOrder.customer_address}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      Order Date:
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="order_date"
                        value={new Date(
                          selectedOrder.order_date
                        ).toLocaleString()}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      Customer Company Name:
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="customer_companyname"
                        value={selectedOrder.customer_companyname}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                {selectedOrder.case_name !== null && (
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">
                        Case Name:
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className="form-control"
                          id="case_id"
                          value={selectedOrder.case_name}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Reseller ID:</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="reseller_id"
                        value={selectedOrder.reseller_id}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      Plan Type:
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="plan_type"
                        value={selectedOrder.plan_type}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      Plan Duration:
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="plan_duration"
                        value={selectedOrder.plan_duration}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  <h5>Product Details</h5>
                  <div
                    style={{
                      maxHeight: "200px",
                      overflowY: "auto",
                      border: "2px solid #000",
                    }}
                  >
                    <table className="table">
                      <thead>
                        <tr
                          style={{
                            position: "sticky",
                            top: 0,
                            zIndex: 100,
                            backgroundColor: "#dee2e6",
                          }}
                        >
                          <th>SKU Name</th>
                          <th>User Counts</th>
                          <th>List Price</th>
                          <th>Shiviom Price</th>
                          <th>Subtotal</th>
                        </tr>
                      </thead>
                      <tbody style={{ paddingTop: "30px" }}>
                        {selectedOrder.prod_details.map((prod, index) => (
                          <tr key={index}>
                            <td>{prod.product_name}</td>
                            <td>{prod.user_count}</td>
                            <td>{Number(prod.unit_price).toLocaleString()}</td>
                            <td>
                              {Number(prod.shivaami_price).toLocaleString()}
                            </td>
                            <td>
                              {Number(prod.shivaami_subtotal).toLocaleString()}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br></br>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      Order Total (without GST):
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="final_price_wto_gst"
                        value={selectedOrder.total.toLocaleString()}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                {parsedLoginData.reseller_type === "Direct" && (
                  <>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-4 col-form-label">
                          Partner Discount:
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control"
                            id="partner_discount"
                            value={selectedOrder.discount}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {/* {parsedLoginData.reseller_type === "Direct" && (
                  <>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-sm-4 col-form-label">
                          Discounted Total:
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control"
                            id="discounted_price"
                            value={parseInt(
                              selectedOrder.discounted_price
                            ).toLocaleString()}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )} */}
              </div>

              {parsedLoginData.reseller_type === "Direct" &&
                selectedOrder.deal_discount !== null && (
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label
                          for="pr_pricewtgst"
                          className="col-sm-4 col-form-label"
                        >
                          Additional Deal Discount
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control"
                            id="additional_deal_discount"
                            value={
                              selectedOrder.deal_discount
                                ? selectedOrder.deal_discount
                                : "0%"
                            }
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label
                          for="pr_pricewtgst"
                          className="col-sm-4 col-form-label"
                        >
                          Final Discounted Price
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control"
                            id="final_discounted_price"
                            value={selectedOrder.final_discounted_price}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              {/* {parsedLoginData.reseller_type === "Indirect" && (
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">
                        Total Commission:
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className="form-control"
                          id="total_commission"
                          value={selectedOrder.commision_amount}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )} */}

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      Order Total (with 18% GST):
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="final_price_wt_gst"
                        value={selectedOrder.final_price_wt_gst.toLocaleString()}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              onClick={() => handleDeleteOrder(selectedOrder.order_id)}
            >
              Delete Order
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Fragment>
  );
};

export default OrderList;
