import React from "react";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid clearfix">
        <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">
          Copyright © Shiviom 2024
        </span>
        <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
          {" "}
          {/* <a href="#" target="_blank">
            Shiviom admin{" "}
          </a> */}
        </span>
      </div>
    </footer>
  );
};

export default Footer;
