import React, { Fragment, useState, useEffect } from "react";
import { verifyDetails } from "../../utils/api";
import { useCookies } from "react-cookie";
import { toast, ToastContainer } from "react-toastify";

const VerifyUserRegisterDetails = () => {
  const [ownerName, setOwnerName] = useState("");
  const [ownerEmail, setOwnerEmail] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [accountsContactName, setAccountsContactName] = useState("");
  const [accountsContactEmail, setAccountsContactEmail] = useState("");
  const [aadharCardFile, setAadharCardFile] = useState(null);
  const [panCardFile, setPanCardFile] = useState(null);
  const [status, setStatus] = useState({});
  const [userRegId, setUserRegId] = useState("");
  const [cookies] = useCookies(["formData"]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    document.title = "User Registration Details"; // Set the document title
    // Retrieve JSON data from the cookie
    const jsonData = cookies.formData;
    if (jsonData) {
      setUserRegId(jsonData.user_reg_id);
      setOwnerName(jsonData.owner_name);
      setOwnerEmail(jsonData.owner_email);
      setContactName(jsonData.contact_name);
      setContactEmail(jsonData.contact_email);
      setAccountsContactName(jsonData.accounts_contact_name);
      setAccountsContactEmail(jsonData.accounts_contact_email);
      if (jsonData.documents && jsonData.documents.length > 0) {
        setAadharCardFile(jsonData.documents[0].adhaar);
        setPanCardFile(jsonData.documents[1].pancard);
      }
    }
  }, [cookies.formData]);


  const handleAccept = (fieldName) => {
    console.log(fieldName + " accepted");
    setStatus((prevStatus) => ({
      ...prevStatus,
      [fieldName]: "Accepted",
      user_reg_id: userRegId,
    }));
    // Show toast notification for acceptance
    toast.success(`${fieldName} accepted`, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    // Update the color of the accept status to green
    const acceptElement = document.getElementById(
      fieldName.toLowerCase() + "-accept"
    );
    if (acceptElement) {
      acceptElement.style.color = "green";
    }
  };

  const handleViewFile = (fileUrl) => {
    const popupWidth = 600;
    const popupHeight = 400;
    const left = window.screen.width / 2 - popupWidth / 2;
    const top = window.screen.height / 2 - popupHeight / 2;
    const popupOptions = `width=${popupWidth}, height=${popupHeight}, top=${top}, left=${left}, resizable=yes, scrollbars=yes`;

    // Open the document in a popup window
    window.open(fileUrl, "Document Preview", popupOptions);
    return;
  };

  const handleReject = (fieldName) => {
    console.log(fieldName + " rejected");
    setStatus((prevStatus) => ({
      ...prevStatus,
      [fieldName]: "Rejected",
      user_reg_id: userRegId,
    }));
    // Show toast notification for rejection
    toast.error(`${fieldName} rejected`, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    // Update the color of the reject status to red
    const rejectElement = document.getElementById(
      fieldName.toLowerCase() + "-reject"
    );
    if (rejectElement) {
      rejectElement.style.color = "red";
    }

    // Special handling for Aadhar Card and Pan Card
    if (fieldName === "Aadhar Card") {
      const aadharCardElement = document.getElementById("aadhar-card-span");
      if (aadharCardElement) {
        aadharCardElement.style.color = "red";
      }
    } else if (fieldName === "Pan Card") {
      const panCardElement = document.getElementById("pan-card-span");
      if (panCardElement) {
        panCardElement.style.color = "red";
      }
    }
  };
  
  const handlePermanentlyReject = () => {
    // Prompt user for reason
    const reason = prompt("Please provide a reason for rejection (optional):");

    console.log("Permanently rejected");
    const rejectedStatus = {
      status: "permanently rejected",
      owner_name: "Rejected",
      owner_email: "Rejected",
      contact_name: "Rejected",
      contact_email: "Rejected",
      accounts_contact_name: "Rejected",
      accounts_contact_email: "Rejected",
      adhaar: "Rejected",
      pancard: "Rejected",
      user_reg_id: userRegId,
      reason: reason || "", // Include reason if provided, otherwise empty string
    };
    setStatus(rejectedStatus);
    console.log(rejectedStatus);

    // Call API to submit rejected status
    verifyDetails(rejectedStatus)
      .then(() => {
        window.alert("Status Updated Successfully");
        window.location.reload();
        window.close();
        // window.location.href = "/adminMainComponent";
      })
      .catch((error) => {
        console.error("Error submitting rejected status:", error);
        window.alert("Failed to update status: " + error.message);
      });
  };
  const handleSubmit = (buttonType) => {
    window.event.preventDefault();
    let buttonStatus = "";
    if (buttonType === "Reject") {
      buttonStatus = "rejected";
    } else if (buttonType === "Approved") {
      buttonStatus = "approved";
    }

    let reason = ""; // Initialize reason variable

    // Prompt user for reason only if button type is Reject or Permanently Rejected
    if (buttonType === "Reject" || buttonType === "Permanently Rejected") {
      reason = prompt("Please provide a reason for rejection (optional):");
    }
    // Check if status for all fields is provided
    const missingFields = [];
    if (!status["Owner Name"]) missingFields.push("Owner Name");
    if (!status["Owner Email"]) missingFields.push("Owner Email");
    if (!status["Contact Name"]) missingFields.push("Contact Name");
    if (!status["Contact Email"]) missingFields.push("Contact Email");
    if (!status["Accounts Contact Name"])
      missingFields.push("Accounts Contact Name");
    if (!status["Accounts Contact Email"])
      missingFields.push("Accounts Contact Email");
    if (!status["Aadhar Card"]) missingFields.push("Aadhar Card");
    if (!status["Pan Card"]) missingFields.push("Pan Card");

    // If any fields are missing status, show error message
    if (missingFields.length > 0) {
      const boldFields = missingFields
        .map((field) => `<b>${field}</b>`)
        .join(", ");
      const div = document.createElement("div");
      div.innerHTML = `Please provide status for the following fields: ${boldFields}`;
      alert(div.textContent || div.innerText); // This will safely extract the text from div
      return; // Don't proceed with form submission
    }

    setStatus((prevStatus) => ({
      ...prevStatus,
      status: buttonStatus,
      reason: reason || "", // Include reason if provided, otherwise empty string
    }));

    console.log("Form submitted with status:", {
      ...status,
      status: buttonStatus,
      reason: reason || "", // Include reason if provided, otherwise empty string
    });

    // Call API to submit status
    verifyDetails({
      ...status,
      status: buttonStatus,
      reason: reason || "", // Include reason if provided, otherwise empty string
    })
      .then((response) => {
        if (response.status === 200) {
          window.alert("Status Updated Successfully");
          window.location.reload(); // Refresh the page
          window.close();
          // window.location.href = "/adminMainComponent";
        } else {
          console.error("Failed to update status:", response.statusText);
          window.alert("Failed to update status: " + response.statusText);
        }
      })
      .catch((error) => {
        console.error("Error updating status:", error);
        window.alert("Failed to update status: " + error.message);
      });
  };
  const handleGoBack = () => {
    window.location.href = "/adminmaincomponent";
  };
  return (
    <Fragment>
      <ToastContainer />
      <div
        className="container-scroller"
        style={{ height: "100vh", overflowY: "auto" }}
      >
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth register-bg-1 theme-one">
            <div className="row w-100">
              <div className="col-lg-9 mx-auto text-center">
                {/* Back button on the left */}

                <h1 className="mb-1">Reseller Registration Details</h1>
                <button
                  className="btn btn-secondary mr-10"
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={handleGoBack}
                >
                  Back
                </button>
                <br></br>
                <div className="auto-form-wrapper">
                  <form onSubmit={handleSubmit}>
                    <div className="container">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="ownerName">Owner Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Owner Name"
                              value={ownerName}
                              readOnly
                              id="ownerName"
                              //   value={ownerName}
                              onChange={(e) => setOwnerName(e.target.value)}
                              // onAccept={() => handleAccept("Owner Name")}
                              // onReject={() => handleReject("Owner Name")}
                              //   fieldName="Owner Name"
                              status={status} // Pass status prop
                            />
                            <span
                              className={`accept ${
                                status["Owner Name"] === "Accepted"
                                  ? "accepted"
                                  : ""
                              }`}
                              style={{
                                cursor: "pointer",
                                fontSize: "20px",
                                marginRight: "5px",
                                color:
                                  status["Owner Name"] === "Accepted"
                                    ? "green"
                                    : "grey",
                                fontWeight:
                                  status["Owner Name"] === "Accepted"
                                    ? "bold"
                                    : "normal",
                              }}
                              onClick={() => handleAccept("Owner Name")}
                            >
                              ✔
                            </span>
                            <span
                              className={`reject ${
                                status["Owner Name"] === "Rejected"
                                  ? "rejected"
                                  : ""
                              }`}
                              style={{
                                cursor: "pointer",
                                fontSize: "20px",
                                marginRight: "20px",
                                color:
                                  status["Owner Name"] === "Rejected"
                                    ? "red"
                                    : "grey",
                                fontWeight:
                                  status["Owner Name"] === "Rejected"
                                    ? "bold"
                                    : "normal",
                              }}
                              onClick={() => handleReject("Owner Name")}
                            >
                              ✘
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="ownerEmail">Owner Email</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Owner Email"
                              value={ownerEmail}
                              readOnly
                              id="ownerEmail"
                              //   value={ownerName}
                              onChange={(e) => setOwnerEmail(e.target.value)}
                              // onAccept={() => handleAccept("Owner Email")}
                              // onReject={() => handleReject("Owner Email")}
                              fieldName="Owner Email"
                              status={status} // Pass status prop
                            />
                            <span
                              className={`accept ${
                                status["Owner Email"] === "Accepted"
                                  ? "accepted"
                                  : ""
                              }`}
                              style={{
                                cursor: "pointer",
                                fontSize: "20px",
                                marginRight: "5px",
                                color:
                                  status["Owner Email"] === "Accepted"
                                    ? "green"
                                    : "grey",
                                fontWeight:
                                  status["Owner Email"] === "Accepted"
                                    ? "bold"
                                    : "normal",
                              }}
                              onClick={() => handleAccept("Owner Email")}
                            >
                              ✔
                            </span>
                            <span
                              className={`reject ${
                                status["Owner Email"] === "Rejected"
                                  ? "rejected"
                                  : ""
                              }`}
                              style={{
                                cursor: "pointer",
                                fontSize: "20px",
                                marginRight: "20px",
                                color:
                                  status["Owner Email"] === "Rejected"
                                    ? "red"
                                    : "grey",
                                fontWeight:
                                  status["Owner Email"] === "Rejected"
                                    ? "bold"
                                    : "normal",
                              }}
                              onClick={() => handleReject("Owner Email")}
                            >
                              ✘
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="contactName">Contact Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Contact Name"
                              value={contactName}
                              readOnly
                              id="contactName"
                              //   value={ownerName}
                              onChange={(e) => setContactName(e.target.value)}
                              // onAccept={() => handleAccept("Contact Name")}
                              // onReject={() => handleReject("Contact Name")}
                              fieldName="Contact Name"
                              status={status} // Pass status prop
                            />
                            <span
                              className={`accept ${
                                status["Conact Name"] === "Accepted"
                                  ? "accepted"
                                  : ""
                              }`}
                              style={{
                                cursor: "pointer",
                                fontSize: "20px",
                                marginRight: "5px",
                                color:
                                  status["Contact Name"] === "Accepted"
                                    ? "green"
                                    : "grey",
                                fontWeight:
                                  status["Contact Name"] === "Accepted"
                                    ? "bold"
                                    : "normal",
                              }}
                              onClick={() => handleAccept("Contact Name")}
                            >
                              ✔
                            </span>
                            <span
                              className={`reject ${
                                status["Contact Name"] === "Rejected"
                                  ? "rejected"
                                  : ""
                              }`}
                              style={{
                                cursor: "pointer",
                                fontSize: "20px",
                                marginRight: "20px",
                                color:
                                  status["Contact Name"] === "Rejected"
                                    ? "red"
                                    : "grey",
                                fontWeight:
                                  status["Contact Name"] === "Rejected"
                                    ? "bold"
                                    : "normal",
                              }}
                              onClick={() => handleReject("Contact Name")}
                            >
                              ✘
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="contactEmail">Contact Email</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Contact Email"
                              value={contactEmail}
                              readOnly
                              id="contactEmail"
                              //   value={ownerName}
                              onChange={(e) => setContactEmail(e.target.value)}
                              // onAccept={() => handleAccept("Contact Email")}
                              // onReject={() => handleReject("Contact Email")}
                              fieldName="Contact Email"
                              status={status} // Pass status prop
                            />
                            <span
                              className={`accept ${
                                status["Contact Email"] === "Accepted"
                                  ? "accepted"
                                  : ""
                              }`}
                              style={{
                                cursor: "pointer",
                                fontSize: "20px",
                                marginRight: "5px",
                                color:
                                  status["Contact Email"] === "Accepted"
                                    ? "green"
                                    : "grey",
                                fontWeight:
                                  status["Contact Email"] === "Accepted"
                                    ? "bold"
                                    : "normal",
                              }}
                              onClick={() => handleAccept("Contact Email")}
                            >
                              ✔
                            </span>
                            <span
                              className={`reject ${
                                status["Contact Email"] === "Rejected"
                                  ? "rejected"
                                  : ""
                              }`}
                              style={{
                                cursor: "pointer",
                                fontSize: "20px",
                                marginRight: "20px",
                                color:
                                  status["Contact Email"] === "Rejected"
                                    ? "red"
                                    : "grey",
                                fontWeight:
                                  status["Contact Email"] === "Rejected"
                                    ? "bold"
                                    : "normal",
                              }}
                              onClick={() => handleReject("Contact Email")}
                            >
                              ✘
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="accountsContactName">
                              Account Contact Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Accounts Contact Name"
                              value={accountsContactName}
                              readOnly
                              id="accountsContactName"
                              //   value={ownerName}
                              onChange={(e) =>
                                setAccountsContactName(e.target.value)
                              }
                              // onAccept={() =>
                              //   handleAccept("Accounts Contact Name")
                              // }
                              // onReject={() =>
                              //   handleReject("Accounts Contact Name")
                              // }
                              fieldName="Accounts Contact Name"
                              status={status} // Pass status prop
                            />
                            <span
                              className={`accept ${
                                status["Accounts Contact Name"] === "Accepted"
                                  ? "accepted"
                                  : ""
                              }`}
                              style={{
                                cursor: "pointer",
                                fontSize: "20px",
                                marginRight: "5px",
                                color:
                                  status["Accounts Contact Name"] === "Accepted"
                                    ? "green"
                                    : "grey",
                                fontWeight:
                                  status["Accounts Contact Name"] === "Accepted"
                                    ? "bold"
                                    : "normal",
                              }}
                              onClick={() =>
                                handleAccept("Accounts Contact Name")
                              }
                            >
                              ✔
                            </span>
                            <span
                              className={`reject ${
                                status["Accounts Contact Name"] === "Rejected"
                                  ? "rejected"
                                  : ""
                              }`}
                              style={{
                                cursor: "pointer",
                                fontSize: "20px",
                                marginRight: "20px",
                                color:
                                  status["Accounts Contact Name"] === "Rejected"
                                    ? "red"
                                    : "grey",
                                fontWeight:
                                  status["Accounts Contact Name"] === "Rejected"
                                    ? "bold"
                                    : "normal",
                              }}
                              onClick={() =>
                                handleReject("Accounts Contact Name")
                              }
                            >
                              ✘
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="accountsContactEmail">
                              Account Contact Email
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Accounts Contact Email"
                              value={accountsContactEmail}
                              readOnly
                              id="accountsContactEmail"
                              //   value={ownerName}
                              onChange={(e) =>
                                setAccountsContactEmail(e.target.value)
                              }
                              // onAccept={() =>
                              //   handleAccept("Accounts Contact Email")
                              // }
                              // onReject={() =>
                              //   handleReject("Accounts Contact Email")
                              // }
                              fieldName="Accounts Contact Email"
                              status={status} // Pass status prop
                            />
                            <span
                              className={`accept ${
                                status["Accounts Contact Email"] === "Accepted"
                                  ? "accepted"
                                  : ""
                              }`}
                              style={{
                                cursor: "pointer",
                                fontSize: "20px",
                                marginRight: "5px",
                                color:
                                  status["Accounts Contact Email"] ===
                                  "Accepted"
                                    ? "green"
                                    : "grey",
                                fontWeight:
                                  status["Accounts Contact Email"] ===
                                  "Accepted"
                                    ? "bold"
                                    : "normal",
                              }}
                              onClick={() =>
                                handleAccept("Accounts Contact Email")
                              }
                            >
                              ✔
                            </span>
                            <span
                              className={`reject ${
                                status["Accounts Contact Email"] === "Rejected"
                                  ? "rejected"
                                  : ""
                              }`}
                              style={{
                                cursor: "pointer",
                                fontSize: "20px",
                                marginRight: "20px",
                                color:
                                  status["Accounts Contact Email"] ===
                                  "Rejected"
                                    ? "red"
                                    : "grey",
                                fontWeight:
                                  status["Accounts Contact Email"] ===
                                  "Rejected"
                                    ? "bold"
                                    : "normal",
                              }}
                              onClick={() =>
                                handleReject("Accounts Contact Email")
                              }
                            >
                              ✘
                            </span>
                          </div>
                        </div>
                        {/* Repeat similar input fields for other form fields */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="aadhaarCard">Aadhaar Card</label>
                            <div className="view-button-wrapper">
                              <button
                                className="btn btn-primary"
                                onClick={() => handleViewFile(aadharCardFile)}
                              >
                                View
                              </button>
                            </div>
                            <span
                              className={`accept ${
                                status["Aadhar Card"] === "Accepted"
                                  ? "accepted"
                                  : ""
                              }`}
                              style={{
                                cursor: "pointer",
                                fontSize: "20px",
                                marginRight: "5px",
                                color:
                                  status["Aadhar Card"] === "Accepted"
                                    ? "green"
                                    : "grey",
                                fontWeight:
                                  status["Aadhar Card"] === "Accepted"
                                    ? "bold"
                                    : "normal",
                              }}
                              onClick={() => handleAccept("Aadhar Card")}
                            >
                              ✔
                            </span>
                            <span
                              className={`reject ${
                                status["Aadhar Card"] === "Rejected"
                                  ? "rejected"
                                  : ""
                              }`}
                              style={{
                                cursor: "pointer",
                                fontSize: "20px",
                                marginRight: "20px",
                                color:
                                  status["Aadhar Card"] === "Rejected"
                                    ? "red"
                                    : "grey",
                                fontWeight:
                                  status["Aadhar Card"] === "Rejected"
                                    ? "bold"
                                    : "normal",
                              }}
                              onClick={() => handleReject("Aadhar Card")}
                            >
                              ✘
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="panCard">PAN Card</label>
                            <div className="view-button-wrapper">
                              <button
                                className="btn btn-primary"
                                onClick={() => handleViewFile(panCardFile)}
                              >
                                View
                              </button>
                            </div>
                            <span
                              className={`accept ${
                                status["Pan Card"] === "Accepted"
                                  ? "accepted"
                                  : ""
                              }`}
                              style={{
                                cursor: "pointer",
                                fontSize: "20px",
                                marginRight: "5px",
                                color:
                                  status["Pan Card"] === "Accepted"
                                    ? "green"
                                    : "grey",
                                fontWeight:
                                  status["Pan Card"] === "Accepted"
                                    ? "bold"
                                    : "normal",
                              }}
                              onClick={() => handleAccept("Pan Card")}
                            >
                              ✔
                            </span>
                            <span
                              className={`reject ${
                                status["Pan Card"] === "Rejected"
                                  ? "rejected"
                                  : ""
                              }`}
                              style={{
                                cursor: "pointer",
                                fontSize: "20px",
                                marginRight: "20px",
                                color:
                                  status["Pan Card"] === "Rejected"
                                    ? "red"
                                    : "grey",
                                fontWeight:
                                  status["Pan Card"] === "Rejected"
                                    ? "bold"
                                    : "normal",
                              }}
                              onClick={() => handleReject("Pan Card")}
                            >
                              ✘
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-12 text-center">
                          <button
                            className="btn btn-success mr-2"
                            onClick={() => handleSubmit("Approved")}
                          >
                            Approve
                          </button>
                          <button
                            className="btn btn-danger mr-2"
                            onClick={() => handleSubmit("Reject")}
                          >
                            Reject
                          </button>
                          <button
                            className="btn btn-warning"
                            onClick={handlePermanentlyReject}
                          >
                            Permanently Reject
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default VerifyUserRegisterDetails;
