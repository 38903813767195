export const modules = [
  {
    name: "Sales Details",
    options: [
      {
        name: "Get Highest Sales Report of Top 10 Customers",
      },
      { name: "Get Sales Report by Month" },
      { name: "Get Sales Report by Year" },
      { name: "Get Profit Report by Year" },
      { name: "Get Profit Report by Month" },
    ],
  },
  {
    name: "Case Details",
    options: [
      {
        name: "Get Cases Report by Status",
        subOptions: ["Open", "Closed", "Pending", "Rejected"],
      },
    ],
  },
  {
    name: "Quotation Details",
    options: [
      {
        name: "Get Quotation by Status",
        subOptions: ["Pending Quotation", "Completed Quotation"],
      },
    ],
  },
];
