import React, { useEffect, useState } from "react";
import { Bar, Pie, Doughnut, Line } from "react-chartjs-2"; // Import Pie chart
import ChartDataLabels from "chartjs-plugin-datalabels"; // Import the plugin
import { Modal, Button } from "react-bootstrap";
import {
  Chart,
  CategoryScale, // Required for x-axis
  LinearScale, // Required for y-axis (linear)
  BarElement, // Required for Bar charts
  ArcElement, // Required for Pie and Doughnut charts
  PointElement, // Required for Line charts
  LineElement, // Required for Line charts
  Title,
  Tooltip,
  Legend,
} from "chart.js"; // Import necessary components from Chart.js
import {
  getAdminTotalResellersCount,
  getAdminYearlyAquiredResellers,
  getAdminTotalCaseCount,
  getAdminTotalSalesByProducts,
  getAdminTotalResellerSalesRevenue,
  getAdminMonthYearwiseSalesRevenue,
  getAdminTopSalesResellers,
  getAdminResellersAccountCount,
  fetchDetailsDashboardDataAdmin,
} from "../../utils/api"; // Import the API function
import { HiArrowDown } from "react-icons/hi2";
// Register the required components for charts
Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
  // ChartDataLabels // Register the datalabels plugin if you're using it
);

const AdminDashBoard = () => {
  const [totalResellersCount, settotalResellersCount] = useState(null);
  const [totalCaseCount, settotalCaseCount] = useState(null);
  const [totalSalesByProducts, settotalSalesByProducts] = useState(null);
  const [totalProductwiseResellers, settotalProductwiseResellers] =
    useState(null);
  const [totalYearlyResellers, settotalYearlyResellers] = useState(null);
  // const [totalResellerProfit, settotalResellerProfit] = useState(null);
  const [totalSalesRevenue, settotalSalesRevenue] = useState(null);
  const [yearMonthwiseSalesRevenue, setYearMonthwiseSalesRevenue] =
    useState(null);
  const [topSalesResellers, setTopSalesResellers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingResellersCount, setLoadingResellersCount] = useState(false);
  const [loadingCaseCount, setLoadingCaseCount] = useState(false);
  const [loadingSalesByProducts, setLoadingSalesByProducts] = useState(false);
  const [loadingProductwiseResellers, setLoadingProductwiseResellers] =
    useState(false);
  const [loadingYearlyResellers, setLoadingYearlyResellers] = useState(false);
  const [loadingSalesRevenue, setLoadingSalesRevenue] = useState(false);
  const [label, setLabel] = useState(false);

  const [totalResellersDetails, setTotalResellersDetails] = useState([]);
  const [showTotalResellersDetailsModal, setShowTotalResellersDetailsModal] =
    useState(false);
  const [yearwiseResellersDetails, setYearwiseResellersDetails] = useState([]);
  const [showYearlResellersModal, setShowYearlResellersModal] = useState(false);

  const [resellersCasesDetails, setResellersCasesDetails] = useState([]);
  const [showResellersCaseModal, setShowResellersCaseModal] = useState(false);

  const [productwiseResellersAquired, setProductwiseResellersAquired] =
    useState([]);
  const [
    showProductResellersAquiredModal,
    setShowProductResellersAquiredModal,
  ] = useState(false);

  const [yearlySales, setYearlySales] = useState([]);
  const [showYearlySalesModal, setShowYearlySalesModal] = useState(false);

  const [custProdSalesDetails, setCustProdSalesDetails] = useState([]);
  const [showResellProdSalesDetailsModal, setShowResellProdSalesDetailsModal] =
    useState(false);

  const loginDataFromStorage = localStorage.getItem("userDetails");
  const parsedLoginData = JSON.parse(loginDataFromStorage);
  const admindetailsmodules = localStorage.getItem("admindetails");
  const admin_email = JSON.parse(admindetailsmodules).email;
  const handleCardClick = async (chartName, e) => {
    // Ensure the function is only triggered for "Total Resellers"
    if (chartName === "Total Resellers") {
      try {
        // API call to fetch total resellers data
        const responseData = await fetchDetailsDashboardDataAdmin(
          admin_email,
          chartName,
          0
        );

        // Set the label and total resellers details from the response
        setLabel("Total Resellers");
        setTotalResellersDetails(responseData.total_resellers_details);

        // Show the modal with the fetched data
        setShowTotalResellersDetailsModal(true);

        console.log("Total Resellers Data:", responseData);
      } catch (error) {
        console.error("Error fetching Total Resellers data:", error);
      }
    } else {
      console.log("No specific API call for this chart.");
    }
  };

  const handleChartClick = async (chartName, e) => {
    const chart = e.chart;
    const elements = chart.getElementsAtEventForMode(
      e,
      "nearest",
      { intersect: true },
      false
    );

    if (elements.length) {
      const index = elements[0].index;
      const datasetIndex = elements[0].datasetIndex;
      const value = chart.data.datasets[datasetIndex].data[index];
      const label = chart.data.labels[index];

      // alert(`Chart: ${chartName}, Label: ${label}, Value: ${value}`);

      // API calls based on chart name
      switch (chartName) {
        case "Yearly Resellers Acquisitions":
          // Fetching dashboard data based on chart name
          try {
            const responseData = await fetchDetailsDashboardDataAdmin(
              admin_email,
              chartName,
              label
            );
            setLabel(label);
            setYearwiseResellersDetails(responseData.yearly_resellers_details);

            setShowYearlResellersModal(true);
            // Process the response data as needed
          } catch (error) {
            console.error("Error fetching dashboard data:", error);
          }
          break;
        case "Total Cases Count":
          // Fetching dashboard data based on chart name
          try {
            const responseData = await fetchDetailsDashboardDataAdmin(
              admin_email,
              chartName,
              label
            );
            setLabel(label);
            setResellersCasesDetails(responseData.case_statuswise_details);

            setShowResellersCaseModal(true);
            // Process the response data as needed
          } catch (error) {
            console.error("Error fetching dashboard data:", error);
          }
          break;
        // case "Productwise Customer Aquired":
        //   // Fetching dashboard data based on chart name
        //   try {
        //     const responseData = await fetchDetailsDashboardDataAdmin(
        //       chartName,
        //       label
        //     );
        //     setLabel(label);
        //     setProductwiseCustomerAquired(
        //       responseData.productwise_customers_details
        //     );

        //     setShowProductCustomerAquiredModal(true);
        //     // Process the response data as needed
        //   } catch (error) {
        //     console.error("Error fetching dashboard data:", error);
        //   }
        //   break;
        case "Yearwise Sales Revenue":
          // Fetching dashboard data based on chart name
          try {
            const responseData = await fetchDetailsDashboardDataAdmin(
              admin_email,
              chartName,
              label
            );
            setLabel(label);
            setYearlySales(responseData.yearwise_sales_revenue_custdetails);

            setShowYearlySalesModal(true);
            // Process the response data as needed
          } catch (error) {
            console.error("Error fetching dashboard data:", error);
          }
          break;
        // Add more cases for other charts
        default:
          console.log("No specific API call for this chart.");
          break;
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingResellersCount(true);
        const response = await getAdminTotalResellersCount(admin_email); // Call the API function
        settotalResellersCount(response.data); // Set the API data in state
        setLoadingResellersCount(false);
      } catch (error) {
        setLoadingResellersCount(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Ensure correct dependencies

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingCaseCount(true);
        const response = await getAdminTotalCaseCount(admin_email); // Call the API function
        settotalCaseCount(response.data); // Set the API data in state
        setLoadingCaseCount(false);
      } catch (error) {
        setLoadingCaseCount(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Ensure correct dependencies

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingSalesByProducts(true);
        const response = await getAdminTotalSalesByProducts(admin_email); // Call the API function
        settotalSalesByProducts(response.data); // Set the API data in state
        setLoadingSalesByProducts(false);
      } catch (error) {
        setLoadingSalesByProducts(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Ensure correct dependencies

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingYearlyResellers(true);
        const response = await getAdminYearlyAquiredResellers(admin_email); // Call the API function
        settotalYearlyResellers(response.data); // Set the API data in state
        setLoadingYearlyResellers(false);
      } catch (error) {
        setLoadingYearlyResellers(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Ensure correct dependencies

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       setLoadingProductwiseResellers(true);
  //       const response = await getAdminProductsWiseResellers(); // Call the API function
  //       settotalProductwiseResellers(response.data); // Set the API data in state
  //       setLoadingProductwiseResellers(false);
  //     } catch (error) {
  //       setLoadingProductwiseResellers(false);
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, [parsedLoginData.reseller_email]); // Ensure correct dependencies

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await getTotalResellerProfit(
  //         parsedLoginData.reseller_email
  //       ); // Call the API function
  //       settotalResellerProfit(response.data); // Set the API data in state
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, [parsedLoginData.reseller_email]); // Ensure correct dependencies

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAdminTotalResellerSalesRevenue(admin_email); // Call the API function
        settotalSalesRevenue(response.data); // Set the API data in state
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Ensure correct dependencies

  useEffect(() => {
    const fetchSalesRevenue = async () => {
      try {
        setLoadingSalesRevenue(true);
        const response = await getAdminMonthYearwiseSalesRevenue(admin_email);
        console.log(response);
        setYearMonthwiseSalesRevenue(response.data.yearmonthwise_sales_revenue); // Set the API data in state
        setLoadingSalesRevenue(false);
      } catch (error) {
        setLoadingSalesRevenue(false);
        console.error("Error fetching sales revenue data:", error);
      }
    };

    fetchSalesRevenue();
  }, []);

  useEffect(() => {
    const fetchTopSalesResellers = async () => {
      try {
        const response = await getAdminTopSalesResellers(admin_email);
        setTopSalesResellers(response.data.top_sales_reseller); // Set the API data in state
      } catch (error) {
        console.error("Error fetching top sales resellers:", error);
      }
    };

    fetchTopSalesResellers();
  }, []);

  const loader = (
    <div className="loader-container">
      <div className="loader">
        <div className="tick"></div>
      </div>
    </div>
  );

  // Inline styles for card and layout
  const cardStyle = {
    border: "1px solid #ddd",
    borderRadius: "10px",
    padding: "20px",
    margin: "10px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#f9f9f9",
    textAlign: "center",
    width: "250px",
    transition: "transform 0.2s, box-shadow 0.2s",
    cursor: "pointer",
    justifyContent: "center",
    marginTop: "-20px",
  };

  const cardTitleStyle = {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "10px",
    color: "#49b8ea", // Bootstrap primary color
  };

  const cardTextStyle = {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#333",
    margin: "0",
  };

  const dashboardContainerStyle = {
    display: "flex", // Flexbox layout to align items in a row
    justifyContent: "space-around", // Optional: Space between the cards
    alignItems: "center", // Align items vertically in the center
    flexWrap: "wrap", // Wrap to the next line if necessary
    margin: "20px 0", // Add some margin to the container
  };

  const chartsContainerStyle = {
    display: "flex", // Display charts side by side
    justifyContent: "space-between", // Maintain space between the charts
    width: "150%", // Ensure full width for charts
    maxWidth: "1900px", // Set a max width for the charts container
    marginTop: "-10px", // Add some space at the top
    marginLeft: "-250px", // Shift the charts container 20px to the left
  };

  const chartStyle = {
    width: "48%", // Ensure each chart takes up 48% of the container width
    height: "420px",
    border: "1px solid #ddd", // Add border to the chart
    borderRadius: "10px", // Rounded corners for the chart
    padding: "15px", // Padding inside the chart container
    backgroundColor: "#fff", // Background color for better visibility
  };

  const handleMouseEnter = (e) => {
    e.currentTarget.style.transform = "scale(1.05)";
    e.currentTarget.style.boxShadow = "0 8px 16px rgba(0, 0, 0, 0.2)";
  };

  const handleMouseLeave = (e) => {
    e.currentTarget.style.transform = "scale(1)";
    e.currentTarget.style.boxShadow = "0 4px 12px rgba(0, 0, 0, 0.1)";
  };

  // Prepare data for the first Bar chart (Customer Acquisitions)
  const customerChartData = {
    labels: totalYearlyResellers?.yearwise_resellers_acquired
      ? totalYearlyResellers.yearwise_resellers_acquired.map(
          (entry) => Object.keys(entry)[0]
        )
      : [], // Get the years or an empty array if null
    datasets: [
      {
        label: "Resellers Acquired",
        data: totalYearlyResellers?.yearwise_resellers_acquired
          ? totalYearlyResellers.yearwise_resellers_acquired.map(
              (entry) => Object.values(entry)[0]
            )
          : [], // Get the number of customers or an empty array if null
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };
  // Prepare data for the Pie chart (Total Cases Count)
  const totalCasesData = {
    labels: ["Open", "Closed", "Pending", "Rejected"],
    datasets: [
      {
        data: [
          totalCaseCount?.total_cases_count[0]?.open_count || 0,
          totalCaseCount?.total_cases_count[1]?.closed_count || 0,
          totalCaseCount?.total_cases_count[2]?.pending_count || 0,
          totalCaseCount?.total_cases_count[3]?.rejected_count || 0,
        ],
        backgroundColor: [
          "rgba(75, 192, 192, 0.6)", // Open cases color
          "rgba(153, 102, 255, 0.6)", // Closed cases color
          "rgba(255, 159, 64, 0.6)", // Pending cases color
          "rgba(255, 99, 132, 0.6)", // Rejected cases color
        ],
        hoverBackgroundColor: [
          "rgba(75, 192, 192, 0.8)",
          "rgba(153, 102, 255, 0.8)",
          "rgba(255, 159, 64, 0.8)",
          "rgba(255, 99, 132, 0.8)",
        ],
      },
    ],
  };
  // Prepare data for the Doughnut chart (Total Sales by Products)
  const salesByProductsLabels = totalSalesByProducts?.total_sales_byproducts
    ? totalSalesByProducts.total_sales_byproducts.map(
        (entry) => Object.keys(entry)[0]
      )
    : []; // Get product names or an empty array if null

  const salesByProductsData = {
    labels: salesByProductsLabels,
    datasets: [
      {
        data: totalSalesByProducts?.total_sales_byproducts
          ? totalSalesByProducts.total_sales_byproducts.map(
              (entry) => Object.values(entry)[0]
            )
          : [], // Get sales figures or an empty array if null
        backgroundColor: salesByProductsLabels.map(() => {
          // Generate random colors for each product dynamically
          return `hsl(${Math.random() * 360}, 70%, 50%)`; // Random color using HSL
        }),
        hoverBackgroundColor: salesByProductsLabels.map(() => {
          return `hsl(${Math.random() * 360}, 70%, 60%)`; // Slightly lighter color on hover
        }),
      },
    ],
  };
  // // Prepare data for the Horizontal Bar chart (Productwise Customers Acquired)
  // const productwiseCustomerData = {
  //   labels: totalProductwiseResellers?.productwise_reseller_acquired
  //     ? totalProductwiseResellers.productwise_reseller_acquired.map(
  //         (entry) => Object.keys(entry)[0]
  //       )
  //     : [], // Get product names or an empty array if null
  //   datasets: [
  //     {
  //       label: "Resellers Acquired",
  //       data: totalProductwiseResellers?.productwise_reseller_acquired
  //         ? totalProductwiseResellers.productwise_reseller_acquired.map(
  //             (entry) => Object.values(entry)[0]
  //           )
  //         : [], // Get customer counts or an empty array if null
  //       backgroundColor: "rgba(153, 102, 255, 0.6)", // Set color for the horizontal bars
  //       borderColor: "rgba(153, 102, 255, 1)", // Border color
  //       borderWidth: 1,
  //     },
  //   ],
  // };

  // Prepare data for the Line chart
  const salesRevenueChartData = {
    labels: yearMonthwiseSalesRevenue
      ? yearMonthwiseSalesRevenue.map((entry) => Object.keys(entry)[0])
      : [], // Get the years
    datasets: [
      {
        label: "Yearly Sales Revenue",
        data: yearMonthwiseSalesRevenue
          ? yearMonthwiseSalesRevenue.map((entry) => Object.values(entry)[0])
          : [], // Get the revenue
        backgroundColor: "rgba(75, 192, 192, 0.4)", // Fill color
        borderColor: "rgba(75, 192, 192, 1)", // Line color
        borderWidth: 2,
        fill: false,
      },
    ],
  };

  const pieChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "right",
        align: "start",
        labels: {
          boxWidth: 12,
          padding: 20,
        },
      },
      // Custom plugin to display values on the chart
      datalabels: {
        formatter: (value, context) => {
          const dataset = context.chart.data.datasets[context.datasetIndex];
          const dataIndex = context.dataIndex;

          // Only show the label if the value is greater than 0
          return value > 0 ? `${value}` : "";
        },
        color: "#000000", // Change the color of the labels if needed
      },
    },
  };

  const doughnutChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "right", // Set legend position to the right
        align: "start", // Align legend items vertically
        labels: {
          boxWidth: 12, // Adjust box width for better visibility
          padding: 20, // Adjust padding between legend items
        },
      },
    },
  };
  const barChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    indexAxis: "y", // Make the bar chart horizontal
    plugins: {
      legend: {
        display: true,
        position: "top",
        align: "start",
        labels: {
          boxWidth: 12,
          padding: 20,
        },
      },
    },
  };
  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  const lineChartOptions = {
    plugins: {
      datalabels: {
        display: true,
        color: "#000",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return "₹" + value.toLocaleString(); // Format as currency
          },
        },
      },
    },
  };
  const thStyles = {
    position: "sticky",
    top: 0,
    backgroundColor: "#f1f1f1",
    zIndex: 100,
  };
  const handleCloseResellersDetailsModal = () => {
    setShowYearlResellersModal(false);
    setLabel("");
  };
  const handleCloseResellersCaseDetailsModal = () => {
    setShowResellersCaseModal(false);
    setLabel("");
  };
  // const handleCloseProductwiseCustAquiredDetailsModal = () => {
  //   setShowProductCustomerAquiredModal(false);
  //   setLabel("");
  // };
  const handleCloseYearlySalesModal = () => {
    setShowYearlySalesModal(false);
    setLabel("");
  };
  const handleCloseTotalResellersDetailsModal = () => {
    setShowTotalResellersDetailsModal(false);
    setLabel("");
  };
  // const handleCloseCustProdSalesModal = () => {
  //   setShowCustProdSalesDetailsModal(false);
  //   setLabel("");
  // };
  // const handleView = async (custID) => {
  //   // Fetching dashboard data based on chart name
  //   try {
  //     const responseData = await fetchDetailsDashboardData(
  //       parsedLoginData.reseller_email,
  //       "Top 10 Customers",
  //       custID
  //     );
  //     setLabel(label);
  //     setCustProdSalesDetails(responseData.top10_cust_prod_details);

  //     setShowCustProdSalesDetailsModal(true);
  //     // Process the response data as needed
  //   } catch (error) {
  //     console.error("Error fetching dashboard data:", error);
  //   }
  //   setShowCustProdSalesDetailsModal(true);
  // };
  const getStatusColor = (status) => {
    switch (status) {
      case "In Review":
        return "orange";
      case "Active":
        return "green";
      case "Inactive":
        return "red";
      default:
        return "black";
    }
  };
  const downloadCSV = (data, headers, filename) => {
    // Create a CSV header row
    const headerRow = headers.join(",") + "\n";
    const csvData = data.map((row) => Object.values(row).join(",")).join("\n");

    const blob = new Blob([headerRow + csvData], {
      type: "text/csv;charset=utf-8;",
    });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <h4 className="text-center mb-4">Admin Dashboard</h4>

          <div style={dashboardContainerStyle}>
            {/* Card for Total Customers */}
            {totalResellersCount && (
              <div
                style={cardStyle}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={(e) => handleCardClick("Total Resellers", e)} // Make the value clickable
              >
                <h5 style={cardTitleStyle}>Total Resellers</h5>
                <p style={cardTextStyle}>
                  {totalResellersCount.total_resellers
                    ? totalResellersCount.total_resellers
                    : 0}
                </p>
              </div>
            )}
            {/* Card for Total Customers */}
            {totalSalesRevenue && (
              <div
                style={cardStyle}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <h5 style={cardTitleStyle}>Total Sales Revenue</h5>
                <p style={cardTextStyle}>
                  {totalSalesRevenue.total_salesrevenue
                    ? new Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).format(totalSalesRevenue.total_salesrevenue)
                    : "₹0"}
                </p>
              </div>
            )}
          </div>

          {/* Charts Container */}
          <div style={chartsContainerStyle}>
            <div style={chartStyle}>
              <h5 style={{ textAlign: "center", marginBottom: "10px" }}>
                Yearly Resellers Acquisitions
              </h5>
              {loadingYearlyResellers ? (
                loader
              ) : (
                <Bar
                  data={customerChartData}
                  options={{
                    ...chartOptions,
                    onClick: (e) =>
                      handleChartClick("Yearly Resellers Acquisitions", e),
                    // Add other options here
                  }}
                />
              )}
            </div>

            {/* Second Chart: Total Cases Count (Pie Chart) */}
            <div style={chartStyle}>
              <h5
                style={{
                  textAlign: "center",
                  marginBottom: "4px",
                  marginTop: "-8px",
                }}
              >
                Total Cases Count
              </h5>
              {loadingCaseCount ? (
                loader
              ) : (
                <Pie
                  data={totalCasesData}
                  options={{
                    ...pieChartOptions,
                    onClick: (e) => handleChartClick("Total Cases Count", e),
                  }}
                  plugins={[ChartDataLabels]}
                />
              )}
            </div>
          </div>
          <br></br>
          <div style={chartsContainerStyle}>
            <div style={{ ...chartStyle }}>
              <h5
                style={{
                  textAlign: "center",
                  marginBottom: "4px",
                  marginTop: "-8px",
                }}
              >
                Total Sales by Products
              </h5>
              {loadingSalesByProducts ? (
                loader
              ) : (
                <Doughnut
                  data={salesByProductsData}
                  // options={doughnutChartOptions}
                  options={{
                    ...doughnutChartOptions,
                    // onClick: (e) =>
                    //   handleChartClick("Total Sales by Products", e),
                    // Add other options here
                  }}
                />
              )}
            </div>
            {/* <div style={chartStyle}>
              <h5
                style={{
                  textAlign: "center",
                  marginBottom: "4px",
                  marginTop: "-8px",
                }}
              >
                Productwise Resellers Acquired
              </h5>
              {loadingProductwiseResellers ? (
                loader
              ) : (
                <Bar
                  data={productwiseCustomerData}
                  // options={barChartOptions}
                  options={{
                    ...barChartOptions,
                    onClick: (e) =>
                      handleChartClick("Productwise Customer Aquired", e),
                    // Add other options here
                  }}
                />
              )}
            </div> */}
            <div style={{ ...chartStyle }}>
              <h5
                style={{
                  textAlign: "center",
                  marginBottom: "4px",
                  marginTop: "-8px",
                }}
              >
                Yearwise Sales Revenue
              </h5>
              {loadingSalesRevenue ? (
                loader
              ) : (
                <Line
                  data={salesRevenueChartData}
                  // options={lineChartOptions}
                  options={{
                    ...lineChartOptions,
                    onClick: (e) =>
                      handleChartClick("Yearwise Sales Revenue", e),
                    // Add other options here
                  }}
                />
              )}
            </div>
          </div>
          <br></br>
          <div style={chartsContainerStyle}>
            {/* <div style={{ ...chartStyle }}>
              <h5
                style={{
                  textAlign: "center",
                  marginBottom: "4px",
                  marginTop: "-8px",
                }}
              >
                Yearwise Sales Revenue
              </h5>
              {loadingSalesRevenue ? (
                loader
              ) : (
                <Line
                  data={salesRevenueChartData}
                  // options={lineChartOptions}
                  options={{
                    ...lineChartOptions,
                    onClick: (e) => 
                      handleChartClick("Yearwise Sales Revenue", e),
                    // Add other options here
                  }}
                />
              )}
            </div> */}
            <div style={{ ...chartStyle }}>
              <div>
                <h5
                  style={{
                    textAlign: "center",
                    marginBottom: "4px",
                    marginTop: "-8px",
                  }}
                >
                  Top 10 Highest Sales Resellers
                </h5>
                <div
                  style={{
                    height: "380px",
                    overflowY: "scroll",
                    border: "1px solid #ddd",
                    borderRadius: "10px",
                    padding: "10px",
                    backgroundColor: "#fff",
                  }}
                >
                  <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead
                      style={{ backgroundColor: "#49b8ea", color: "#fff" }}
                    >
                      <tr>
                        <th style={{ padding: "10px", textAlign: "center" }}>
                          Reseller Name
                        </th>
                        <th style={{ padding: "10px", textAlign: "center" }}>
                          Reseller Company Name
                        </th>
                        <th style={{ padding: "10px", textAlign: "center" }}>
                          Total Sales
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {topSalesResellers.map((reseller, index) => (
                        <tr
                          key={reseller.reseller_id}
                          // onClick={() => handleView(reseller.reseller_id)}
                          style={{ cursor: "pointer" }}
                        >
                          <td
                            style={{
                              padding: "10px",
                              borderBottom: "1px solid #ddd",
                            }}
                          >
                            {reseller.reseller_name}
                          </td>
                          <td
                            style={{
                              padding: "10px",
                              borderBottom: "1px solid #ddd",
                            }}
                          >
                            {reseller.company_name}
                          </td>
                          <td
                            style={{
                              padding: "10px",
                              borderBottom: "1px solid #ddd",
                              textAlign: "right",
                            }}
                          >
                            ₹{reseller.total_sales.toLocaleString()}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showYearlResellersModal}
        onHide={handleCloseResellersDetailsModal}
        backdrop="static"
        keyboard={true}
        size="xl"
      >
        {loading && (
          <div className="loader-container">
            <div className="loader">
              <div className="tick"></div>
            </div>
          </div>
        )}
        <Modal.Header closeButton>
          <Modal.Title>
            Registered Resellers in <b>{label}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form-sample">
            <div className="form-group">
              <div className="row mt-4">
                <div
                  className="table-responsive"
                  style={{ maxHeight: "500px", overflowY: "auto" }}
                >
                  <table className="table table-hover sticky-header">
                    <thead>
                      <tr>
                        <th style={thStyles}>Reseller Name</th>
                        <th style={thStyles}>Company Name</th>
                        <th style={thStyles}>Reseller EmailID</th>
                      </tr>
                    </thead>
                    <tbody>
                      {yearwiseResellersDetails.map((reseller, index) => (
                        <tr key={index}>
                          <td>{reseller.reseller_name}</td>
                          <td>{reseller.company_name}</td>
                          <td>{reseller.reseller_email}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() =>
              downloadCSV(
                yearwiseResellersDetails,
                ["Reseller Name", "Company Name", "Reseller Email-ID"],
                "Export-Data.csv"
              )
            }
            title="Download Data"
          >
            <HiArrowDown />
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showResellersCaseModal}
        onHide={handleCloseResellersCaseDetailsModal}
        backdrop="static"
        keyboard={true}
        size="xl"
      >
        {loading && (
          <div className="loader-container">
            <div className="loader">
              <div className="tick"></div>
            </div>
          </div>
        )}
        <Modal.Header closeButton>
          <Modal.Title>
            Case Details of <b>{label}</b> Status
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form-sample">
            <div className="form-group">
              <div className="row mt-4">
                <div
                  className="table-responsive"
                  style={{ maxHeight: "500px", overflowY: "auto" }}
                >
                  <table className="table table-hover sticky-header">
                    <thead>
                      <tr>
                        <th style={thStyles}>Reseller Name</th>
                        <th style={thStyles}>Case Name</th>
                        <th style={thStyles}>Customer EmailID</th>
                        <th style={thStyles}>Domain Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {resellersCasesDetails.map((cases, index) => (
                        <tr key={index}>
                          <td>{cases.reseller_name}</td>
                          <td>{cases.case_name}</td>
                          <td>{cases.customer_emailid}</td>
                          <td>{cases.domain_name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() =>
              downloadCSV(
                resellersCasesDetails,
                [
                  "Reseller Name",
                  "Case Name",
                  "Customer Email-ID",
                  "Customer Domain Name",
                ],
                "Export-data.csv"
              )
            }
            title="Download Data"
          >
            <HiArrowDown />
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Modal
        show={showProductCustomerAquiredModal}
        onHide={handleCloseProductwiseCustAquiredDetailsModal}
        backdrop="static"
        keyboard={true}
        size="xl"
      >
        {loading && (
          <div className="loader-container">
            <div className="loader">
              <div className="tick"></div>
            </div>
          </div>
        )}
        <Modal.Header closeButton>
          <Modal.Title>
            Details of <b>{label}</b> Product
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form-sample">
            <div className="form-group">
              <div className="row mt-4">
                <div
                  className="table-responsive"
                  style={{ maxHeight: "500px", overflowY: "auto" }}
                >
                  <table className="table table-hover sticky-header">
                    <thead>
                      <tr>
                        <th style={thStyles}>Customer Name</th>
                        <th style={thStyles}>Customer Company Name</th>
                        <th style={thStyles}>Domain Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productwiseCustomerAquired.map((product, index) => (
                        <tr key={index}>
                          <td>{product.customer_name}</td>
                          <td>{product.customer_domainname}</td>
                          <td>{product.customer_company_name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body> */}
      {/* <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => handleCloseCustomerDetailsModal(false)}
          >
            Close
          </Button>
        </Modal.Footer> */}
      {/* </Modal> */}

      <Modal
        show={showYearlySalesModal}
        onHide={handleCloseYearlySalesModal}
        backdrop="static"
        keyboard={true}
        size="xl"
      >
        {loading && (
          <div className="loader-container">
            <div className="loader">
              <div className="tick"></div>
            </div>
          </div>
        )}
        <Modal.Header closeButton>
          <Modal.Title>
            Sales of <b>{label}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form-sample">
            <div className="form-group">
              <div className="row mt-4">
                <div
                  className="table-responsive"
                  style={{ maxHeight: "500px", overflowY: "auto" }}
                >
                  <table className="table table-hover sticky-header">
                    <thead>
                      <tr>
                        <th style={thStyles}>Reseller Name</th>
                        <th style={thStyles}>Reseller Company Name</th>
                        <th style={thStyles}>Total Sales</th>
                      </tr>
                    </thead>
                    <tbody>
                      {yearlySales.map((yearsale, index) => (
                        <tr key={index}>
                          <td>{yearsale.reseller_name}</td>
                          <td>{yearsale.company_name}</td>
                          <td>₹{yearsale.total_sales.toLocaleString()}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() =>
              downloadCSV(
                yearlySales,
                ["Reseller Name", "Company Name", "Total Sales"],
                "Export-data.csv"
              )
            }
            title="Download Data"
          >
            <HiArrowDown />
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Modal
        show={showCustProdSalesDetailsModal}
        onHide={handleCloseCustProdSalesModal}
        backdrop="static"
        keyboard={true}
        size="m"
      >
        {loading && (
          <div className="loader-container">
            <div className="loader">
              <div className="tick"></div>
            </div>
          </div>
        )}
        <Modal.Header closeButton>
          <Modal.Title>Sales Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form-sample">
            <div className="form-group">
              <div className="row mt-4">
                <div
                  className="table-responsive"
                  style={{ maxHeight: "500px", overflowY: "auto" }}
                >
                  <table className="table table-hover sticky-header">
                    <thead>
                      <tr>
                        <th style={thStyles}>Oem Name</th>
                        <th style={thStyles}>Total Sales</th>
                      </tr>
                    </thead>
                    <tbody>
                      {custProdSalesDetails.map((custprodsale, index) => (
                        <tr key={index}>
                          <td>{custprodsale.oem_name}</td>
                          <td>₹{custprodsale.total_sales.toLocaleString()}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        
      </Modal> */}
      <Modal
        show={showTotalResellersDetailsModal}
        onHide={handleCloseTotalResellersDetailsModal}
        backdrop="static"
        keyboard={true}
        size="xl"
      >
        {loading && (
          <div className="loader-container">
            <div className="loader">
              <div className="tick"></div>
            </div>
          </div>
        )}
        <Modal.Header closeButton>
          <Modal.Title>Resellers Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form-sample">
            <div className="form-group">
              <div className="row mt-4">
                <div
                  className="table-responsive"
                  style={{ maxHeight: "500px", overflowY: "auto" }}
                >
                  <table className="table table-hover sticky-header">
                    <thead>
                      <tr>
                        <th style={thStyles}>Reseller Name</th>
                        <th style={thStyles}>Reseller Email</th>
                        <th style={thStyles}>Company Name</th>
                        <th style={thStyles}>Account Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {totalResellersDetails.map((resellerDetail, index) => (
                        <tr key={index}>
                          <td>{resellerDetail.reseller_name}</td>
                          <td>{resellerDetail.reseller_email}</td>
                          <td>{resellerDetail.company_name}</td>
                          <td
                            style={{
                              color: getStatusColor(resellerDetail.status),
                            }}
                          >
                            {resellerDetail.status}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() =>
              downloadCSV(
                totalResellersDetails,
                [
                  "Reseller Name",
                  "Reseller Email",
                  "Company Name",
                  "Account Status",
                ],
                "Export-data.csv"
              )
            }
            title="Download Data"
          >
            <HiArrowDown />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default AdminDashBoard;
