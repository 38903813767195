import React, { Fragment, useState, useEffect } from "react";
import {
  Adminlogin,
  adminloginsendOTP,
  adminloginverifyOTP,
} from "../../utils/api"; // Assuming these functions exist in your api file
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

function AdminLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOTP] = useState("");
  const [error, setError] = useState("");
  const [stage, setStage] = useState("email"); // stages: email, otp, password
  const [cookies, setCookie] = useCookies(["email"]);
  const [resendOTPEnabled, setResendOTPEnabled] = useState(false);
  const [timer, setTimer] = useState(30);
  const [loading, setLoading] = useState(false); // Loading state

  useEffect(() => {
    document.title = "Admin Login Page";
  }, []);

  useEffect(() => {
    let interval;
    if (!resendOTPEnabled && stage === "otp") {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(interval);
            setResendOTPEnabled(true);
            return 30;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [resendOTPEnabled, stage]);

  const handleSendOTP = async (e) => {
    e.preventDefault();
    if (email.trim() === "") {
      window.alert("Please enter your email.");
      return;
    }
    if (!isValidEmail(email)) {
      window.alert("Please enter a valid email address.");
      return;
    }
    try {
      setLoading(true); // Set loading state to true
      const response = await adminloginsendOTP(email);
      if (response.status === 200) {
        setStage("otp");
        setResendOTPEnabled(false);
        setTimer(30);
        window.alert("OTP sent to your email.");
      } else {
        setError("Failed to send OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      setError("Error sending OTP. Please try again.");
    } finally {
      setLoading(false); // Set loading state to false after login attempt
    }
  };

  const handleResendOTP = async () => {
    if (!resendOTPEnabled) return;
    try {
      setLoading(true);
      const response = await adminloginsendOTP(email);
      if (response.status === 200) {
        setResendOTPEnabled(false);
        setTimer(30);
        window.alert("OTP resent to your email.");
      } else {
        setError("Failed to resend OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error resending OTP:", error);
      setError("Error resending OTP. Please try again.");
    } finally {
      setLoading(false); // Set loading state to false after login attempt
    }
  };

  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    if (otp.trim() === "") {
      window.alert("Please enter the OTP.");
      return;
    }
    try {
      setLoading(true);
      const response = await adminloginverifyOTP(email, otp);
      if (response.status === 200) {
        setStage("password");
      } else {
        window.alert("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setError("Error verifying OTP. Please try again.");
    } finally {
      setLoading(false); // Set loading state to false after login attempt
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (password.trim() === "") {
      window.alert("Please enter your password.");
      return;
    }

    try {
      setLoading(true);
      const response = await Adminlogin(email, password);
      const data = await response.json();
      console.log(data);
      if (data.firsttime === "Yes" && response.status === 200) {
        // window.alert("Please change your password.");
        localStorage.removeItem("adminemail");
        localStorage.setItem("adminemail", data.adminemail);
        localStorage.removeItem("token");
        localStorage.setItem("token", data.token);
        window.location.href = "/adminchangepassword";
      } else if (data.firsttime === "Yes" && response.status === 401) {
        window.alert(data.message);
      } else if (data.firsttime === "No" && response.status === 401) {
        window.alert(data.message);
      } else if (data.firsttime === "No" && response.status === 200) {
        setCookie("adminemail", email, { path: "/" });
        console.log("Login Successful");
        localStorage.removeItem("admindetails");
        localStorage.setItem(
          "admindetails",
          JSON.stringify(data.admin_details)
        );
        console.log(data.token);
        localStorage.removeItem("token");
        localStorage.setItem("token", data.token);
        window.location.href = "/adminmaincomponent";
        // } else if (data.firsttime === "yes" && response.status === 404) {
        //   window.alert(data.message);
      } else if (response.status === 403) {
        window.alert(data.message);
      } else {
        setError("Unexpected error occurred.");
      }
    } catch (error) {
      console.error("Login error:", error);
      setError("Login failed. Please try again.");
    } finally {
      setLoading(false); // Set loading state to false after login attempt
    }
  };
  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation regex
    return regex.test(email);
  };

  return (
    <Fragment>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth auth-bg-1 theme-one">
            <div className="row w-100">
              <div className="col-lg-12 text-center mb-5">
                <img
                  src="../../assets/images/Shiviom-Logo.png"
                  className="img"
                  alt=""
                />
                <h1> Admin Login </h1>
              </div>
              <div className="col-lg-4 mx-auto">
                <div className="auto-form-wrapper">
                  <form
                    onSubmit={
                      stage === "password"
                        ? handleLogin
                        : stage === "otp"
                        ? handleVerifyOTP
                        : handleSendOTP
                    }
                  >
                    <div className="form-group">
                      <label className="label">Admin EmailID</label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          disabled={stage !== "email"}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="mdi mdi-check-circle-outline"></i>
                          </span>
                        </div>
                      </div>
                    </div>

                    {stage !== "email" && (
                      <div className="form-group">
                        <label className="label">Enter OTP</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="OTP"
                            value={otp}
                            onChange={(e) => setOTP(e.target.value)}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="mdi mdi-check-circle-outline"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    {stage === "otp" && (
                      <div className="form-group text-center">
                        <button
                          className="btn btn-link"
                          type="button"
                          onClick={handleResendOTP}
                          disabled={!resendOTPEnabled}
                        >
                          Resend OTP
                        </button>
                        {!resendOTPEnabled && (
                          <div>
                            <small>Resend OTP in {timer} seconds</small>
                          </div>
                        )}
                      </div>
                    )}

                    {stage === "password" && (
                      <div className="form-group">
                        <label className="label">Admin Password</label>
                        <div className="input-group">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="*********"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="mdi mdi-check-circle-outline"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="form-group">
                      <button
                        className="btn btn-primary submit-btn btn-block"
                        type="submit"
                      >
                        {stage === "password"
                          ? "Login"
                          : stage === "otp"
                          ? "Verify OTP"
                          : "Send OTP"}
                      </button>
                    </div>
                  </form>

                  {error && <div className="alert alert-danger">{error}</div>}

                  <div className="form-group d-flex justify-content-between">
                    <Link
                      to="/adminForgetpass"
                      className="text-small forgot-password text-black"
                    >
                      Forgot Password
                    </Link>
                  </div>

                  {/* <div className="text-block text-center my-3">
                    <span className="text-small font-weight-semibold">
                      Not a member ?
                    </span>
                    <Link to="/adminSignup" className="text-black text-small">
                      Create new account
                    </Link>
                  </div> */}
                </div>
                <ul className="auth-footer"></ul>
                <p className="footer-text text-center mt-5">
                  copyright © 2024 Shiviom. All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <script src="../../assets/vendors/js/vendor.bundle.base.js"></script>
      <script src="../../assets/vendors/js/vendor.bundle.addons.js"></script>
      <script src="../../assets/js/shared/off-canvas.js"></script>
      <script src="../../assets/js/shared/misc.js"></script>
      <script
        src="../../assets/js/shared/jquery.cookie.js"
        type="text/javascript"
      ></script>
    </Fragment>
  );
}

export default AdminLogin;
