import React, { Fragment, useState, useEffect } from "react";
import { adminChangePass } from "../../utils/api"; // Import your resetPassword API function

const Changepassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailidParam, setEmailId] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "Change Password Page"; // Set the document title

    // Get email from localStorage
    const emailFromLocalStorage = localStorage.getItem("adminemail");
    console.log(emailFromLocalStorage);
    if (emailFromLocalStorage) {
      setEmailId(emailFromLocalStorage);
    }
  }, []);

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await adminChangePass(
        emailidParam,
        newPassword,
        confirmPassword
      );
      if (response.status === 200) {
        // Password reset successful, redirect to login page
        const data = await response.json(); // Extract error message from response body
        window.alert(data.message);
        // window.alert("Password Changed Successfully. Now you can login again.");
        window.location.href = "/adminlogin";
      } else {
        const data = await response.json(); // Extract error message from response body
        window.alert(data.message); // Display error message in alert
      }
    } catch (error) {
      console.error("Reset password error:", error);
      window.alert("Error resetting password. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth register-bg-1 theme-one">
            <div className="row w-100">
              <div className="col-lg-4 mx-auto text-center">
                <img
                  src="../../assets/images/Shiviom-Logo.png"
                  className="img"
                  alt=""
                />
                <h1 className="mb-5">Change Your Password</h1>
                <p style={{ color: "#FFFFFF" }}>Enter Your new Password</p>
                <div className="auto-form-wrapper">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <div className="input-group">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          value={emailidParam}
                          readOnly
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="mdi mdi-check-circle-outline"></i>
                          </span>
                        </div>
                      </div>
                      <br></br>
                      <div className="input-group">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="New Password"
                          value={newPassword}
                          onChange={handleNewPasswordChange}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="mdi mdi-check-circle-outline"></i>
                          </span>
                        </div>
                      </div>
                      <br></br>
                      <div className="input-group">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Confirm Password"
                          value={confirmPassword}
                          onChange={handleConfirmPasswordChange}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="mdi mdi-check-circle-outline"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <button
                        className="btn btn-primary submit-btn btn-block"
                        type="submit"
                      >
                        Change Password
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Changepassword;
