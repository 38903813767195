import React, { Fragment, useState, useEffect } from "react";
import { Modal, Button, Spinner, Form } from "react-bootstrap";
import {
  getRenewalDetails,
  importCustomerData,
  getRegisteredList,
} from "../../utils/api";
import { FaSortUp, FaSortDown, FaSort } from "react-icons/fa";
import { CiImport, CiExport } from "react-icons/ci";
import { common } from "@mui/material/colors";

const RenewalDetails = () => {
  const [renewalDetails, setRenewalDetails] = useState([]);
  const [filteredCustomerList, setFilteredCustomerList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showViewModal, setShowViewModal] = useState(false);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [loading, setLoading] = useState(false);
  const admindetailsmodules = localStorage.getItem("admindetails");
  const admin_email = JSON.parse(admindetailsmodules).email;
  const [showImportModal, setShowImportModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [resellerList, setResellerList] = useState([]);
  const [selectedReseller, setSelectedReseller] = useState("");
  const [initialRenewalList, setInitialRenewalList] = useState([]);

  useEffect(() => {
    async function fetchRenewalDetails() {
      try {
        setLoading(true);
        const data = await getRenewalDetails();
        console.log(data);
        setRenewalDetails(data);
        setInitialRenewalList(data);
        // setFilteredCustomerList(data);
      } catch (error) {
        console.error("Failed to fetch renewal details:", error.message);
      } finally {
        setLoading(false);
      }
    }

    fetchRenewalDetails();
  }, []);
  const handleSearchInputChange = (value) => {
    setSearchInput(value.toLowerCase());
    const filteredList = initialRenewalList.filter((renewal) => {
      if (renewal) {
        return (
          (renewal.customer_name &&
            renewal.customer_name.toLowerCase().includes(value)) ||
          (renewal.customer_domainname &&
            renewal.customer_domainname.toLowerCase().includes(value)) ||
          (renewal.reseller_name &&
            renewal.reseller_name.toLowerCase().includes(value))
        );
      }
      return false;
    });
    setRenewalDetails(filteredList);
  };

  const handleReset = () => {
    setSearchInput("");
    setRenewalDetails(renewalDetails);
  };

  const handleSort = (column) => {
    let sortedList = [...filteredCustomerList];
    if (sortColumn === column) {
      sortedList.reverse(); // Toggle the sorting order
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      sortedList.sort((a, b) => {
        if (a[column] < b[column]) return -1;
        if (a[column] > b[column]) return 1;
        return 0;
      });
      setSortColumn(column);
      setSortOrder("asc");
    }
    setFilteredCustomerList(sortedList);
  };

  const handleViewDetails = (customer) => {
    setSelectedCustomer(customer);
    setShowViewModal(true);
  };
  const renderActionButtons = (user) => {
    return (
      <Fragment>
        <button
          className="btn btn-success mr-2"
          //   onClick={() => handleUserStatusUpdate(user.reseller_id, "ACCEPT")}
        >
          Renew Now
        </button>
      </Fragment>
    );
  };
  const thStyles = {
    position: "sticky",
    top: 0,
    backgroundColor: "#f1f1f1",
    zIndex: 100,
  };

  return (
    <Fragment>
      <style>
        {`
          .sortable-header {
            cursor: pointer;
            position: relative;
          }

          .sort-icon {
            margin-left: 5px;
            color: #6c757d; /* Default color */
            transition: color 0.2s ease;
          }

          .sortable-header:hover .sort-icon {
            color: #007bff; /* Change this to your preferred hover color */
          }

          .table-responsive {
            max-height: 500px;
            overflow-y: auto;
          }
        `}
      </style>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="mb-1">Renewal Details</h4>
            <br />
            <div className="search-container mb-3">
              <input
                type="text"
                placeholder="Search"
                value={searchInput}
                onChange={(e) => handleSearchInputChange(e.target.value)}
                style={{ float: "left" }}
              />
              <button
                className="btn btn-primary ml-2"
                onClick={handleReset}
                style={{ float: "left" }}
              >
                Reset
              </button>
              {/* <Button
                className="btn btn-primary ml-2"
                style={{ float: "right" }}
                onClick={handleOpenImportModal}
                title="Import Customer Data"
              >
                <CiImport size={15} />
              </Button> */}
            </div>
            <br />
            <div className="table-responsive">
              <table className="table table-hover sticky-header">
                <thead>
                  <tr>
                    <th
                      className="sortable-header"
                      onClick={() => handleSort("customer_name")}
                      style={thStyles}
                    >
                      Customer Name
                      {sortColumn === "customer_name" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" /> // Default sort icon
                      )}
                    </th>
                    <th
                      className="sortable-header"
                      onClick={() => handleSort("customer_domainname")}
                      style={thStyles}
                    >
                      Customer Domain Name
                      {sortColumn === "customer_domainname" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" />
                      )}
                    </th>
                    <th
                      className="sortable-header"
                      onClick={() => handleSort("company_name")}
                      style={thStyles}
                    >
                      Reseller Name
                      {sortColumn === "company_name" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" />
                      )}
                    </th>
                    <th style={thStyles}>SKU Name</th>
                    <th style={thStyles}>Expiry</th>
                    <th style={thStyles}>Licence Count</th>
                    <th style={thStyles}>Plan Type</th>
                    <th style={thStyles}>List Price</th>
                    <th style={thStyles}>Shiviom Price</th>
                    <th style={thStyles}>Action</th>
                    {/* <th>Account Manager</th> */}
                  </tr>
                </thead>
                <tbody>
                  {renewalDetails.map((renewal) => (
                    <tr
                      key={renewal.cust_id}
                      onClick={() => handleViewDetails(renewal)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{renewal.customer_name}</td>
                      <td>{renewal.customer_domainname}</td>
                      <td>{renewal.reseller_name}</td>
                      <td>{renewal.sku_name}</td>
                      <td>
                        {new Date(renewal.renewal_date).toLocaleDateString()}
                      </td>
                      <td>{renewal.usercount}</td>
                      <td>{renewal.plan_type}</td>
                      <td>{renewal.price}</td>
                      <td>{renewal.shiviom_price}</td>
                      <td>{renderActionButtons(renewal)}</td>
                      {/* <td></td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default RenewalDetails;
