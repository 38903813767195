import React, { Fragment, useState, useEffect } from "react";
import { adminsignup, adminsendOTP, adminverifyOTP } from "../../utils/api"; // Import the signup and sendOTP API functions
import { Link } from "react-router-dom";

function AdminSignup() {
  const [name, setName] = useState("");
  const [phnnumber, setPhnnumber] = useState("");
  const [phoneValid, setPhoneValid] = useState(true);
  const [phoneError, setPhoneError] = useState("");
  const [email, setEmail] = useState("");
  const [department, setDepartment] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [cnfpassword, setConfirmPassword] = useState("");
  const [otpSent, setOtpSent] = useState(false); // State to manage OTP sent status
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [otpVerificationResult, setOtpVerificationResult] = useState("");

  useEffect(() => {
    document.title = "Admin Signup Page"; // Set the document title
  }, []);

  const validatePhoneNumber = (phone) => {
    const pattern = /^\d{10}$/;
    return pattern.test(phone);
  };
  const handlePhoneChange = (e) => {
    const phoneValue = e.target.value;
    setPhnnumber(phoneValue);
    setPhoneValid(validatePhoneNumber(phoneValue));

    const phoneInput = e.target;
    if (validatePhoneNumber(phoneValue)) {
      phoneInput.style.borderColor = "green";
      setPhoneError("");
    } else {
      phoneInput.style.borderColor = "red";
      setPhoneError("Please enter a valid phone number.");
    }
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setEmailValid(validateEmail(emailValue));

    const emailInput = e.target;
    if (validateEmail(emailValue)) {
      emailInput.style.borderColor = "green";
      setEmailError("");
    } else {
      emailInput.style.borderColor = "red";
      setEmailError("Please enter a valid email address.");
    }
  };

  const validateEmail = (email) => {
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
  };
  setLoading(true);
  const handleSendOTP = async () => {
    try {
      if (!email) {
        window.alert("Please insert your email ID.");
        return;
      }

      console.log(email); // Ensure email value is correct
      const response = await adminsendOTP(email); // Pass email value to sendOTP function
      if (response.status === 200) {
        setOtpSent(true); // Update the state to indicate OTP has been sent
        setOtpVerificationResult("success-sent-otp");
        window.alert("OTP Sent on EmailID");
      } else {
        const data = await response.json(); // Extract error message from response body
        window.alert(data.message); // Display error message in alert
      }
    } catch (error) {
      window.alert("Failed to send OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOTP = async () => {
    try {
      setLoading(true);
      const response = await adminverifyOTP(email, otp); // Pass email value to verifyOTP function
      if (response.status === 200) {
        setOtpVerificationResult("Success"); // Update state to indicate successful OTP verification
        window.alert("OTP verified successfully!");
      } else {
        const data = await response.json(); // Extract error message from response body
        window.alert(data.message); // Display error message in alert
      }
    } catch (error) {
      window.alert("Failed to verify OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!password || !cnfpassword) {
      window.alert("Please enter password and confirm password.");
      return;
    }

    if (password !== cnfpassword) {
      window.alert("Passwords do not match.");
      return;
    }

    setLoading(true);
    try {
      const response = await adminsignup(
        name,
        phnnumber,
        email,
        password,
        cnfpassword,
        otp,
        department
      );
      if (response.status === 200) {
        window.alert("Admin User registered successfully. You can now login.");
        window.location.href = "/adminlogin";
        // Reset form fields only when registration is successful
        setName("");
        setPhnnumber("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setOtp("");
      } else {
        console.log(response);
        const data = await response.json(); // Extract error message from response body
        window.alert(data.message); // Display error message in alert
      }
      setLoading(false);
    } catch (error) {
      window.alert("Signup failed. Please try again.");
      setLoading(false);
    }
  };

  return (
    <Fragment>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth register-bg-1 theme-one">
            <div className="row w-100">
              <div className="col-lg-4 mx-auto text-center">
                <img
                  src="../../assets/images/Shiviom-Logo.png"
                  className="img"
                  alt=""
                />
                <h1 className="mb-5">Admin Account Register</h1>
                <div className="auto-form-wrapper">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="mdi mdi-check-circle-outline"></i>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      {/* <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Company Name"
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="mdi mdi-check-circle-outline"></i>
                          </span>
                        </div>
                      </div> */}
                    </div>

                    <div className="form-group">
                      <div className="input-group">
                        <input
                          type="tel"
                          className={`form-control ${
                            phoneValid ? "valid-input" : "invalid-input"
                          }`}
                          placeholder="Phone Number"
                          value={phnnumber}
                          onChange={handlePhoneChange}
                          required
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="mdi mdi-check-circle-outline"></i>
                          </span>
                        </div>
                      </div>
                      {phoneError && (
                        <div className="text-danger">{phoneError}</div>
                      )}
                    </div>
                    <div className="form-group">
                      <select
                        className="form-control"
                        value={department}
                        onChange={(e) => setDepartment(e.target.value)}
                        required
                      >
                        <option value="">Select Department</option>
                        <option value="Account">Account</option>
                        <option value="Renewal">Renewal</option>
                        <option value="Deployment">Deployment</option>
                        <option value="Superadmin">Superadmin</option>
                      </select>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-8">
                        <input
                          type="email"
                          className={`form-control ${
                            emailValid ? "" : "is-invalid"
                          }`}
                          placeholder="Email ID"
                          value={email}
                          onChange={handleEmailChange}
                          title="Enter a valid email address"
                          required
                        />
                        {!emailValid && (
                          <div className="invalid-feedback">
                            Please enter a valid email address.
                          </div>
                        )}
                      </div>

                      <div className="col-md-4">
                        {!otpSent ? (
                          <button
                            className="btn btn-primary submit-btn btn-block"
                            type="button"
                            onClick={handleSendOTP}
                          >
                            Send OTP
                          </button>
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter OTP"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                          />
                        )}
                      </div>
                    </div>

                    {otpSent && (
                      <div className="mt-2">
                        <button
                          className="btn btn-success mr-2"
                          onClick={handleVerifyOTP}
                        >
                          Verify OTP
                        </button>
                        <button
                          className="btn btn-info"
                          onClick={handleSendOTP}
                        >
                          Resend OTP
                        </button>
                      </div>
                    )}
                    <br></br>
                    {otpVerificationResult === "Success" && (
                      <div>
                        <div className="form-group">
                          <div className="input-group">
                            <input
                              type="password"
                              className="form-control"
                              placeholder="Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">
                                <i className="mdi mdi-check-circle-outline"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="input-group">
                            <input
                              type="password"
                              className="form-control"
                              placeholder="Confirm Password"
                              value={cnfpassword}
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">
                                <i className="mdi mdi-check-circle-outline"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="form-group">
                      <button
                        className="btn btn-primary submit-btn btn-block"
                        type="submit"
                      >
                        Register
                      </button>
                    </div>
                    <div className="text-block text-center my-3">
                      <span className="text-small font-weight-semibold">
                        Already have and account ?
                      </span>
                      <Link to="/adminLogin" className="text-black text-small">
                        {" "}
                        {/* Use Link for navigation */}
                        Login
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <script src="../../assets/vendors/js/vendor.bundle.base.js"></script>
      <script src="../../assets/vendors/js/vendor.bundle.addons.js"></script>

      <script src="../../assets/js/shared/off-canvas.js"></script>
      <script src="../../assets/js/shared/misc.js"></script>
      <script
        src="../../assets/js/shared/jquery.cookie.js"
        type="text/javascript"
      ></script>
    </Fragment>
  );
}

export default AdminSignup;
