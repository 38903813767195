import React, { Fragment, useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify"; // Import toast
import "react-toastify/dist/ReactToastify.css"; // Import toast CSS
import { Modal, Button } from "react-bootstrap";
import {
  get_products_datdetails,
  addProductsMaster,
  removeProductsMaster,
  updateProductMaster, // Ensure this import exists
  getPriceHistory,
} from "../../utils/api.js";
import { useCookies } from "react-cookie";
import { Tooltip, OverlayTrigger, Tab, Tabs } from "react-bootstrap";

const ProductMaster = () => {
  const [jsonData, setJsonData] = useState(null);
  const [selectedoem, setSelectedoem] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [cookies] = useCookies(["email"]);
  const email = cookies.email;
  const [isTooltipHovered, setIsTooltipHovered] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedSKU, setSelectedSKU] = useState("");
  const [price, setPrice] = useState("");
  const [modalSelectedOEM, setModalSelectedOEM] = useState("");
  const [modalSelectedProduct, setModalSelectedProduct] = useState("");
  const [removeModalShow, setRemoveModalShow] = useState(false);
  const [removeParameter, setRemoveParameter] = useState("");
  const [removeSelectedValue, setRemoveSelectedValue] = useState("");
  const [editModalShow, setEditModalShow] = useState(false);
  const [editSKU, setEditSKU] = useState("");
  const [oldPrice, setOldPrice] = useState("");
  const [newPrice, setNewPrice] = useState("");
  const [editSelectedOEM, setEditSelectedOEM] = useState("");
  const [editSelectedProduct, setEditSelectedProduct] = useState("");
  const [editSelectedSKU, setEditSelectedSKU] = useState("");
  const [priceHistory, setPriceHistory] = useState([]);
  const [activeTab, setActiveTab] = useState("productList");
  const [loading, setLoading] = useState(false);

  // **New State Variables for Adding OEM/Product**
  const [isAddingNewOEM, setIsAddingNewOEM] = useState(false);
  const [newOEMName, setNewOEMName] = useState("");
  const [isAddingNewProduct, setIsAddingNewProduct] = useState(false);
  const [newProductName, setNewProductName] = useState("");

  // Toggle tooltip hover state
  const handleTooltipHover = (hovered) => {
    setIsTooltipHovered(hovered);
  };

  const handleRemoveProduct = () => {
    setRemoveModalShow(true);
  };

  const handleRemoveModalClose = () => {
    setRemoveModalShow(false);
  };

  const fetchProductData = async () => {
    try {
      setLoading(true);
      const response = await get_products_datdetails();
      setJsonData(response);
    } catch (error) {
      toast.error("Failed to fetch product data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const fetchPriceHistory = async () => {
    try {
      setLoading(true);
      const data = await getPriceHistory();
      setPriceHistory(data);
    } catch (error) {
      toast.error("Failed to fetch price history. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProductData();
    if (activeTab === "priceHistory") {
      fetchPriceHistory();
    }
  }, [activeTab]);

  const handleSKUChange = (event) => {
    setSelectedSKU(event.target.value);
  };

  const handleoemSelect = (event) => {
    const selectedOEM = event.target.value;
    setSelectedoem(selectedOEM);
    setSelectedProduct(""); // Reset product selection when OEM changes
  };

  const handleProductSelect = (event) => {
    const selectedProduct = event.target.value;
    setSelectedProduct(selectedProduct);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    // Reset all states related to adding new OEM/Product
    setIsAddingNewOEM(false);
    setNewOEMName("");
    setIsAddingNewProduct(false);
    setNewProductName("");
  };

  // **Handler for OEM selection in Modal**
  const handleModalOEMSelect = (event) => {
    const selectedOEM = event.target.value;
    if (selectedOEM === "__ADD_NEW_OEM__") {
      setIsAddingNewOEM(true);
      setModalSelectedOEM(""); // Clear OEM selection
    } else {
      setIsAddingNewOEM(false);
      setModalSelectedOEM(selectedOEM);
      setNewOEMName(""); // Reset new OEM name input
    }

    // Reset Product related states when OEM changes
    setModalSelectedProduct("");
    setIsAddingNewProduct(false);
    setNewProductName("");
  };

  // **Handler for Product selection in Modal**
  const handleModalProductSelect = (event) => {
    const selectedProduct = event.target.value;
    if (selectedProduct === "__ADD_NEW_PRODUCT__") {
      setIsAddingNewProduct(true);
      setModalSelectedProduct(""); // Clear Product selection
    } else {
      setIsAddingNewProduct(false);
      setModalSelectedProduct(selectedProduct);
      setNewProductName(""); // Reset new Product name input
    }

    // Reset SKU related states when Product changes
    setSelectedSKU("");
  };

  // **Handler for SKU selection in Modal (No Add New SKU)**
  const handleSKUSelect = (event) => {
    const selectedSKU = event.target.value;
    setSelectedSKU(selectedSKU);
  };

  const handleAddProduct = async () => {
    let selectedOemValue = modalSelectedOEM; // Corrected to use modalSelectedOEM
    let selectedProductValue = modalSelectedProduct;
    let skuValue = selectedSKU;

    // Handle new OEM
    if (isAddingNewOEM) {
      if (!newOEMName.trim()) {
        toast.error("Please enter a new OEM name.");
        return;
      }
      selectedOemValue = newOEMName.trim();
    }

    // Handle new Product
    if (isAddingNewProduct) {
      if (!newProductName.trim()) {
        toast.error("Please enter a new Product name.");
        return;
      }
      selectedProductValue = newProductName.trim();
    }

    // Ensure all required fields are filled
    if (!selectedOemValue || !selectedProductValue || !skuValue || !price) {
      toast.error("Please fill in all fields.");
      return;
    }

    try {
      setLoading(true);
      const response = await addProductsMaster(
        selectedOemValue,
        selectedProductValue,
        skuValue,
        price
      );
      if (response.status === 200) {
        const data = await response.json();
        toast.success(data.message);
        fetchProductData();
      } else {
        const data = await response.json();
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Failed to add product. Please try again later.");
    } finally {
      setLoading(false);
    }

    // Reset the input fields after adding the product
    setModalSelectedOEM("");
    setNewOEMName("");
    setModalSelectedProduct("");
    setNewProductName("");
    setSelectedSKU("");
    setPrice("");

    setIsAddingNewOEM(false);
    setIsAddingNewProduct(false);
    setShowModal(false); // Close the modal after adding the product
  };

  const handleRemoveProductConfirm = async () => {
    try {
      // Ensure that both removeParameter and removeSelectedValue are filled
      if (!removeParameter || !removeSelectedValue) {
        toast.error("Please select a parameter and enter a value.");
        return;
      }
      setLoading(true);
      // Call removeProductsMaster with the selected parameter and entered value
      const response = await removeProductsMaster(
        removeParameter,
        removeSelectedValue
      );
      if (response.status === 200) {
        const data = await response.json();
        toast.success(data.message);
        // Refresh product data after removal
        fetchProductData();
        setRemoveParameter("");
        setRemoveSelectedValue("");
        // Close the modal
        setRemoveModalShow(false);
      } else if (response.status === 404) {
        const data = await response.json();
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Failed to remove product. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleEditProduct = () => {
    setEditModalShow(true);
  };

  const handleEditModalClose = () => {
    setEditModalShow(false);
    setEditSKU("");
    setOldPrice("");
    setNewPrice("");
    setEditSelectedOEM("");
    setEditSelectedProduct("");
    setEditSelectedSKU("");
  };

  const handleEditOEMSelect = (event) => {
    const selectedOEM = event.target.value;
    setEditSelectedOEM(selectedOEM);
    setEditSelectedProduct("");
    setEditSelectedSKU("");
    setEditSKU("");
    setOldPrice("");
    setNewPrice("");
  };

  const handleEditProductSelect = (event) => {
    const selectedProduct = event.target.value;
    setEditSelectedProduct(selectedProduct);
    setEditSelectedSKU("");
    setEditSKU("");
    setOldPrice("");
    setNewPrice("");
  };

  const handleEditSKUSelect = (event) => {
    const sku = event.target.value;
    setEditSelectedSKU(sku);
    setEditSKU(sku);

    const selectedOEMValue = editSelectedOEM;
    const selectedProductValue = editSelectedProduct;
    if (
      jsonData[selectedOEMValue] &&
      jsonData[selectedOEMValue][selectedProductValue] &&
      jsonData[selectedOEMValue][selectedProductValue][sku]
    ) {
      setOldPrice(jsonData[selectedOEMValue][selectedProductValue][sku][1]);
    } else {
      setOldPrice("");
    }
  };

  const handleOldPriceChange = (event) => {
    setOldPrice(event.target.value);
  };
  const handleNewPriceChange = (event) => {
    setNewPrice(event.target.value);
  };

  const handleSaveEditProduct = async () => {
    try {
      const selectedOEMValue = editSelectedOEM;
      const selectedProductValue = editSelectedProduct;
      setLoading(true);
      const response = await updateProductMaster(
        selectedOEMValue,
        selectedProductValue,
        editSKU,
        oldPrice,
        newPrice
      );
      toast.success(response.message);
      fetchProductData();
      handleEditModalClose();
    } catch (error) {
      toast.error("Failed to update product. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body" style={{ height: "600px" }}>
            <h4 className="mb-1">Product Master</h4>
            <Tabs
              activeKey={activeTab}
              onSelect={(tab) => setActiveTab(tab)}
              className="mb-3"
            >
              <Tab eventKey="productList" title="Product List">
                {/* OEM and Product Filters */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <select
                    id="vendorName"
                    onChange={handleoemSelect}
                    style={{
                      maxHeight: "150px",
                      overflowY: "auto",
                    }}
                    value={selectedoem}
                  >
                    <option value="">OEM Name</option>
                    {jsonData &&
                      Object.keys(jsonData).map((company) => (
                        <option key={company} value={company}>
                          {company}
                        </option>
                      ))}
                  </select>
                  <select
                    id="productName"
                    onChange={handleProductSelect}
                    style={{
                      maxHeight: "150px",
                      overflowY: "auto",
                    }}
                    value={selectedProduct}
                  >
                    <option value="">Products Name</option>
                    {selectedoem &&
                      jsonData[selectedoem] &&
                      Object.keys(jsonData[selectedoem]).map((product) => (
                        <option key={product} value={product}>
                          {product}
                        </option>
                      ))}
                  </select>
                </div>
                <br />
                {/* Products Table */}
                <div
                  className="scroll-container"
                  style={{
                    maxHeight: "400px",
                    overflowY: "auto",
                  }}
                >
                  <table className="table">
                    <thead>
                      <tr>
                        <th>OEM Name</th>
                        <th>Product Name</th>
                        <th>SKU</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {jsonData &&
                        Object.entries(jsonData).map(
                          ([company, products]) =>
                            (!selectedoem || selectedoem === company) &&
                            Object.entries(products).map(
                              ([product, SKUs]) =>
                                (!selectedProduct ||
                                  selectedProduct === product) &&
                                Object.entries(SKUs).map(([SKU, values]) => (
                                  <tr key={`${company}-${product}-${SKU}`}>
                                    <td>{company}</td>
                                    <td>{product}</td>
                                    <td>{SKU}</td>
                                    <td>{values[1]}</td>
                                  </tr>
                                ))
                            )
                        )}
                    </tbody>
                  </table>
                </div>
                {/* Action Buttons */}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Click to Add Product</Tooltip>}
                    onEnter={() => handleTooltipHover(true)}
                    onLeave={() => handleTooltipHover(false)}
                  >
                    <Button
                      variant="primary"
                      onClick={() => setShowModal(true)}
                      onMouseEnter={() => handleTooltipHover(true)}
                      onMouseLeave={() => handleTooltipHover(false)}
                    >
                      Add Product
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Click to Remove Product</Tooltip>}
                    onEnter={() => handleTooltipHover(true)}
                    onLeave={() => handleTooltipHover(false)}
                  >
                    <Button
                      variant="danger"
                      onClick={handleRemoveProduct}
                      onMouseEnter={() => handleTooltipHover(true)}
                      onMouseLeave={() => handleTooltipHover(false)}
                      style={{
                        marginLeft: "20px",
                      }}
                    >
                      Remove Product
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Click to Edit Product</Tooltip>}
                    onEnter={() => handleTooltipHover(true)}
                    onLeave={() => handleTooltipHover(false)}
                  >
                    <Button
                      variant="success"
                      onClick={handleEditProduct}
                      onMouseEnter={() => handleTooltipHover(true)}
                      onMouseLeave={() => handleTooltipHover(false)}
                      style={{
                        marginLeft: "20px",
                      }}
                    >
                      Edit Product
                    </Button>
                  </OverlayTrigger>
                </div>
              </Tab>
              <Tab eventKey="priceHistory" title="Price History">
                <div
                  className="table-wrapper-scroll-y my-custom-scrollbar"
                  style={{ maxHeight: "400px", overflowY: "scroll" }}
                >
                  <h5>Price History</h5>
                  <div style={{ marginBottom: "10px" }}>
                    <select
                      value={selectedoem}
                      onChange={(e) => setSelectedoem(e.target.value)}
                      style={{ marginRight: "10px" }}
                    >
                      <option value="">Select OEM Name</option>
                      {jsonData &&
                        Object.keys(jsonData).map((company) => (
                          <option key={company} value={company}>
                            {company}
                          </option>
                        ))}
                    </select>
                    <select
                      value={selectedProduct}
                      onChange={(e) => setSelectedProduct(e.target.value)}
                    >
                      <option value="">Select Product Name</option>
                      {selectedoem &&
                        jsonData[selectedoem] &&
                        Object.keys(jsonData[selectedoem]).map((product) => (
                          <option key={product} value={product}>
                            {product}
                          </option>
                        ))}
                    </select>
                  </div>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>OEM Name</th>
                        <th>Product Name</th>
                        <th>Sku Name</th>
                        <th>Old Price</th>
                        <th>New Price</th>
                        <th>Updated On</th>
                      </tr>
                    </thead>
                    <tbody>
                      {priceHistory
                        .filter(
                          (entry) =>
                            (selectedoem === "" ||
                              entry.oem_name === selectedoem) &&
                            (selectedProduct === "" ||
                              entry.product_name === selectedProduct)
                        )
                        .map((filteredEntry, index) => (
                          <tr key={index}>
                            <td>{filteredEntry.oem_name}</td>
                            <td>{filteredEntry.product_name}</td>
                            <td>{filteredEntry.sku_name}</td>
                            <td>{filteredEntry.oldprice}</td>
                            <td>{filteredEntry.newprice}</td>
                            <td>{filteredEntry.updated_on}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </Tab>
            </Tabs>

            {/* **Updated Add Product Modal** */}
            <Modal show={showModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Add Product</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* OEM Selection */}
                <div className="form-group">
                  <label htmlFor="oemName">OEM Name</label>
                  <select
                    id="oemName"
                    className="form-control"
                    onChange={handleModalOEMSelect}
                    value={modalSelectedOEM}
                  >
                    <option value="">Select OEM</option>
                    {jsonData &&
                      Object.keys(jsonData).map((company) => (
                        <option key={company} value={company}>
                          {company}
                        </option>
                      ))}
                    <option value="__ADD_NEW_OEM__">Add New OEM</option>{" "}
                    {/* Add New OEM Option */}
                  </select>
                </div>

                {/* Conditional Input for New OEM */}
                {isAddingNewOEM && (
                  <div className="form-group">
                    <label htmlFor="newOEMName">New OEM Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="newOEMName"
                      value={newOEMName}
                      onChange={(e) => setNewOEMName(e.target.value)}
                    />
                  </div>
                )}

                {/* Product Selection */}
                <div className="form-group">
                  <label htmlFor="productName">Product Name</label>
                  <select
                    id="productName"
                    className="form-control"
                    onChange={handleModalProductSelect}
                    value={modalSelectedProduct}
                  >
                    <option value="">Select Product</option>
                    {modalSelectedOEM &&
                      jsonData[modalSelectedOEM] &&
                      Object.keys(jsonData[modalSelectedOEM]).map((product) => (
                        <option key={product} value={product}>
                          {product}
                        </option>
                      ))}
                    <option value="__ADD_NEW_PRODUCT__">Add New Product</option>{" "}
                    {/* Add New Product Option */}
                  </select>
                </div>

                {/* Conditional Input for New Product */}
                {isAddingNewProduct && (
                  <div className="form-group">
                    <label htmlFor="newProductName">New Product Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="newProductName"
                      value={newProductName}
                      onChange={(e) => setNewProductName(e.target.value)}
                    />
                  </div>
                )}

                {/* SKU Selection */}
                <div className="form-group">
                  <label htmlFor="sku">SKU</label>
                  <input
                    type="text"
                    className="form-control"
                    id="sku"
                    value={selectedSKU}
                    onChange={handleSKUChange}
                  />
                </div>
                {/* Price Input */}
                <div className="form-group">
                  <label htmlFor="price">Price</label>
                  <input
                    type="text"
                    className="form-control"
                    id="price"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleAddProduct}>
                  Add Product
                </Button>
              </Modal.Footer>
            </Modal>

            {/* Modal for Remove Product */}
            <Modal show={removeModalShow} onHide={handleRemoveModalClose}>
              <Modal.Header closeButton>
                <Modal.Title>Remove Product</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group">
                  <label htmlFor="removeParameter">Select Parameter</label>
                  <select
                    id="removeParameter"
                    className="form-control"
                    onChange={(e) => setRemoveParameter(e.target.value)}
                    value={removeParameter}
                  >
                    <option value="">Select Parameter</option>
                    <option value="OEM Name">OEM Name</option>
                    <option value="Product Name">Product Name</option>
                    <option value="SKU">SKU</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="removeValue">Enter Value</label>
                  <input
                    type="text"
                    className="form-control"
                    id="removeValue"
                    value={removeSelectedValue}
                    onChange={(e) => setRemoveSelectedValue(e.target.value)}
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleRemoveModalClose}>
                  Close
                </Button>
                <Button variant="danger" onClick={handleRemoveProductConfirm}>
                  Remove Product
                </Button>
              </Modal.Footer>
            </Modal>

            {/* Modal for Edit Product */}
            <Modal show={editModalShow} onHide={handleEditModalClose}>
              <Modal.Header closeButton>
                <Modal.Title>Edit Product</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group">
                  <label>OEM Name:</label>
                  <select
                    className="form-control"
                    value={editSelectedOEM}
                    onChange={handleEditOEMSelect}
                  >
                    <option value="">Select OEM</option>
                    {jsonData &&
                      Object.keys(jsonData).map((company) => (
                        <option key={company} value={company}>
                          {company}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-group">
                  <label>Product Name:</label>
                  <select
                    className="form-control"
                    value={editSelectedProduct}
                    onChange={handleEditProductSelect}
                    disabled={!editSelectedOEM}
                  >
                    <option value="">Select Product</option>
                    {editSelectedOEM &&
                      jsonData[editSelectedOEM] &&
                      Object.keys(jsonData[editSelectedOEM]).map((product) => (
                        <option key={product} value={product}>
                          {product}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-group">
                  <label>SKU Name:</label>
                  <select
                    className="form-control"
                    value={editSelectedSKU}
                    onChange={handleEditSKUSelect}
                    disabled={!editSelectedProduct}
                  >
                    <option value="">Select SKU</option>
                    {editSelectedProduct &&
                      jsonData[editSelectedOEM][editSelectedProduct] &&
                      Object.keys(
                        jsonData[editSelectedOEM][editSelectedProduct]
                      ).map((sku) => (
                        <option key={sku} value={sku}>
                          {sku}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-group">
                  <label>Old Price:</label>
                  <input
                    type="number"
                    className="form-control"
                    value={oldPrice}
                    onChange={handleOldPriceChange}
                  />
                </div>
                <div className="form-group">
                  <label>New Price:</label>
                  <input
                    type="number"
                    className="form-control"
                    value={newPrice}
                    onChange={handleNewPriceChange}
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleEditModalClose}>
                  Close
                </Button>
                <Button variant="success" onClick={handleSaveEditProduct}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default ProductMaster;
