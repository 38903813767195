import React, { Fragment, useState, useEffect } from "react";
import {
  getCustomerlist,
  updateCustomerDetails,
  deleteCustomerDetails,
} from "../../utils/api"; // Assuming you have an API function for deleting customers
import { useCookies } from "react-cookie";
import { Modal, Button, Dropdown } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";

const RenewalList = () => {
  // useEffect(() => {
  //   document.title = "Renewal Lists"; // Set the document title
  // }, []);
  const thStyles = {
    position: "sticky",
    top: 0,
    backgroundColor: "#f1f1f1",
    zIndex: 100,
  };
  return (
    <Fragment>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="mb-1">Renewal list Comming Soon</h4>
            <br />
            <div className="search-container mb-3">
              <input
                type="text"
                placeholder="Search by domain name..."
                // value={searchInput}
                // onChange={(e) => handleSearchInputChange(e.target.value)}
                onChange={{}}
              />
              <button className="btn btn-primary ml-2" onClick={{}}>
                Reset
              </button>
            </div>
            <div
              className="table-responsive"
              style={{ maxHeight: "500px", overflowY: "auto" }}
            >
              <table className="table table-hover sticky-header">
                <thead>
                  <tr>
                    <th style={thStyles}>Renewal ID</th>
                    <th style={thStyles}>Domain Name</th>
                    <th style={thStyles}>Start Date</th>
                    <th style={thStyles}>Expiry Date</th>
                    <th style={thStyles}>Status</th>
                    {/* <th style={thStyles}>Status</th> */}
                    <th style={thStyles}>Reseller ID</th>
                    <th style={thStyles}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {/* {cases.map((user) => (
                    <tr key={user.case_id}>
                      <td>{user.case_id}</td>
                      <td>{user.customer_name}</td>
                      <td>{user.domain_name}</td>
                      <td>{user.created_date}</td>
                      <td>{user.status}</td>
                      <td>{user.reseller_id}</td>
                      <td>{renderActionButtons(user)}</td>
                    </tr>
                    <tr >
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  ))} */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default RenewalList;
