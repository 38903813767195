import React, { Fragment, useState, useEffect } from "react";
// import { verifyKYCDetails } from "../../utils/api";

const UserRegistrationkysviewdetailsmodal = () => {
  const [formData, setFormData] = useState({
    ownerfullname: "",
    owneremailid: "",
    secondcontactname: "",
    secondcontactemailid: "",
    accountcontactname: "",
    accountcontactemailid: "",
    udyogadharflag: "",
    udyogadhaarnumber: "",
    pannumber: "",
    gstnumber: "",
    tannumber: "",
    pr_address: "",
    pr_city: "",
    pr_state: "",
    pr_pincode: "",
    contactnumber: "",
    bankname: "",
    branchname: "",
    accountnumber: "",
    ifsccode: "",
    reseller_email: "",
    gstcertificate: null,
    panCard: null,
    cancelCheque: null,
    gstcertificateURL: "",
    pancardURL: "",
    cancelChequeURL: "",
    userRegID: "",
    secondarycontactnumber: "",
    accountcontactnumber: "",
    region: "",
    accountholdername: "",
    typeofcompany: "",
  });
  const [fieldStatus, setFieldStatus] = useState({
    pannumber: null,
    panCard: null,
    gstnumber: null,
    gstcertificate: null,
    tannumber: null,
  });
  const [updatedFields, setUpdatedFields] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // document.title = "Registration Page"; // Set the document title

    // Retrieve data from local storage
    // const loginDataFromStorage = localStorage.getItem("loginData");
    // const loginDataStatusFromStorage = localStorage.getItem("loginDatastatus");
    const kycDataStorage = localStorage.getItem("kycdetails");

    if (kycDataStorage) {
      try {
        const parsedLoginData = JSON.parse(kycDataStorage);
        console.log("yedi por", parsedLoginData);
        // console.log("pancard", parsedLoginData.documents);
        setFormData((prevFormData) => ({
          ...prevFormData,

          ownerfullname: parsedLoginData.owner_full_name || "",
          owneremailid: parsedLoginData.owner_email || "",
          secondcontactname: parsedLoginData.secondary_contact_name || "",
          secondcontactemailid: parsedLoginData.secondary_contact_email || "",
          accountcontactname: parsedLoginData.account_contact_name || "",
          accountcontactemailid: parsedLoginData.account_contact_email || "",
          udyogadharflag: parsedLoginData.udyog_adhaar || "",
          udyogadhaarnumber: parsedLoginData.udyog_adhaar_number || "",
          pannumber: parsedLoginData.pan_number || "",
          gstnumber: parsedLoginData.gst_number || "",
          tannumber: parsedLoginData.tan_number || "",
          pr_address: parsedLoginData.address || "",
          pr_city: parsedLoginData.city || "",
          pr_state: parsedLoginData.state || "",
          pr_pincode: parsedLoginData.pincode || "",
          contactnumber: parsedLoginData.contact_number || "",
          bankname: parsedLoginData.bank_name || "",
          branchname: parsedLoginData.branch_name || "",
          accountnumber: parsedLoginData.acc_number || "",
          ifsccode: parsedLoginData.ifsc_code || "",
          userRegID: parsedLoginData.registration_id || "",
          pancardURL: parsedLoginData.documents[0].panCard,
          gstcertificateURL: parsedLoginData.documents[1].gstcertificate,
          cancelChequeURL: parsedLoginData.documents[2].cancelCheque,
          secondarycontactnumber:
            parsedLoginData.secondary_contact_number || "",
          accountcontactnumber: parsedLoginData.account_contact_number || "",
          region: parsedLoginData.region || "",
          accountholdername: parsedLoginData.account_holder_name || "",
          typeofcompany: parsedLoginData.type_of_company || "",
        }));
      } catch (error) {
        console.error("Error parsing JSON from local storage:", error);
      }
    }
  }, []);

  const handleFileUpload = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: file,
      [`${name}status`]: "", // Set the corresponding status to empty when file is uploaded
    }));

    // Update updatedFields with the new file data
    const updatedFieldName = name.replace("Card", "");
    setUpdatedFields((prevUpdatedFields) => ({
      ...prevUpdatedFields,
      [updatedFieldName]: file,
    }));
  };
  const handleFieldAction = (field, action) => {
    setFieldStatus((prevStatus) => ({
      ...prevStatus,
      [field]: action,
    }));
  };
//   const handleSubmit = async (event, action) => {
//     event.preventDefault();

//     try {
//       setLoading(true);
//       const loginDataFromStorage = localStorage.getItem("kycdetails");
//       const parsedLoginData = JSON.parse(loginDataFromStorage);

//       let reason = ""; // Initialize reason variable

//       if (action === "Permanently Reject") {
//         // Directly prompt the user for a reason
//         reason = prompt("Please provide a reason for rejection:");
//         if (!reason) {
//           alert("Reason is required for rejection.");
//           return;
//         }
//       } else {
//         // Check if all fields have been reviewed and have a status
//         const allFieldsReviewed = Object.values(fieldStatus).every(
//           (status) => status !== null
//         );

//         if (!allFieldsReviewed) {
//           alert("Please review all fields before submitting.");
//           return;
//         }

//         // For "Reject" or "Approve," ask for reason only if action is "Reject"
//         if (action === "Rejected") {
//           reason = prompt("Please provide a reason for rejection:");
//           if (!reason) {
//             alert("Reason is required for rejection.");
//             return;
//           }
//         }
//       }

//       const jsonData = {
//         buttonStatus: action,
//         reason: reason.trim() || "", // Include reason in jsonData
//       }; // Include button status in jsonData

//       for (const [field, status] of Object.entries(fieldStatus)) {
//         if (
//           status === "Accepted" ||
//           (status === null && action === "Approved")
//         ) {
//           jsonData[field] = "accepted";
//         } else if (
//           status === "Rejected" ||
//           (status === null &&
//             (action === "Rejected" || action === "Permanently Reject"))
//         ) {
//           jsonData[field] = "rejected";
//         }
//       }

//       jsonData["registration_id"] = parsedLoginData.registration_id;
//       jsonData["reseller_id"] = parsedLoginData.reseller_id;
//       console.log(jsonData);
//       const response = await verifyKYCDetails(jsonData);
//       console.log(response);
//       if (response.status === 200) {
//         const resp = await response.json();
//         window.alert(resp.message);
//         window.location.href = "/adminmaincomponent";
//       }
//       // You can proceed with submitting jsonData to your API or perform other actions
//     } catch (error) {
//       window.alert("Error for verify the details :", error);
//     } finally {
//       setLoading(false);
//     }
//   };

  const openFilePopup = (fileUrl) => {
    console.log(fileUrl);
    // Open a new window to display the file
    window.open(fileUrl, "_blank", "width=800,height=600");
  };
  return (
    <Fragment>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="col-12 grid-margin">
        <div className="card">
          <div className="card-body">
            {/* <h4 className="mb-1">Reseller KYC Regiatration</h4> */}
            <br></br>
            <form className="form-sample">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Owner Full Name <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="ownerfullname"
                        className="form-control"
                        // style={getBorderStyle(formData.ownerNamestatus)}
                        placeholder="Owner Full Name"
                        value={formData.ownerfullname}
                        readOnly
                      />
                      {/* <small className="text-danger">
                        Note:-Enter the name as per PAN card document
                      </small> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Owner Email<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        // style={getBorderStyle(formData.ownerEmailstatus)}
                        placeholder="Owner Email"
                        name="owneremailid"
                        value={formData.owneremailid}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Secondary Contact Name
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        name="secondcontactname"
                        // style={getBorderStyle(formData.contactNamestatus)}
                        placeholder="Secondary Contact Name"
                        value={formData.secondcontactname}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Account Contact Name<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="accountcontactname"
                        className="form-control"
                        // style={getBorderStyle(formData.accountContactNamestatus)}
                        placeholder="Account Contact Name"
                        value={formData.accountcontactname}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Secondary Contact Email
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="secondcontactemailid"
                        className="form-control"
                        // style={getBorderStyle(formData.contactEmailstatus)}
                        placeholder="Secondary Contact Email"
                        value={formData.secondcontactemailid}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Account Contact Email
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="accountcontactemailid"
                        className="form-control"
                        // style={getBorderStyle(formData.accountContactEmailstatus)}
                        placeholder="Account Contact Email"
                        value={formData.accountcontactemailid}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Secondary Contact Number
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="secondarycontactnumber"
                        className="form-control"
                        // style={getBorderStyle(formData.accountContactNamestatus)}
                        placeholder="Secondary Contact Number"
                        value={formData.secondarycontactnumber}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Account Contact Number
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="accountcontactnumber"
                        className="form-control"
                        // style={getBorderStyle(formData.accountContactEmailstatus)}
                        placeholder="Account Contact Number"
                        value={formData.accountcontactnumber}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Udyog Adhaar
                    </label>
                    <div className="col-sm-2">
                      <input
                        type="radio"
                        name="udyogadharflag"
                        value="Yes"
                        checked={formData.udyogadharflag === "Yes"}
                      />
                      Yes{" "}
                      <input
                        type="radio"
                        name="udyogadharflag"
                        value="No"
                        checked={formData.udyogadharflag === "No"}
                      />{" "}
                      No
                    </div>
                  </div>
                </div>

                {formData.udyogadharflag === "Yes" && (
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label text-left">
                        Udyog Adhaar Number
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          name="udyogadhaarnumber"
                          className="form-control "
                          placeholder="Udyog Adhaar Number"
                          value={formData.udyogadhaarnumber}
                          required={formData.udyogadharflag === "Yes"}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Pancard Number<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="pannumber"
                        className="form-control"
                        // style={getBorderStyle(formData.accountContactNamestatus)}
                        placeholder="Pancard Number"
                        value={formData.pannumber}
                        readOnly
                      />
                      <br></br>
                      {/* <span
                        style={{
                          cursor: "pointer",
                          color:
                            fieldStatus["pannumber"] === "Accepted"
                              ? "green"
                              : "inherit",
                        }}
                        onClick={() =>
                          handleFieldAction("pannumber", "Accepted")
                        }
                      >
                        ✔
                      </span>
                      {"     "}
                      <span
                        style={{
                          cursor: "pointer",
                          color:
                            fieldStatus["pannumber"] === "Rejected"
                              ? "red"
                              : "inherit",
                        }}
                        onClick={() =>
                          handleFieldAction("pannumber", "Rejected")
                        }
                      >
                        ✘
                      </span> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      GST Number
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="gstnumber"
                        className="form-control "
                        // style={getBorderStyle(formData.accountContactEmailstatus)}
                        placeholder="GST Number"
                        value={formData.gstnumber}
                        readOnly
                      />
                      <br></br>
                      {/* <span
                        style={{
                          cursor: "pointer",
                          color:
                            fieldStatus["gstnumber"] === "Accepted"
                              ? "green"
                              : "inherit",
                        }}
                        onClick={() =>
                          handleFieldAction("gstnumber", "Accepted")
                        }
                      >
                        ✔
                      </span>
                      {"     "}
                      {"     "}
                      <span
                        style={{
                          cursor: "pointer",
                          color:
                            fieldStatus["gstnumber"] === "Rejected"
                              ? "red"
                              : "inherit",
                        }}
                        onClick={() =>
                          handleFieldAction("gstnumber", "Rejected")
                        }
                      >
                        ✘
                      </span> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Uploaded Pan Card<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      {formData.pancardURL ? (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => openFilePopup(formData.pancardURL)}
                          title="View Document"
                        >
                          View
                        </button>
                      ) : (
                        <input
                          type="file"
                          className="form-control"
                          name="panCard"
                          onChange={handleFileUpload}
                          accept=".pdf,.jpg,.jpeg,.png"
                          required
                        />
                      )}{" "}
                      {/* <span
                        style={{
                          cursor: "pointer",
                          color:
                            fieldStatus["panCard"] === "Accepted"
                              ? "green"
                              : "inherit",
                        }}
                        onClick={() => handleFieldAction("panCard", "Accepted")}
                      >
                        ✔
                      </span>{" "}
                      <span
                        style={{
                          cursor: "pointer",
                          color:
                            fieldStatus["panCard"] === "Rejected"
                              ? "red"
                              : "inherit",
                        }}
                        onClick={() => handleFieldAction("panCard", "Rejected")}
                      >
                        ✘
                      </span> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Uploaded GST Certificate
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      {formData.gstcertificateURL ? (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() =>
                            openFilePopup(formData.gstcertificateURL)
                          }
                          title="View Document"
                        >
                          View
                        </button>
                      ) : (
                        <input
                          type="file"
                          className="form-control"
                          name="gstcertificate"
                          onChange={handleFileUpload}
                          accept=".pdf,.jpg,.jpeg,.png"
                          required
                        />
                      )}{" "}
                      {/* <span
                        style={{
                          cursor: "pointer",
                          color:
                            fieldStatus["gstcertificate"] === "Accepted"
                              ? "green"
                              : "inherit",
                        }}
                        onClick={() =>
                          handleFieldAction("gstcertificate", "Accepted")
                        }
                      >
                        ✔
                      </span>{" "}
                      <span
                        style={{
                          cursor: "pointer",
                          color:
                            fieldStatus["gstcertificate"] === "Rejected"
                              ? "red"
                              : "inherit",
                        }}
                        onClick={() =>
                          handleFieldAction("gstcertificate", "Rejected")
                        }
                      >
                        ✘
                      </span> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      TAN Number<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="tannumber"
                        className="form-control"
                        // style={getBorderStyle(formData.accountContactNamestatus)}
                        placeholder="TAN Number"
                        value={formData.tannumber}
                        readOnly
                      />
                      <br></br>
                      {/* <span
                        style={{
                          cursor: "pointer",
                          color:
                            fieldStatus["tannumber"] === "Accepted"
                              ? "green"
                              : "inherit",
                        }}
                        onClick={() =>
                          handleFieldAction("tannumber", "Accepted")
                        }
                      >
                        ✔
                      </span>{" "}
                      <span
                        style={{
                          cursor: "pointer",
                          color:
                            fieldStatus["tannumber"] === "Rejected"
                              ? "red"
                              : "inherit",
                        }}
                        onClick={() =>
                          handleFieldAction("tannumber", "Rejected")
                        }
                      >
                        ✘
                      </span> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Address <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        className="form-control"
                        rows="2"
                        name="pr_address"
                        value={formData.pr_address}
                        readOnly
                        style={{ resize: "vertical" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Pincode<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="pr_pincode"
                        className="form-control "
                        // style={getBorderStyle(formData.accountContactNamestatus)}
                        placeholder="Pincode"
                        value={formData.pr_pincode}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      City<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="pr_city"
                        className="form-control"
                        // style={getBorderStyle(formData.accountContactNamestatus)}
                        placeholder="City"
                        value={formData.pr_city}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      State<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="pr_state"
                        className="form-control"
                        // style={getBorderStyle(formData.accountContactNamestatus)}
                        placeholder="State"
                        value={formData.pr_state}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Contact Number<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="contactnumber"
                        className="form-control "
                        // style={getBorderStyle(formData.accountContactNamestatus)}
                        placeholder="Contact Number"
                        value={formData.contactnumber}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Company Type<span className="text-danger">*</span>
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="typeofcompany"
                        className="form-control "
                        // style={getBorderStyle(formData.accountContactNamestatus)}
                        placeholder="Company Type"
                        value={formData.typeofcompany}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Region<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="region"
                        className="form-control "
                        // style={getBorderStyle(formData.accountContactNamestatus)}
                        placeholder="Rgion"
                        value={formData.region}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label text-left">
                      IFSC Code<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-6 d-flex align-items-center">
                      <input
                        type="text"
                        name="ifsccode"
                        className="form-control "
                        placeholder="IFSC Code"
                        value={formData.ifsccode}
                        readOnly
                      />
                      {/* <button
                        className="btn btn-primary ml-2"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault(); // Prevent default form submission
                          verifyIFSCCode(); // Call the verifyIFSCCode function
                        }}
                      >
                        Verify
                      </button> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Bank Name<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="bankname"
                        className="form-control"
                        placeholder="Bank Name"
                        value={formData.bankname}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Branch Name<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="branchname"
                        className="form-control"
                        placeholder="Branch Name"
                        value={formData.branchname}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Bank Account Number<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="accountnumber"
                        className="form-control "
                        // style={getBorderStyle(formData.accountContactNamestatus)}
                        placeholder="Bank Account Number"
                        value={formData.accountnumber}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Account Holder Name<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="accountholdername"
                        className="form-control"
                        placeholder="Account Holder Name"
                        value={formData.accountholdername}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Canceled Cheque
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      {formData.cancelChequeURL ? (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() =>
                            openFilePopup(formData.cancelChequeURL)
                          }
                          title="View Document"
                        >
                          View
                        </button>
                      ) : (
                        <input
                          type="file"
                          className="form-control"
                          name="cancelCheque"
                          onChange={handleFileUpload}
                          accept=".pdf,.jpg,.jpeg,.png"
                          required
                        />
                      )}{" "}
                      {/* <span
                        style={{
                          cursor: "pointer",
                          color:
                            fieldStatus["cancelCheque"] === "Accepted"
                              ? "green"
                              : "inherit",
                        }}
                        onClick={() =>
                          handleFieldAction("cancelCheque", "Accepted")
                        }
                      >
                        ✔
                      </span>{" "}
                      <span
                        style={{
                          cursor: "pointer",
                          color:
                            fieldStatus["cancelCheque"] === "Rejected"
                              ? "red"
                              : "inherit",
                        }}
                        onClick={() =>
                          handleFieldAction("cancelCheque", "Rejected")
                        }
                      >
                        ✘
                      </span> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="row mt-4">
                <div className="col-md-12 text-center">
                  <button
                    className="btn btn-success mr-2"
                    onClick={(event) => handleSubmit(event, "Approved")}
                  >
                    Approve
                  </button>
                  <button
                    className="btn btn-danger mr-2"
                    onClick={(event) => handleSubmit(event, "Rejected")}
                  >
                    Reject
                  </button>
                  <button
                    className="btn btn-warning"
                    onClick={(event) =>
                      handleSubmit(event, "Permanently Reject")
                    }
                  >
                    Permanently Reject
                  </button>
                </div>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UserRegistrationkysviewdetailsmodal;
