import React, { Fragment, useState, useEffect } from "react";
import {
  getCustomerlist,
  updateCustomerDetails,
  deleteCustomerDetails,
} from "../../utils/api"; // Assuming you have an API function for deleting customers
import { useCookies } from "react-cookie";
import { Modal, Button, Dropdown } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";

const KnowledgePage = () => {
  // useEffect(() => {
  //   document.title = "Knowledge Page "; // Set the document title
  // }, []);
  return (
    <Fragment>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="mb-1">Knowledge Page Comming Soon</h4>
            <br />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default KnowledgePage;
