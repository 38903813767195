import React, { Fragment, useState, useEffect } from "react";
import { adminsendOTPforget } from "../../utils/api"; // Import the signup and sendOTP API functions
import { Link } from "react-router-dom";

function AdminForgetPass() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    document.title = "Admin Forget Password Page"; // Set the document title
  }, []);
  const handleSendMail = async (event) => {
    event.preventDefault();
    if (!email.trim()) {
      // Check if the email field is empty
      window.alert("Please enter your email address.");
      return;
    }
    try {
      setLoading(true);
      console.log(email); // Ensure email value is correct
      const response = await adminsendOTPforget(email); // Pass email value to sendOTP function
      if (response.status === 200) {
        window.alert("OTP and Reset Link Sent on your EmailID");
        window.location.href = "/adminlogin";
        console.log("OTP and Reset Link Sent on your EmailID");
      } else {
        const data = await response.json(); // Extract error message from response body
        window.alert(data.message); // Display error message in alert
      }
    } catch (error) {
      window.alert("Failed to send OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <Fragment>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth register-bg-1 theme-one">
            <div className="row w-100">
              <div className="col-lg-4 mx-auto text-center">
                <img
                  src="../../assets/images/Shiviom-Logo.png"
                  className="img"
                  alt=""
                />{" "}
                <h1 className="mb-5">Recover Your Password</h1>
                <p style={{ color: "#FFFFFF" }}>
                  Enter your email address and an OTP will be emailed to you.
                </p>
                <div className="auto-form-wrapper">
                  <form action="#">
                    <div className="form-group">
                      <div className="input-group">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="mdi mdi-check-circle-outline"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* 
                    <div className="form-group d-flex justify-content-center">
                      <div className="form-check form-check-flat mt-0">
                        <label className="form-check-label">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked
                          />{" "}
                          I agree to the terms{" "}
                        </label>
                      </div>
                    </div> */}
                    <div className="form-group">
                      <button
                        className="btn btn-primary submit-btn btn-block"
                        type="submit"
                        onClick={handleSendMail}
                      >
                        Send OTP
                      </button>
                    </div>
                    <div className="text-block text-center my-3">
                      <span className="text-small font-weight-semibold">
                        Already have and account ?
                      </span>
                      <Link to="/adminLogin" className="text-black text-small">
                        {" "}
                        {/* Use Link for navigation */}
                        Login
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <script src="../../assets/vendors/js/vendor.bundle.base.js"></script>
      <script src="../../assets/vendors/js/vendor.bundle.addons.js"></script>
      <script src="../../assets/js/shared/off-canvas.js"></script>
      <script src="../../assets/js/shared/misc.js"></script>
      <script
        src="../../assets/js/shared/jquery.cookie.js"
        type="text/javascript"
      ></script>
    </Fragment>
  );
}

export default AdminForgetPass;
