import React, { Fragment, useState, useEffect } from "react";
import { resellerresetpassword } from "../../utils/api"; // Import your resetPassword API function
import { Link } from "react-router-dom";

const UserResetPassword = () => {
  const [otp, setOtp] = useState(""); // State for OTP digits
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userIdParam, setUserId] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "Reset Password Page"; // Set the document title

    // Get userIdParam from URL params

    const params = new URLSearchParams(window.location.search);
    const userIdParamFromUrl = params.get("id");

    if (userIdParamFromUrl) {
      setUserId(userIdParamFromUrl);
    }
  }, []);

  //   const handleChangeOtp = (index, value) => {
  //     const updatedOtp = [...otp];
  //     updatedOtp[index] = value;
  //     setOtp(updatedOtp);
  //   };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // const enteredOtp = otp.join("");
    try {
      setLoading(true);
      const response = await resellerresetpassword(
        userIdParam,
        otp,
        newPassword,
        confirmPassword
      );
      if (response.status === 200) {
        // Password reset successful, redirect to login page
        window.alert("Password Reset Successfull");
        window.location.href = "/userlogin";
      } else {
        const data = await response.json(); // Extract error message from response body
        window.alert(data.message); // Display error message in alert
      }
    } catch (error) {
      console.error("Reset password error:", error);
      //setError("Error resetting password. Please try again.");
      window.alert("Error resetting password. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth register-bg-1 theme-one">
            <div className="row w-100">
              <div className="col-lg-4 mx-auto text-center">
                <img
                  src="../../assets/images/Shiviom-Logo.png"
                  className="img"
                  alt=""
                />{" "}
                <h1 className="mb-5">Reset Your Password</h1>
                <p style={{ color: "#FFFFFF" }}>
                  Enter the OTP and Enter Your new Password
                </p>
                <div className="auto-form-wrapper">
                  <form action="#">
                    <div className="form-group">
                      <div className="input-group">
                        <input
                          type="otp"
                          className="form-control"
                          placeholder="OTP"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="mdi mdi-check-circle-outline"></i>
                          </span>
                        </div>
                      </div>
                      <br></br>
                      <div className="input-group">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="New Password"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="mdi mdi-check-circle-outline"></i>
                          </span>
                        </div>
                      </div>
                      <br></br>
                      <div className="input-group">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Cnfrm Password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="mdi mdi-check-circle-outline"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* 
                  <div className="form-group d-flex justify-content-center">
                    <div className="form-check form-check-flat mt-0">
                      <label className="form-check-label">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked
                        />{" "}
                        I agree to the terms{" "}
                      </label>
                    </div>
                  </div> */}
                    <div className="form-group">
                      <button
                        className="btn btn-primary submit-btn btn-block"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Reset Password
                      </button>
                    </div>
                    <div className="text-block text-center my-3">
                      <span className="text-small font-weight-semibold">
                        Already have and account ?
                      </span>
                      <Link to="/adminLogin" className="text-black text-small">
                        {" "}
                        {/* Use Link for navigation */}
                        Login
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <script src="../../assets/vendors/js/vendor.bundle.base.js"></script>
      <script src="../../assets/vendors/js/vendor.bundle.addons.js"></script>
      <script src="../../assets/js/shared/off-canvas.js"></script>
      <script src="../../assets/js/shared/misc.js"></script>
      <script
        src="../../assets/js/shared/jquery.cookie.js"
        type="text/javascript"
      ></script>
    </Fragment>
  );
};

export default UserResetPassword;
