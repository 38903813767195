import React, { Fragment, useState, useEffect } from "react";
import { getAddedLicenceDetails } from "../../utils/api"; // Assuming you have an API function for deleting customers
import { Button } from "react-bootstrap";
import { FaSortUp, FaSortDown, FaSort } from "react-icons/fa";

const AddedLicencesDetails = () => {
  const [customers, setCustomers] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [initialUserList, setInitialUserList] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [loading, setLoading] = useState(false); // Loading state
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Optional: Show a loading spinner if needed.
      try {
        const data = await getAddedLicenceDetails();
        setCustomers(data);
        setInitialUserList(data);
      } catch (error) {
        console.error(error.message);
      } finally {
        setLoading(false); // Optional: Show a loading spinner if needed.
      }
    };

    fetchData();
  }, []);

  const handleSearchInputChange = (value) => {
    setSearchInput(value.toLowerCase());
    const filteredList = initialUserList.filter((user) => {
      if (user) {
        return (
          (user.customer_domainname &&
            user.customer_domainname.toLowerCase().includes(value)) ||
          (user.case_name && user.case_name.toLowerCase().includes(value))
        );
      }
      return false; // Skip this user if domain_name is undefined
    });
    console.log("Filtered List:", filteredList);
    setCustomers(filteredList);
  };

  const handleReset = () => {
    setSearchInput("");
    setCustomers(initialUserList);
  };
  const handleSort = (column) => {
    let sortedList = [...initialUserList];
    if (sortColumn === column) {
      sortedList.reverse(); // Toggle the sorting order
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      sortedList.sort((a, b) => {
        if (a[column] < b[column]) return -1;
        if (a[column] > b[column]) return 1;
        return 0;
      });
      setSortColumn(column);
      setSortOrder("asc");
    }
    setInitialUserList(sortedList);
  };
  const thStyles = {
    position: "sticky",
    top: 0,
    backgroundColor: "#f1f1f1",
    zIndex: 100,
  };

  return (
    <Fragment>
      <style>
        {`
          .sortable-header {
            cursor: pointer;
            position: relative;
          }

          .sort-icon {
            margin-left: 5px;
            color: #6c757d; /* Default color */
            transition: color 0.2s ease;
          }

          .sortable-header:hover .sort-icon {
            color: #007bff; /* Change this to your preferred hover color */
          }

          .table-responsive {
            max-height: 500px;
            overflow-y: auto;
          }
        `}
      </style>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="mb-1">Licence Added Details</h4>
            <br />
            <div className="search-container mb-3">
              <input
                type="text"
                placeholder="Search"
                value={searchInput}
                onChange={(e) => handleSearchInputChange(e.target.value)}
                style={{ float: "left" }}
              />
              <Button
                className="btn btn-primary ml-2"
                onClick={handleReset}
                style={{ float: "left" }}
              >
                Reset
              </Button>
            </div>
            <br></br>
            <div
              className="table-responsive"
              style={{ maxHeight: "450px", overflowY: "auto" }}
            >
              <table className="table table-hover sticky-header">
                <thead>
                  <tr>
                    <th style={thStyles}>Case Name</th>
                    <th
                      style={thStyles}
                      className="sortable-header"
                      onClick={() => handleSort("customer_domainname")}
                    >
                      Customer Domain
                      {sortColumn === "customer_domainname" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" />
                      )}
                    </th>
                    <th style={thStyles}>Product Name</th>
                    <th style={thStyles}>Old Licences</th>
                    <th style={thStyles}>New Licences</th>
                    <th style={thStyles}>Changed Date</th>
                  </tr>
                </thead>
                <tbody>
                  {customers.map((user) => (
                    <tr
                      key={user.cust_id}
                      //   onClick={() => handleView(user.cust_id)}
                      style={{ cursor: "pointer" }}
                    >
                      {/* <td>{user.cust_id}</td> */}
                      <td>{user.case_name}</td>
                      <td>{user.customer_domainname}</td>
                      <td>{user.product_name}</td>
                      <td>{user.olduser_count}</td>
                      <td>{user.newuser_count}</td>
                      <td>
                        {new Date(user.changed_date).toLocaleDateString()}
                      </td>
                      {/* <td>{renderActionButtons(user)}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddedLicencesDetails;
