import React, { Fragment, useState, useEffect } from "react";
import { resellerlogin } from "../../utils/api";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

const UserLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [cookies, setCookie] = useCookies(["email"]); // useCookies hook
  const [loading, setLoading] = useState(false); // Loading state

  useEffect(() => {
    document.title = "Login Page";
    // document.body.style.backgroundImage = `url(${backgroundImage})`;
    // document.body.style.backgroundSize = "cover";
    // document.body.style.padding = "20px";

    // return () => {
    //   document.body.style.backgroundImage = "";
    //   document.body.style.backgroundSize = "";
    //   document.body.style.padding = "";
    // };
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!email.trim() || !password.trim()) {
      window.alert("Please enter your email and password.");
      return;
    }
    setLoading(true); // Set loading state to true
    try {
      const response = await resellerlogin(email, password);
      const responseData = await response.json();
      console.log(responseData);
      if (response.status === 200) {
        const { user_data, reg_data, token } = responseData;

        localStorage.removeItem("token");
        localStorage.setItem("token", token);
        console.log(reg_data);
        if (user_data !== null) {
          const { isactive } = user_data;
          if (isactive === "PENDING") {
            window.alert(
              "Your Account is not activated yet! Wait for Admin Verification"
            );
          } else if (isactive === "ACCEPT") {
            localStorage.removeItem("loginData");
            localStorage.removeItem("loginDatastatus");
            localStorage.removeItem("userDetails");
            localStorage.setItem("loginData", JSON.stringify(reg_data));
            localStorage.setItem(
              "loginDatastatus",
              JSON.stringify(reg_data.fields_names)
            );
            localStorage.setItem("userDetails", JSON.stringify(user_data));

            if (reg_data.kyc_status === null) {
              localStorage.removeItem("errorMessage");
              localStorage.removeItem("userDetails");
              localStorage.setItem("userDetails", JSON.stringify(user_data));
              window.location.href = "/usermaincomponent";
            } else if (reg_data.kyc_status === "Pending") {
              const errorMessage = `Your KYC is in Pending state you have to wait let Admin verify your KYC Details`;
              localStorage.removeItem("errorMessage");
              localStorage.setItem("errorMessage", errorMessage);
              window.location.href = "/usermaincomponent";
            } else if (reg_data.kyc_status === "Approved") {
              if (Object.values(reg_data.fields_names).includes("rejected")) {
                const rejectedFields = Object.keys(
                  reg_data.fields_names
                ).filter((key) => reg_data.fields_names[key] === "rejected");
                const errorMessage = `Your KYC is Approved but the following fields are rejected by the admin: ${rejectedFields.join(
                  ", "
                )}. Please Update it as soon as possible.`;
                localStorage.removeItem("errorMessage");
                localStorage.setItem("errorMessage", errorMessage);
              }
              // window.location.href = "/errorPage"; // Redirect to error page
              else {
                setCookie("email", email, { path: "/" });
                localStorage.removeItem("loginData");
                localStorage.removeItem("loginDatastatus");
                localStorage.removeItem("userDetails");
                localStorage.setItem("loginData", JSON.stringify(reg_data));
                localStorage.setItem(
                  "loginDatastatus",
                  JSON.stringify(reg_data.fields_names)
                );
                localStorage.setItem("userDetails", JSON.stringify(user_data));
              }
              localStorage.removeItem("errorMessage");
              window.location.href = "/usermaincomponent";
            } else if (reg_data.kyc_status === "Rejected") {
              // window.alert(
              //   "Some fields are rejected. Redirecting you to the form page to resubmit."
              // );
              const rejectedFields = Object.keys(reg_data.fields_names).filter(
                (key) => reg_data.fields_names[key] === "rejected"
              );
              const errorMessage = `Your KYC is Rejected following fields are rejected by the admin: ${rejectedFields.join(
                ", "
              )}`;
              localStorage.removeItem("errorMessage");
              localStorage.setItem("errorMessage", errorMessage);
              localStorage.removeItem("loginData");
              localStorage.removeItem("loginDatastatus");
              localStorage.removeItem("userDetails");
              localStorage.setItem("loginData", JSON.stringify(reg_data));
              localStorage.setItem(
                "loginDatastatus",
                JSON.stringify(reg_data.fields_names)
              );
              localStorage.setItem("userDetails", JSON.stringify(user_data));
              window.location.href = "/usermaincomponent";
            } else if (reg_data.kyc_status === "Permanently Reject") {
              console.log("adasdsada");
              window.alert(
                "All your fields are rejected due to unauthorized information."
              );
            }

            setCookie("email", email, { path: "/" });
          } else if (isactive === "REJECT") {
            window.alert(
              "You are not Authorized User for Login Because Admin Rejected Your Account."
            );
          }
        }
      } else if (response.status === 403) {
        window.alert("User is not registered with us.");
      } else if (response.status === 401) {
        window.alert("Username and password are incorrect.");
      } else if (response.status === 404) {
        window.alert("Invalid request.");
      } else {
        setError("Unexpected error occurred.");
      }
    } catch (error) {
      console.error("Login error:", error);
      setError("Login failed. Please try again.");
    } finally {
      setLoading(false); // Set loading state to false after login attempt
    }
  };

  const isLoginFormValid = () => {
    return email.trim() !== "" && password.trim() !== "";
  };

  return (
    <Fragment>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth auth-bg-1 theme-one">
            <div className="row w-100">
              <div className="col-lg-12 text-center mb-5">
                <img
                  src="../../assets/images/Shiviom-Logo.png"
                  className="img"
                  alt=""
                />
                <h1> User/Reseller Login </h1>{" "}
              </div>

              <div className="col-lg-4 mx-auto">
                <div className="auto-form-wrapper">
                  <form onSubmit={handleLogin}>
                    <div className="form-group">
                      <label className="label">Reseller EmailID</label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="mdi mdi-check-circle-outline"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="label">Password</label>
                      <div className="input-group">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="*********"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="mdi mdi-check-circle-outline"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <button
                        className="btn btn-primary submit-btn btn-block"
                        type="submit"
                        disabled={!isLoginFormValid()}
                      >
                        Login
                      </button>
                    </div>
                    <div className="form-group d-flex justify-content-between">
                      {/* <div className="form-check form-check-flat mt-0">
                        <label className="form-check-label">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked
                          />{" "}
                          Keep me signed in{" "}
                        </label>
                      </div> */}
                      <Link
                        to="/userForgetpass"
                        className="text-small forgot-password text-black"
                      >
                        {" "}
                        {/* Use Link for navigation */}
                        Forgot Password
                      </Link>
                    </div>

                    <div className="text-block text-center my-3">
                      <span className="text-small font-weight-semibold">
                        Not a member ?
                      </span>
                      <Link to="/userSignup" className="text-black text-small">
                        {" "}
                        {/* Use Link for navigation */}
                        Create new account
                      </Link>
                    </div>
                  </form>
                </div>
                <ul className="auth-footer"></ul>
                <p className="footer-text text-center mt-5">
                  copyright © 2024 Shiviom. All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <script src="../../assets/vendors/js/vendor.bundle.base.js"></script>
      <script src="../../assets/vendors/js/vendor.bundle.addons.js"></script>

      <script src="../../assets/js/shared/off-canvas.js"></script>
      <script src="../../assets/js/shared/misc.js"></script>
      <script
        src="../../assets/js/shared/jquery.cookie.js"
        type="text/javascript"
      ></script>
    </Fragment>
  );
};

export default UserLogin;
