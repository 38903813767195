import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  update_user_account_details,
  update_admin_account_details,
  resellersendOTP,
  resellerverifyOTP,
  resellerupdatepassword,
} from "../../utils/api";
import UserRegistrationFormModal from "./UserRegistrationFormModal"; // Import the UserRegistrationFormModal component

const Navbar = ({ data, isAdmin }) => {
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [profileInfo, setProfileInfo] = useState({
    name: "",
    companyName: "",
    email: "",
    phoneNumber: "",
  });
  const [resetPasswordStage, setResetPasswordStage] = useState(1); // 1: email input, 2: OTP input, 3: password reset
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  // Set default values based on isAdmin
  useEffect(() => {
    if (isAdmin) {
      setProfileInfo({
        name: data.username || "",
        email: data.email || "",
        phoneNumber: data.phone || "",
      });
    } else {
      setProfileInfo({
        name: data.reseller_name || "",
        companyName: data.company_name || "",
        email: data.reseller_email || "",
        phoneNumber: data.phone_number || "",
      });
    }
  }, [isAdmin, data]);

  const handleSignOut = () => {
    let logoutUrl = "/";
    if (isAdmin) {
      logoutUrl = "/adminlogin"; // Redirect to admin login page
    } else {
      logoutUrl = "/userlogin"; // Redirect to user login page
    }
    window.location.href = logoutUrl;
  };

  const handleProfileModal = () => {
    setShowProfileModal(!showProfileModal);
  };

  const openUserRegistrationPopup = () => {
    setShowRegistrationModal(true); // Show the registration modal
  };

  const handleUpdateProfile = () => {
    const updateFunction = isAdmin
      ? update_admin_account_details
      : update_user_account_details;

    // Validate profileInfo fields
    const isValidProfile = Object.values(profileInfo).every(
      (value) => value !== undefined && value !== ""
    );

    if (isValidProfile) {
      updateFunction(profileInfo)
        .then((response) => {
          window.alert("Profile Updated:", response);
          setShowProfileModal(false);
        })
        .catch((error) => {
          window.alert("Error updating profile:", error);
        });
    } else {
      window.alert("Invalid profile data:", profileInfo);
    }
  };
  const handleResetPasswordModal = () => {
    setResetPasswordStage(1);
    setEmail(profileInfo.email); // Automatically populate with the user's email
    setOtp("");
    setNewPassword("");
    setConfirmPassword("");
    setOtpSent(false);
    setShowResetPasswordModal(!showResetPasswordModal);
  };

  const handleSendOtp = async () => {
    try {
      const response = await resellersendOTP(email);
      const responseData = await response.json();
      window.alert(responseData.message); // Display success message
      setOtpSent(true);
      setResetPasswordStage(2);
    } catch (error) {
      let errorMessage = "Unknown error occurred";
      if (error.response) {
        const errorData = await error.response.json();
        errorMessage = errorData.message || "Error sending OTP";
      } else {
        errorMessage = error.message || "Error sending OTP";
      }
      window.alert("Error sending OTP: " + errorMessage); // Display error message
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await resellerverifyOTP(email, otp);
      const responseData = await response.json();
      window.alert(responseData.message); // Display success message
      setResetPasswordStage(3);
    } catch (error) {
      let errorMessage = "Unknown error occurred";
      if (error.response) {
        const errorData = await error.response.json();
        errorMessage = errorData.message || "Error verifying OTP";
      } else {
        errorMessage = error.message || "Error verifying OTP";
      }
      window.alert("Error verifying OTP: " + errorMessage); // Display error message
    }
  };

  const handlePasswordReset = async () => {
    if (newPassword === confirmPassword) {
      try {
        const response = await resellerupdatepassword(
          email,
          newPassword,
          confirmPassword
        );
        const responseData = await response.json();
        window.alert(responseData.message + ".\nYou have to log in again."); // Display success message
        setShowResetPasswordModal(false);
        window.location.href = "/userlogin";
      } catch (error) {
        let errorMessage = "Unknown error occurred";
        if (error.response) {
          const errorData = await error.response.json();
          errorMessage = errorData.message || "Error resetting password";
        } else {
          errorMessage = error.message || "Error resetting password";
        }
        window.alert("Error resetting password: " + errorMessage); // Display error message
      }
    } else {
      window.alert("Passwords do not match!"); // Display mismatch error
    }
  };
  return (
    <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <UserRegistrationFormModal
        show={showRegistrationModal}
        onHide={() => setShowRegistrationModal(false)}
      />
      <div className="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center">
        <a className="navbar-brand brand-logo" href="">
          <img src="assets/images/logo.svg" alt="logo" />
        </a>
        <a className="navbar-brand brand-logo-mini" href="">
          <img src="assets/images/logo-mini.svg" alt="logo" />
        </a>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-center">
        <ul className="navbar-nav">
          <li className="nav-item font-weight-semibold d-none d-lg-block">
            {data.reseller_logo && (
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <img
                    src={data.reseller_logo}
                    alt="Reseller Logo"
                    style={{
                      height: "60px",
                      width: "100px",
                      marginRight: "10px",
                    }}
                  />
                </li>
              </ul>
            )}
          </li>
        </ul>

        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown d-none d-xl-inline-block user-dropdown">
            <a
              className="nav-link dropdown-toggle"
              id="UserDropdown"
              href="#"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                className="img-xs rounded-circle"
                src="assets/images/faces/face8.jpg"
                alt="Profile image"
              />
              {data.username && <span className="ml-2">{data.username}</span>}
              {data.reseller_name && (
                <span className="ml-2">{data.reseller_name}</span>
              )}
            </a>
            <div
              className="dropdown-menu dropdown-menu-right navbar-dropdown"
              aria-labelledby="UserDropdown"
            >
              <div className="dropdown-header text-center">
                <img
                  className="img-md rounded-circle"
                  src="assets/images/faces/face8.jpg"
                  alt="Profile image"
                />
                {data.username && (
                  <p className="mb-1 mt-3 font-weight-semibold">
                    {data.username}
                  </p>
                )}
                {data.email && (
                  <p className="font-weight-light text-muted mb-0">
                    {data.email}
                  </p>
                )}
                {data.reseller_name && (
                  <p className="mb-1 mt-3 font-weight-semibold">
                    {data.reseller_name}
                  </p>
                )}
                {data.reseller_email && (
                  <p className="font-weight-light text-muted mb-0">
                    {data.reseller_email}
                  </p>
                )}
              </div>
              <a
                className="dropdown-item"
                onClick={handleProfileModal}
                style={{ cursor: "pointer" }}
              >
                My Profile
                <i className="dropdown-item-icon ti-dashboard"></i>
              </a>
              {!isAdmin &&
                data.kyc_status != null &&
                data.kyc_status != "Pending" && (
                  <a
                    className="dropdown-item"
                    onClick={openUserRegistrationPopup}
                    style={{ cursor: "pointer" }}
                  >
                    Registration Details
                    <i className="dropdown-item-icon ti-dashboard"></i>
                  </a>
                )}
              {!isAdmin && (
                <a
                  className="dropdown-item"
                  onClick={handleResetPasswordModal}
                  style={{ cursor: "pointer" }}
                >
                  Reset Password
                  <i className="dropdown-item-icon ti-dashboard"></i>
                </a>
              )}
              <a
                className="dropdown-item"
                onClick={handleSignOut}
                style={{ cursor: "pointer" }}
              >
                Sign Out<i className="dropdown-item-icon ti-power-off"></i>
              </a>
            </div>
          </li>
        </ul>
      </div>

      <Modal show={showProfileModal} onHide={handleProfileModal}>
        <Modal.Header>
          <Modal.Title>Edit Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            {isAdmin ? (
              <>
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={profileInfo.name}
                    onChange={(e) =>
                      setProfileInfo({ ...profileInfo, name: e.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    value={profileInfo.email}
                    onChange={(e) =>
                      setProfileInfo({
                        ...profileInfo,
                        email: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="form-group">
                  <label>Phone Number</label>
                  <input
                    type="tel"
                    className="form-control"
                    value={profileInfo.phoneNumber}
                    onChange={(e) =>
                      setProfileInfo({
                        ...profileInfo,
                        phoneNumber: e.target.value,
                      })
                    }
                  />
                </div>
              </>
            ) : (
              <>
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={profileInfo.name}
                    onChange={(e) =>
                      setProfileInfo({ ...profileInfo, name: e.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <label>Company Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={profileInfo.companyName}
                    onChange={(e) =>
                      setProfileInfo({
                        ...profileInfo,
                        companyName: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    value={profileInfo.email}
                    onChange={(e) =>
                      setProfileInfo({
                        ...profileInfo,
                        email: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="form-group">
                  <label>Phone Number</label>
                  <input
                    type="tel"
                    className="form-control"
                    value={profileInfo.phoneNumber}
                    onChange={(e) =>
                      setProfileInfo({
                        ...profileInfo,
                        phoneNumber: e.target.value,
                      })
                    }
                  />
                </div>
              </>
            )}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleProfileModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateProfile}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showResetPasswordModal} onHide={handleResetPasswordModal}>
        <Modal.Header>
          <Modal.Title>
            {resetPasswordStage === 1
              ? "Reset Password"
              : resetPasswordStage === 2
              ? "Verify OTP"
              : "Set New Password"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {resetPasswordStage === 1 && (
            <div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled
                />
              </div>
              <Button variant="primary" onClick={handleSendOtp}>
                Send OTP
              </Button>
            </div>
          )}
          {resetPasswordStage === 2 && otpSent && (
            <div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={resetPasswordStage > 1} // Disable if OTP sent
                />
                <br></br>
                <br></br>
                <label>Enter OTP</label>
                <input
                  type="text"
                  className="form-control"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
              </div>
              <Button variant="primary" onClick={handleVerifyOtp}>
                Verify OTP
              </Button>
              <Button variant="link" onClick={handleSendOtp}>
                Resend OTP
              </Button>
            </div>
          )}
          {resetPasswordStage === 3 && (
            <div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={resetPasswordStage > 1} // Disable if OTP sent
                />
                <br></br>
                <br></br>
                <label>New Password</label>
                <input
                  type="password"
                  className="form-control"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Confirm Password</label>
                <input
                  type="password"
                  className="form-control"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
              <Button variant="primary" onClick={handlePasswordReset}>
                Reset Password
              </Button>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleResetPasswordModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </nav>
  );
};

export default Navbar;
