import React, { Fragment, useState, useEffect } from "react";
import {
  getRegisteredList,
  updateUserStatus,
  addResellerDiscount,
  getResellerDiscounts,
  create_reseller_account,
  importResellerData,
  addResellerCommision,
  getResellerCommisions,
  addResellerVoucher,
  getResellerVouchers,
  getAccountMgrLists,
  assignAccountMgr,
  getResellerAccountDetails,
  getTeamLists,
  assignTeam,
  getResellerTeamDetails,
} from "../../utils/api";
import { Modal, Button, Dropdown, Form } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";

import { FaSortUp, FaSortDown, FaSort, FaFilter } from "react-icons/fa";
import { CiImport, CiExport } from "react-icons/ci";
import { Collapse, Checkbox } from "antd"; // using antd for UI, can also be custom
import { reseller_details_modules } from "./reportModulesjson";
import { exportReports } from "../../utils/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { subYears } from "date-fns";
const { Panel } = Collapse;

const RegisteredUsers = () => {
  const [resellerList, setResellerList] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [salesList, setSalesList] = useState([]);
  const [presalesList, setPresalesList] = useState([]);
  const [supportList, setSupportList] = useState([]);
  const [deploymentList, setDeploymentList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [initialResellerList, setInitialResellerList] = useState([]);
  const [initialTeamList, setInitialTeamList] = useState([]);
  const [selectedReseller, setSelectedReseller] = useState(null);
  const [showAddDiscountModal, setShowAddDiscountModal] = useState(false);
  const [discountValue, setDiscountValue] = useState("");
  const [discountHistory, setDiscountHistory] = useState([]);
  const [accountManagerList, setAccountManagerList] = useState([]);
  const [selectedAccountManager, setSelectedAccountManager] = useState(""); // Add this line
  const [showAssignAccountManagerModal, setShowAssignAccountManagerModal] =
    useState(false);

  const [showAddCommisionModal, setShowAddCommisionModal] = useState(false);
  const [commisionValue, setCommisionValue] = useState("");
  const [commisionHistory, setCommisionHistory] = useState([]);

  const [showCreateResellerModal, setShowCreateResellerModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [showAddVoucherModal, setShowAddVoucherModal] = useState(false);
  const [showManageResellerModal, setShowManageResellerModal] = useState(false);
  const [voucherType, setVoucherType] = useState(""); // Default to 'amount'
  const [voucherValue, setVoucherValue] = useState("");
  const [voucherHistory, setVoucherHistory] = useState([]);
  const [newReseller, setNewReseller] = useState({
    name: "",
    companyName: "",
    phoneNumber: "",
    email: "",
    resellerType: "", // Default value
  });
  const [formErrors, setFormErrors] = useState({
    phoneNumber: "",
    email: "",
  });

  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  const [statusFilter, setStatusFilter] = useState(""); // For filtering by status
  const [showStatusFilterOptions, setShowStatusFilterOptions] = useState(false); // Toggle for status filter dropdown
  const admindetailsmodules = localStorage.getItem("admindetails");
  const role = JSON.parse(admindetailsmodules).role;
  const admin_email = JSON.parse(admindetailsmodules).email;
  const admin_name = JSON.parse(admindetailsmodules).username;

  const [loading, setLoading] = useState(false);

  const [resellerID, setResellerID] = useState("");
  const [resellerName, setResellerName] = useState("");
  const [resellerEmail, setResellerEmail] = useState("");
  const [resellerCompanyName, setResellerCompanyName] = useState("");
  const [resellerAccountStatus, setResellerAccountStatus] = useState("");
  const [resellerRegisteredDate, setResellerRegisteredDate] = useState("");
  const [resellerPhoneNumber, setResellerPhoneNumber] = useState("");
  const [resellerType, setResellerType] = useState("");
  const [resellerRejectionReason, setResellerRejectionReason] = useState("");
  const [showResellerDetailsViewModal, setShowResellerDetailsViewModal] =
    useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedSubOption, setSelectedSubOption] = useState(null);
  const [selectedMonthYear, setSelectedMonthYear] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [currentAccountManager, setCurrentAccountManager] = useState("");
  const [currentAccountManagerRegion, setCurrentAccountManagerRegion] =
    useState("");
  const [selectedSalesPerson, setSelectedSalesPerson] = useState(""); // Add this line
  const [selectedDeploymentPerson, setSelectedDeploymentPerson] = useState(""); // Add this line
  const [selectedPresalesPerson, setSelectedPresalesPerson] = useState(""); // Add this line
  const [selectedSupportPerson, setSelectedSupportPerson] = useState(""); // Add this line
  const [salesPerson, setSalesPerson] = useState(""); // Add this line
  const [deploymentPerson, setDeploymentPerson] = useState(""); // Add this line
  const [presalesPerson, setPresalesPerson] = useState(""); // Add this line
  const [supportPerson, setSupportPerson] = useState(""); // Add this line

  useEffect(() => {
    fetchResellerList();
    fetchTeamList();
  }, []);

  const fetchResellerList = async () => {
    try {
      setLoading(true);
      const response = await getRegisteredList(admin_email);
      setResellerList(response || []);
      setInitialResellerList(response || []);
    } catch (error) {
      console.error("Error fetching user list:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchTeamList = async () => {
    try {
      setLoading(true);
      const response = await getTeamLists();
      setTeamList(response || []);
      setSalesList(response.Sales || []);
      setPresalesList(response.PreSales || []);
      setDeploymentList(response.Deployment || []);
      setSupportList(response.Support || []);
      setInitialTeamList(response || []);
    } catch (error) {
      console.error("Error fetching user list:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUserStatusUpdate = async (userId, newStatus, reason = null) => {
    try {
      setLoading(true);
      await updateUserStatus(userId, newStatus, reason);
      fetchResellerList();
    } catch (error) {
      console.error("Error updating user status:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchInputChange = (value) => {
    setSearchInput(value.toLowerCase());
    const filteredList = initialResellerList.filter((user) => {
      if (user) {
        return (
          (user.company_name &&
            user.company_name.toLowerCase().includes(value)) ||
          (user.reseller_name &&
            user.reseller_name.toLowerCase().includes(value)) ||
          (user.reseller_email &&
            user.reseller_email.toLowerCase().includes(value))
        );
      }
      return false;
    });
    setResellerList(filteredList);
  };

  const handleReset = () => {
    setSearchInput("");
    setResellerList(initialResellerList);
  };
  const handleSort = (column) => {
    let sortedList = [...resellerList];
    if (sortColumn === column) {
      sortedList.reverse(); // Toggle the sorting order
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      sortedList.sort((a, b) => {
        if (a[column] < b[column]) return -1;
        if (a[column] > b[column]) return 1;
        return 0;
      });
      setSortColumn(column);
      setSortOrder("asc");
    }
    setResellerList(sortedList);
  };
  // Handle the filter by status
  const handleStatusFilter = (status) => {
    setStatusFilter(status);
    const filteredList = initialResellerList.filter(
      (user) => user.isactive === status
    );
    setResellerList(filteredList);
    setShowStatusFilterOptions(false); // Hide dropdown after selection
  };

  const handleCreateReseller = () => {
    setShowCreateResellerModal(true);
  };

  const thStyles = {
    position: "sticky",
    top: 0,
    backgroundColor: "#f1f1f1",
    zIndex: 100,
  };
  const validateForm = () => {
    const errors = { phoneNumber: "", email: "" };

    // Phone Number Validation (simple pattern)
    if (!/^\d{10}$/.test(newReseller.phoneNumber)) {
      errors.phoneNumber = "Phone number must be 10 digits long.";
    }

    // Email Validation
    if (!/\S+@\S+\.\S+/.test(newReseller.email)) {
      errors.email = "Please enter a valid email address.";
    }

    setFormErrors(errors);
    return !errors.phoneNumber && !errors.email;
  };

  const handleSaveReseller = async () => {
    if (validateForm()) {
      try {
        setLoading(true);
        const response = await create_reseller_account(
          newReseller.email,
          newReseller.companyName,
          newReseller.phoneNumber,
          newReseller.name,
          newReseller.resellerType
        );
        if (response.status === 200) {
          const responsedata = await response.json();
          window.alert(responsedata.message);
          fetchResellerList(); // Refresh the reseller list after creation
          setShowCreateResellerModal(false); // Close the modal
          resetCreateResellerForm(); // Reset the form
        } else if (response.status === 409) {
          const responsedata = await response.json();
          window.alert(responsedata.message);
        } else {
          console.error("Failed to create reseller:", response.statusText);
        }
      } catch (error) {
        console.error("Error creating reseller:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const renderActionButtons = (user) => {
    if (user.isactive === "PENDING") {
      return (
        <Fragment>
          <button
            className="btn btn-success mr-2"
            onClick={() => handleUserStatusUpdate(user.reseller_id, "ACCEPT")}
          >
            Accept
          </button>
          <button
            className="btn btn-danger"
            onClick={() => {
              const reason = prompt("Please provide a reason for rejection:");
              if (reason !== null) {
                handleUserStatusUpdate(user.reseller_id, "REJECT", reason);
              }
            }}
          >
            Reject
          </button>
        </Fragment>
      );
    } else if (user.isactive === "ACCEPT") {
      return (
        <button
          className="btn btn-danger"
          onClick={() => {
            const reason = prompt("Please provide a reason for rejection:");
            if (reason !== null) {
              handleUserStatusUpdate(user.reseller_id, "REJECT", reason);
            }
          }}
        >
          Reject
        </button>
      );
    } else if (user.isactive === "REJECT") {
      return (
        <button
          className="btn btn-success"
          onClick={() => handleUserStatusUpdate(user.reseller_id, "ACCEPT")}
        >
          Accept
        </button>
      );
    }
    return null;
  };
  const getStatusColor = (status) => {
    switch (status) {
      case "PENDING":
        return "orange";
      case "ACCEPT":
        return "green";
      case "REJECT":
        return "red";
      default:
        return "black";
    }
  };
  const getStatusText = (status) => {
    switch (status) {
      case "ACCEPT":
        return "Active";
      case "REJECT":
        return "Inactive";
      case "PENDING":
        return "Pending";
      default:
        return "Unknown";
    }
  };

  const handleAddVoucher = async () => {
    try {
      setLoading(true);
      if (!selectedReseller || !voucherValue || !voucherType) return;

      await addResellerVoucher(
        selectedReseller.reseller_email,
        voucherValue,
        voucherType
      );
      setVoucherValue("");
      window.alert(
        "Voucher Assign Successfully for:",
        selectedReseller.reseller_id
      );
      setVoucherValue("");
      setVoucherType("");
      fetchResellerDiscountHistory(selectedReseller.reseller_email);
      setShowAddVoucherModal(false);
    } catch (error) {
      window.alert("Error adding voucher:", error);
    } finally {
      setLoading(false);
    }
  };
  // Handle selection of account manager
  const handleAccountManagerSelect = (e) => {
    const selectedId = e.target.value;
    const manager = accountManagerList.find(
      (manager) => manager.admin_id === selectedId
    );
    setSelectedAccountManager(manager);
  };
  const handleSalesPersonSelect = (e) => {
    const selectedId = e.target.value;
    const salesperson = salesList.find(
      (salesperson) => salesperson.admin_id === selectedId
    );
    setSelectedSalesPerson(salesperson);
  };
  const handlePreSalesPersonSelect = (e) => {
    const selectedId = e.target.value;
    const presalesperson = presalesList.find(
      (presalesperson) => presalesperson.admin_id === selectedId
    );
    setSelectedPresalesPerson(presalesperson);
  };
  const handleSupportPersonSelect = (e) => {
    const selectedId = e.target.value;
    const supportperson = supportList.find(
      (supportperson) => supportperson.admin_id === selectedId
    );
    setSelectedSupportPerson(supportperson);
  };
  const handleDeploymentPersonSelect = (e) => {
    const selectedId = e.target.value;
    const deploymentperson = deploymentList.find(
      (deploymentperson) => deploymentperson.admin_id === selectedId
    );
    setSelectedDeploymentPerson(deploymentperson);
  };
  const handleAssignAccMgr = async () => {
    try {
      setLoading(true);
      if (!selectedAccountManager) {
        window.alert("Please select the Account Manager");
        return;
      }

      await assignAccountMgr(
        selectedAccountManager.admin_id,
        selectedReseller.reseller_id
      );
      setSelectedAccountManager("");
      window.alert("Account Manager Assigned Successfully");
      setShowAssignAccountManagerModal(false);
      fetchResellerList();
    } catch (error) {
      window.alert("Error for assiging the account manager:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleAssignTeam = async () => {
    try {
      setLoading(true);
      const teamData = {
        reseller: selectedReseller,
        sales: selectedSalesPerson,
        presales: selectedPresalesPerson,
        support: selectedSupportPerson,
        deployment: selectedDeploymentPerson,
        name: admin_name,
        email: admin_email,
      };
      // if (!selectedAccountManager) {
      //   window.alert("Please select the Account Manager");
      //   return;
      // }

      await assignTeam(teamData);
      setSelectedDeploymentPerson("");
      setSelectedPresalesPerson("");
      setSelectedSalesPerson("");
      setSelectedSupportPerson("");
      window.alert("Team Assigned Successfully");
      setShowManageResellerModal(false);
      fetchResellerList();
    } catch (error) {
      window.alert("Error for assiging the teams:", error);
    } finally {
      setLoading(false);
    }
  };
  const renderActionOptions = (user) => {
    const handleAddDiscountClick = () => {
      setSelectedReseller(user); // Set the selected reseller
      setShowAddDiscountModal(true); // Open the modal
      fetchResellerDiscountHistory(user.reseller_id); // Fetch discount history for the selected reseller
    };
    const handleAddCommisionClick = () => {
      setSelectedReseller(user); // Set the selected reseller
      setShowAddCommisionModal(true); // Open the modal
      fetchResellerCommisionHistory(user.reseller_id); // Fetch discount history for the selected reseller
    };
    const handleAddVoucherClick = () => {
      setSelectedReseller(user);
      setShowAddVoucherModal(true); // Open the modal
      fetchResellerVoucherHistory(user.reseller_email);
    };
    const handleManageResellerClick = async () => {
      setSelectedReseller(user); // Set the selected reseller
      try {
        setLoading(true);
        const response = await getResellerTeamDetails(user.reseller_id);

        // Check if response.data is an empty object
        if (response.data && Object.keys(response.data).length > 0) {
          setSalesPerson(response.data.Sales.tag_person_name || "");
          setPresalesPerson(response.data.PreSales.tag_person_name || "");
          setDeploymentPerson(response.data.Deployment.tag_person_name || "");
          setSupportPerson(response.data.Support.tag_person_name || "");
        } else {
          // Handle the case for empty object or null
          setSalesPerson("");
          setPresalesPerson("");
          setDeploymentPerson("");
          setSupportPerson("");
        }

        setShowManageResellerModal(true); // Open the modal
        fetchTeamList();
      } catch (error) {
        window.alert("Error for fetching the account team details:", error);
      } finally {
        setLoading(false);
      }
    };

    const handleAssignAccountMgrClick = async () => {
      setSelectedReseller(user); // Set the selected reseller
      try {
        setLoading(true);
        const response = await getResellerAccountDetails(user.reseller_id);
        if (response.data !== null) {
          setCurrentAccountManager(response.data.tag_person_name);
          setCurrentAccountManagerRegion(response.data.region);

          setShowAssignAccountManagerModal(true);
        } else {
          setCurrentAccountManager("None");
          setCurrentAccountManagerRegion("None");
          setShowAssignAccountManagerModal(true);
        }
      } catch (error) {
        window.alert("Error for fetching the account manager details:", error);
      } finally {
        setLoading(false);
      }

      setShowAssignAccountManagerModal(true); // Open the modal
      fetchAccountManagerList(); // Fetch discount history for the selected reseller
    };

    return (
      <div className="d-flex justify-content-center">
        <Dropdown>
          <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
            <BsThreeDotsVertical />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {/* Conditionally render based on reseller type */}
            {user.reseller_type === "Indirect" &&
              (role === "Admin" || role === "SuperAdmin") && (
                <>
                  <Dropdown.Item onClick={handleAddCommisionClick}>
                    Add Commission Percentage
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleAddVoucherClick}>
                    Add Voucher
                  </Dropdown.Item>
                </>
              )}
            {user.reseller_type === "Direct" &&
              user.isactive === "ACCEPT" &&
              (role === "Admin" || role === "SuperAdmin") && (
                <Dropdown.Item onClick={handleAddDiscountClick}>
                  Add Partner Discount
                </Dropdown.Item>
              )}
            {(role === "Admin" || role === "SuperAdmin") &&
              user.isactive === "ACCEPT" && (
                <Dropdown.Item onClick={handleAssignAccountMgrClick}>
                  Assign Account Manager
                </Dropdown.Item>
              )}
            {(role === "Account Manager" ||
              role === "Assistant Admin" ||
              role === "Admin" ||
              role === "SuperAdmin") &&
              user.isactive === "ACCEPT" && (
                <Dropdown.Item onClick={handleManageResellerClick}>
                  Manage Reseller
                </Dropdown.Item>
              )}
            <Dropdown.Item onClick={() => handleView(user.reseller_id)}>
              View Partner Details
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };
  const handleView = (resellerID) => {
    const selected = resellerList.find(
      (reseller) => reseller.reseller_id === resellerID
    );
    setResellerID(selected.reseller_id);
    setSelectedReseller(selected);
    setResellerName(selected.reseller_name);
    setResellerCompanyName(selected.company_name);
    setResellerEmail(selected.reseller_email);
    setResellerPhoneNumber(selected.phone_number);
    setResellerRegisteredDate(selected.created_on);
    setResellerRejectionReason(selected.reason);
    setResellerType(selected.reseller_type);
    setResellerAccountStatus(selected.isactive);
    setShowResellerDetailsViewModal(true);
  };
  const handleOpenImportModal = () => {
    setShowImportModal(true);
  };

  const handleCloseImportModal = () => {
    setShowImportModal(false);
  };
  const handleFileDownload = () => {
    // Create a temporary anchor element
    const downloadLink = document.createElement("a");
    // Set the href attribute to the URL of the file to be downloaded
    downloadLink.href = "/resellerbulkrecord.xlsx"; // Replace with the actual file path
    // Set the download attribute to specify the file name when downloaded
    downloadLink.download = "resellerbulkrecord.xlsx"; // Replace with the desired file name

    // Append the anchor element to the body
    document.body.appendChild(downloadLink);

    // Trigger a click event on the anchor element to initiate the download
    downloadLink.click();

    // Remove the anchor element from the body after the download
    document.body.removeChild(downloadLink);
  };
  const handleFileUpload = async () => {
    const fileInput = document.getElementById("fileInput"); // Get the file input element
    if (!fileInput || !fileInput.files || fileInput.files.length === 0) {
      window.alert("Please upload the file first.");
      return; // Exit function if no file is selected
    }
    setUploading(true); // Show loader when upload starts
    const file = fileInput.files[0]; // Get the uploaded file

    try {
      setLoading(true);
      const response = await importResellerData(file);
      if (response.status === 200) {
        const data = await response.json();
        window.alert(data.message);
        setUploading(false);
        setShowImportModal(false);
        fetchResellerList();
      } else {
        const data = await response.json();
        window.alert(data.message);
        setUploading(false);
      }
    } catch (error) {
      console.error("Error uploading file:", error.message);
      window.alert("An error occurred while uploading the file.");
    } finally {
      setUploading(false); // Hide loader after upload completes (whether success or failure)
      setLoading(false);
    }
  };
  const handleAddDiscount = async () => {
    try {
      setLoading(true);
      if (!selectedReseller || !discountValue) return;

      await addResellerDiscount(selectedReseller.reseller_id, discountValue);
      fetchResellerDiscountHistory(selectedReseller.reseller_id);
      setDiscountValue("");
      window.alert(
        "Discount Added Successfully for:",
        selectedReseller.reseller_id
      );
    } catch (error) {
      window.alert("Error adding discount:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchResellerDiscountHistory = async (resellerId) => {
    try {
      setLoading(true);
      const history = await getResellerDiscounts(resellerId);
      setDiscountHistory(history);
    } catch (error) {
      window.alert("Error fetching discount history:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchResellerVoucherHistory = async (reselleremail) => {
    try {
      setLoading(true);
      const history = await getResellerVouchers(reselleremail);
      setVoucherHistory(history);
    } catch (error) {
      window.alert("Error fetching vouchers history:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchAccountManagerList = async () => {
    try {
      setLoading(true);
      const history = await getAccountMgrLists();
      setAccountManagerList(history);
    } catch (error) {
      window.alert("Error fetching account manager list:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddCommision = async () => {
    try {
      setLoading(true);
      if (!selectedReseller || !commisionValue) return;

      await addResellerCommision(selectedReseller.reseller_id, commisionValue);
      await fetchResellerCommisionHistory(selectedReseller.reseller_id);
      setCommisionValue("");
      window.alert(
        "Commision Added Successfully for:",
        selectedReseller.reseller_id
      );
      // setShowAddCommisionModal(false);
    } catch (error) {
      console.error("Error adding commission:", error); // Log the error for debugging
      window.alert(
        `Error adding commission: ${error.message || error.toString()}`
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchResellerCommisionHistory = async (resellerId) => {
    try {
      setLoading(true);
      const history = await getResellerCommisions(resellerId);
      setCommisionHistory(history);
    } catch (error) {
      console.error("Error fetching commission history:", error); // Log the error for debugging
      window.alert(
        `Error fetching commission history: ${
          error.message || error.toString()
        }`
      );
    } finally {
      setLoading(false);
    }
  };

  const handleCloseCreateResellerModal = () => {
    setShowCreateResellerModal(false);
    resetCreateResellerForm();
  };

  const resetCreateResellerForm = () => {
    setNewReseller({
      name: "",
      companyName: "",
      phoneNumber: "",
      email: "",
      resellerType: "",
    });
    setFormErrors({
      phoneNumber: "",
      email: "",
    });
  };

  const handleCloseViewModal = () => {
    setShowResellerDetailsViewModal(false);
    // Reset all fields to their initial state
    setResellerAccountStatus("");
    setResellerCompanyName("");
    setResellerEmail("");
    setResellerID("");
    setResellerName("");
    setResellerPhoneNumber("");
    setResellerRegisteredDate("");
    setResellerRejectionReason("");
    setResellerType("");
  };
  const handleCloseExportModal = () => {
    setIsModalVisible(false);
    // Reset all fields to their initial state
    setSelectedModule("");
    setSelectedOption("");
    setSelectedSubOption("");
    setSelectedMonthYear("");
    setSelectedYear("");
  };

  const toggleModal = () => {
    setIsModalVisible(true);
  };

  const handleModuleChange = (moduleName) => {
    setSelectedModule(moduleName);
    setSelectedOption(null); // Reset selected option when module changes
    setSelectedSubOption(null); // Reset selected sub-option
  };

  const handleOptionChange = (optionName) => {
    setSelectedOption(optionName);
    setSelectedSubOption(null); // Reset selected sub-option when option changes
  };

  const handleSubOptionChange = (subOptionName) => {
    setSelectedSubOption((prev) =>
      prev === subOptionName ? null : subOptionName
    );
  };
  const handleSubmit = async () => {
    if (!selectedOption) {
      alert("Please select at least one report type.");
      return;
    }
    try {
      setLoading(true);
      const response = await exportReports(
        selectedModule,
        selectedOption ? selectedOption : null,
        selectedSubOption ? selectedSubOption : null,
        selectedMonthYear ? selectedMonthYear : null,
        selectedYear ? selectedYear : null,
        admin_email
      );
      if (response.status === 200) {
        const responsedata = await response.json();
        if (responsedata.flag_value === 1) {
          window.alert(responsedata.message);
          setSelectedModule(null);
          setSelectedOption(null);
          setSelectedSubOption(null);
          setSelectedMonthYear(null);
          setSelectedYear(null);
          setIsModalVisible(false);
        } else if (responsedata.flag_value === 2) {
          window.alert(responsedata.message);
        }
      } else {
        console.error("Failed to generate report:", response.statusText);
      }
    } catch (error) {
      console.error("Error generate report", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <style>
        {`
          .sortable-header {
            cursor: pointer;
            position: relative;
          }

          .sort-icon {
            margin-left: 5px;
            color: #6c757d; /* Default color */
            transition: color 0.2s ease;
          }

          .sortable-header:hover .sort-icon {
            color: #007bff; /* Change this to your preferred hover color */
          }

          .table-responsive {
            max-height: 500px;
            overflow-y: auto;
          }
             .status-filter-dropdown {
            position: absolute;
            background-color: white;
            border: 1px solid #ccc;
            padding: 10px;
            z-index: 150;
          }

          .filter-icon {
            cursor: pointer;
          }
        `}
      </style>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="mb-1">Registered Resellers</h4>
            <br></br>
            <div className="search-container mb-3">
              <input
                type="text"
                placeholder="Search"
                value={searchInput}
                onChange={(e) => handleSearchInputChange(e.target.value)}
                style={{ float: "left" }}
              />
              <button
                className="btn btn-primary ml-2"
                onClick={handleReset}
                style={{ float: "left" }}
              >
                Reset
              </button>
              <button
                className="btn btn-primary ml-2"
                style={{ float: "right" }}
                onClick={handleOpenImportModal}
                title="Import Reseller Data"
              >
                <CiImport size={15} />
              </button>
              <button
                className="btn btn-primary ml-2"
                style={{ float: "right" }}
                onClick={toggleModal}
                title="Export Reseller Data"
              >
                <CiExport size={15} />
              </button>

              <button
                className="btn btn-primary ml-2"
                style={{ float: "right" }}
                onClick={handleCreateReseller}
                title="Create New Reseller"
              >
                Create New Reseller
              </button>
            </div>
            <br></br>
            <div
              className="table-responsive"
              style={{ maxHeight: "500px", overflowY: "auto" }}
            >
              <table className="table table-hover sticky-header">
                <thead>
                  <tr>
                    {/* <th style={thStyles}>User ID</th> */}
                    <th
                      style={thStyles}
                      className="sortable-header"
                      onClick={() => handleSort("reseller_name")}
                    >
                      Name
                      {sortColumn === "reseller_name" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" /> // Default sort icon
                      )}
                    </th>
                    <th style={thStyles}>Email</th>
                    <th
                      style={thStyles}
                      className="sortable-header"
                      onClick={() => handleSort("company_name")}
                    >
                      Company Name
                      {sortColumn === "company_name" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" /> // Default sort icon
                      )}
                    </th>
                    <th style={thStyles} className="sortable-header">
                      Status
                      <FaFilter
                        className="filter-icon"
                        onClick={() =>
                          setShowStatusFilterOptions(!showStatusFilterOptions)
                        }
                      />
                      {/* Status Filter Dropdown */}
                      {showStatusFilterOptions && (
                        <div className="status-filter-dropdown">
                          <p onClick={() => handleStatusFilter("ACCEPT")}>
                            Accepted
                          </p>
                          <p onClick={() => handleStatusFilter("REJECT")}>
                            Rejected
                          </p>
                          <p onClick={() => handleStatusFilter("PENDING")}>
                            Pending
                          </p>
                        </div>
                      )}
                    </th>
                    {(role === "Admin" || role === "SuperAdmin") && (
                      <th style={thStyles}>Account Action</th>
                    )}
                    <th style={thStyles}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {resellerList.map((user) => (
                    <tr key={user.reseller_id}>
                      {/* <td>{user.reseller_id}</td> */}
                      <td>{user.reseller_name}</td>
                      <td>{user.reseller_email}</td>
                      <td>{user.company_name}</td>
                      <td style={{ color: getStatusColor(user.isactive) }}>
                        {user.isactive}
                      </td>
                      {(role === "Admin" || role === "SuperAdmin") && (
                        <td>{renderActionButtons(user)}</td>
                      )}
                      <td>{renderActionOptions(user)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showAddDiscountModal}
        onHide={() => {
          setShowAddDiscountModal(false);
          setDiscountValue(""); // Clear discount value
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Discount</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="discountValue">
              <Form.Label>Discount Value</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter discount value"
                value={discountValue}
                onChange={(e) => setDiscountValue(e.target.value)}
              />
            </Form.Group>
            <br></br>
            <Button variant="primary" onClick={handleAddDiscount}>
              Add Discount
            </Button>
          </Form>
          <hr />
          <h5>Discount History</h5>
          <div style={{ maxHeight: "150px", overflowY: "auto" }}>
            <table className="table">
              <thead>
                <tr>
                  <th>Discount</th>
                  <th>Updated On</th>
                </tr>
              </thead>
              <tbody>
                {discountHistory.map((discount, index) => (
                  <tr key={index}>
                    <td>{discount.discount_in}%</td>
                    <td>{discount.updated_on}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showAssignAccountManagerModal}
        onHide={() => {
          setShowAssignAccountManagerModal(false);
          setSelectedAccountManager(""); // Clear Account Manager value
          setCurrentAccountManager("");
          setSelectedReseller("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Assign Account Manager</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="accountmanager">
              <Form.Label>Current Account Manager</Form.Label>
              <Form.Control
                type="text"
                // placeholder="Enter discount value"
                value={currentAccountManager}
                // onChange={(e) => setDiscountValue(e.target.value)}
                readOnly
              />
            </Form.Group>
            <br></br>
            <Form.Group controlId="region">
              <Form.Label>Region</Form.Label>
              <Form.Control
                type="text"
                // placeholder="Enter discount value"
                value={currentAccountManagerRegion}
                // onChange={(e) => setDiscountValue(e.target.value)}
                readOnly
              />
            </Form.Group>
            <br></br>
            {/* Account Manager Dropdown */}
            <Form.Group controlId="accountManagerSelect">
              <Form.Label>Select Account Manager</Form.Label>
              <Form.Control
                as="select"
                onChange={handleAccountManagerSelect}
                defaultValue=""
              >
                <option value="">Select an Account Manager</option>
                {accountManagerList.map((manager) => (
                  <option key={manager.admin_id} value={manager.admin_id}>
                    {manager.admin_name} ({manager.region})
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <br />
            <Button variant="primary" onClick={handleAssignAccMgr}>
              Assign Manager
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showAddCommisionModal}
        onHide={() => {
          setShowAddCommisionModal(false);
          setCommisionValue(""); // Clear discount value
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Commision</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="commisionValue">
              <Form.Label>Commision Value</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter commision value"
                value={commisionValue}
                onChange={(e) => setCommisionValue(e.target.value)}
              />
            </Form.Group>
            <br></br>
            <Button variant="primary" onClick={handleAddCommision}>
              Add Commision
            </Button>
          </Form>
          <hr />
          <h5>Commision History</h5>
          <div style={{ maxHeight: "150px", overflowY: "auto" }}>
            <table className="table">
              <thead>
                <tr>
                  <th>Commision</th>
                  <th>Updated On</th>
                </tr>
              </thead>
              <tbody>
                {commisionHistory.map((commision, index) => (
                  <tr key={index}>
                    <td>{commision.commision_percentage}%</td>
                    <td>{commision.timestamp}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showCreateResellerModal}
        onHide={handleCloseCreateResellerModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>New Reseller Registration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="resellerName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                value={newReseller.name}
                onChange={(e) =>
                  setNewReseller({ ...newReseller, name: e.target.value })
                }
                required
              />
            </Form.Group>
            <Form.Group controlId="resellerType">
              <Form.Label>Reseller Type</Form.Label>
              <Form.Control
                as="select"
                value={newReseller.resellerType}
                onChange={(e) =>
                  setNewReseller({
                    ...newReseller,
                    resellerType: e.target.value,
                  })
                }
              >
                <option value="">Select Type</option>
                <option value="Direct">Direct</option>
                <option value="Indirect">Indirect</option>
              </Form.Control>
            </Form.Group>
            {newReseller.resellerType === "Direct" && (
              <Form.Group controlId="companyName">
                <Form.Label>Company Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter company name"
                  value={newReseller.companyName}
                  onChange={(e) =>
                    setNewReseller({
                      ...newReseller,
                      companyName: e.target.value,
                    })
                  }
                />
              </Form.Group>
            )}
            <Form.Group controlId="phoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter phone number"
                value={newReseller.phoneNumber}
                onChange={(e) =>
                  setNewReseller({
                    ...newReseller,
                    phoneNumber: e.target.value,
                  })
                }
                isInvalid={!!formErrors.phoneNumber}
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.phoneNumber}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={newReseller.email}
                onChange={(e) =>
                  setNewReseller({ ...newReseller, email: e.target.value })
                }
                isInvalid={!!formErrors.email}
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <br></br>
            <Button
              variant="primary"
              onClick={handleSaveReseller}
              style={{ float: "right" }}
            >
              Create Reseller
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {uploading && (
            <div className="loader-container">
              <div className="loader">
                <div className="tick"></div>
              </div>
            </div>
          )}
        </Modal.Footer>
      </Modal>
      <Modal show={showImportModal} onHide={handleCloseImportModal}>
        <Modal.Header closeButton>
          <Modal.Title>Import Reseller Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ color: "red" }}>
            Note:- If you want to upload bulk data then first you need to
            download the file template fill the data in that file and upload it.
          </p>
          <p>Download the file template:</p>
          <i
            class="fa fa-download"
            aria-hidden="true"
            style={{ fontSize: "24px" }}
            onClick={handleFileDownload}
            title="Download"
          ></i>{" "}
          <hr />
          <p>Upload the filled data file:</p>
          <input
            type="file"
            className="form-control"
            id="fileInput"
            // onChange={handleFileUpload}
            accept=".xlsx,.csv"
            required
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={handleCloseImportModal}
          >
            Close
          </button>
          <button className="btn btn-secondary" onClick={handleFileUpload}>
            Import
          </button>
          {uploading && (
            <div className="loader-container">
              <div className="loader">
                <div className="tick"></div>
              </div>
            </div>
          )}
          {/* Conditionally render the loader */}
        </Modal.Footer>
      </Modal>
      <Modal
        show={showAddVoucherModal}
        onHide={() => {
          setShowAddVoucherModal(false);
          setVoucherValue(""); // Clear voucher value
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Voucher</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="voucherType">
              <Form.Label>Voucher Type</Form.Label>
              <Form.Control
                as="select"
                value={voucherType}
                onChange={(e) => setVoucherType(e.target.value)}
              >
                <option value="">Select Voucher Type</option>
                <option value="Amount">Amount</option>
                <option value="Percent">Percent</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="voucherValue">
              <Form.Label>
                {voucherType === "Amount"
                  ? "Voucher Amount"
                  : "Voucher Percent"}
              </Form.Label>
              <Form.Control
                type="number"
                placeholder={`Enter ${
                  voucherType === "Amount" ? "Amount" : "percent"
                }`}
                value={voucherValue}
                onChange={(e) => {
                  const value = e.target.value;
                  if (voucherType === "Percent" && (value < 0 || value > 100)) {
                    // Prevent percent value from exceeding 100%
                    return;
                  }
                  setVoucherValue(value);
                }}
              />
            </Form.Group>
            <br />
            <Button variant="primary" onClick={handleAddVoucher}>
              Add Voucher
            </Button>
          </Form>
          <hr />
          <h5>Voucher History</h5>
          <div style={{ maxHeight: "150px", overflowY: "auto" }}>
            <table className="table">
              <thead>
                <tr>
                  <th>Voucher Type</th>
                  <th>Voucher Value</th>
                  <th>Expiry Date</th>
                  <th>Voucher Status</th>
                </tr>
              </thead>
              <tbody>
                {voucherHistory.map((voucher, index) => (
                  <tr key={index}>
                    <td>{voucher.voucher_type}</td>
                    <td>
                      {voucher.voucher_content}{" "}
                      {voucher.type === "percent" ? "%" : ""}
                    </td>
                    <td>
                      {new Date(voucher.expiry_date).toLocaleDateString()}
                    </td>
                    <td>{voucher.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showResellerDetailsViewModal}
        onHide={handleCloseViewModal}
        size="xl"
      >
        <Modal.Header>
          <Modal.Title>Reseller Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    Reseller Name
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      id="resellername"
                      value={resellerName}
                      name="resellername"
                      readOnly
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    Company Name
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      id="resellercompanyname"
                      value={resellerCompanyName}
                      name="resellercompanyname"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">Email ID</label>
                  <div className="col-sm-9">
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      value={resellerEmail}
                      name="email"
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    Phone Number
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      id="phone"
                      value={resellerPhoneNumber}
                      name="phone"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    Reseller Type
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      id="resellertype"
                      value={resellerType}
                      name="resellertype"
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    Reseller Account Status
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      id="reselleraccountstatus"
                      value={getStatusText(resellerAccountStatus)}
                      name="reselleraccountstatus"
                      disabled
                      style={{
                        color: getStatusColor(resellerAccountStatus),
                        fontWeight: "bold",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {resellerRejectionReason !== "null" && (
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Account Rejection Reason
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        type="text"
                        className="form-control"
                        id="reselleraccountreason"
                        value={resellerRejectionReason}
                        name="reselleraccountreason"
                        row="2"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    Reseller Registration Date
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      id="resellerregistrationdate"
                      value={new Date(
                        resellerRegisteredDate
                      ).toLocaleDateString()}
                      name="resellerregistrationdate"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary" onClick={handleCloseViewModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        title="Export Data"
        onHide={handleCloseExportModal}
        show={isModalVisible}
      >
        <Modal.Header closeButton>
          <Modal.Title>Export Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Collapse>
            {reseller_details_modules.map((module, moduleIndex) => (
              <Panel header={module.name} key={moduleIndex}>
                <Checkbox
                  checked={selectedModule === module.name}
                  onChange={() => handleModuleChange(module.name)}
                >
                  Select Report Type
                </Checkbox>
                {selectedModule === module.name && (
                  <div style={{ paddingLeft: "20px" }}>
                    {module.options.map((option, optionIndex) => (
                      <div key={optionIndex}>
                        <Checkbox
                          onChange={() => handleOptionChange(option.name)}
                          checked={selectedOption === option.name}
                        >
                          {option.name}
                        </Checkbox>
                        {selectedOption === option.name &&
                          option.subOptions && (
                            <div style={{ paddingLeft: "20px" }}>
                              {option.subOptions.map((subOption, subIndex) => (
                                <div key={subIndex}>
                                  <Checkbox
                                    onChange={() =>
                                      handleSubOptionChange(subOption)
                                    }
                                    checked={selectedSubOption === subOption}
                                  >
                                    {subOption}
                                  </Checkbox>
                                </div>
                              ))}
                            </div>
                          )}
                      </div>
                    ))}
                  </div>
                )}
                <br></br>
                {selectedOption === "Get Top 10 Resellers Sales by Month" && (
                  <>
                    <h4 className="card-title">Select Month and Year</h4>
                    <DatePicker
                      selected={selectedMonthYear}
                      onChange={(date) => setSelectedMonthYear(date)}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                      locale="en-GB"
                      maxDate={new Date()}
                      placeholderText="Select Month and Year"
                      className="form-control"
                      popperPlacement="bottom"
                      popperModifiers={[
                        {
                          name: "preventOverflow",
                          options: {
                            boundary: "viewport",
                          },
                        },
                        {
                          name: "offset",
                          options: {
                            offset: [0, 10],
                          },
                        },
                      ]}
                      withPortal // Forces the calendar to open in a portal
                    />
                  </>
                )}

                {selectedOption === "Get Top 10 Resellers Sales by Year" && (
                  <>
                    <h4 className="card-title">Select Year</h4>
                    <DatePicker
                      selected={selectedYear}
                      onChange={(date) => setSelectedYear(date)}
                      dateFormat="yyyy"
                      showYearPicker
                      locale="en-GB"
                      minDate={subYears(new Date(), 20)} // Adjust for minimum year range
                      maxDate={new Date()}
                      placeholderText="Select Year"
                      className="form-control"
                      popperPlacement="bottom"
                      popperModifiers={[
                        {
                          name: "preventOverflow",
                          options: {
                            boundary: "viewport",
                          },
                        },
                        {
                          name: "offset",
                          options: {
                            offset: [0, 10],
                          },
                        },
                      ]}
                      withPortal // Forces the calendar to open in a portal
                    />
                  </>
                )}
              </Panel>
            ))}
          </Collapse>
        </Modal.Body>
        <Modal.Footer>
          <Button key="submit" type="primary" onClick={handleSubmit}>
            Get Report
          </Button>
          ,
        </Modal.Footer>
      </Modal>

      <Modal
        show={showManageResellerModal}
        onHide={() => {
          setShowManageResellerModal(false);
        }}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Assign Teams</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              <div className="col-md-6">
                <Form.Group controlId="accountmanager">
                  <Form.Label>Current Sales Co-ordinator</Form.Label>
                  <Form.Control
                    type="text"
                    // placeholder="Enter discount value"
                    value={salesPerson}
                    // onChange={(e) => setDiscountValue(e.target.value)}
                    readOnly
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group controlId="salespersonselect">
                  <Form.Label>Select Sales Co-ordinator</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={handleSalesPersonSelect}
                    defaultValue=""
                  >
                    <option value="">Select a Sales Co-ordinater</option>
                    {salesList.map((sale) => (
                      <option key={sale.admin_id} value={sale.admin_id}>
                        {sale.admin_name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>
            </div>
            <br></br>
            <div className="row">
              <div className="col-md-6">
                <Form.Group controlId="accountmanager">
                  <Form.Label>Current PreSales Co-ordinator</Form.Label>
                  <Form.Control
                    type="text"
                    // placeholder="Enter discount value"
                    value={presalesPerson}
                    // onChange={(e) => setDiscountValue(e.target.value)}
                    readOnly
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group controlId="salespersonselect">
                  <Form.Label>Select PreSales Co-ordinator</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={handlePreSalesPersonSelect}
                    defaultValue=""
                  >
                    <option value="">Select a PreSales Co-ordinater</option>
                    {presalesList.map((presale) => (
                      <option key={presale.admin_id} value={presale.admin_id}>
                        {presale.admin_name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>
            </div>
            <br></br>
            <div className="row">
              <div className="col-md-6">
                <Form.Group controlId="accountmanager">
                  <Form.Label>Current Support Engineer</Form.Label>
                  <Form.Control
                    type="text"
                    // placeholder="Enter discount value"
                    value={supportPerson}
                    // onChange={(e) => setDiscountValue(e.target.value)}
                    readOnly
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group controlId="salespersonselect">
                  <Form.Label>Select Support Engineer</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={handleSupportPersonSelect}
                    defaultValue=""
                  >
                    <option value="">Select a Support Engineer</option>
                    {supportList.map((support) => (
                      <option key={support.admin_id} value={support.admin_id}>
                        {support.admin_name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>
            </div>
            <br></br>
            <div className="row">
              <div className="col-md-6">
                <Form.Group controlId="accountmanager">
                  <Form.Label>Current Deployment Engineer</Form.Label>
                  <Form.Control
                    type="text"
                    // placeholder="Enter discount value"
                    value={deploymentPerson}
                    // onChange={(e) => setDiscountValue(e.target.value)}
                    readOnly
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group controlId="salespersonselect">
                  <Form.Label>Select Deployment Engineer</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={handleDeploymentPersonSelect}
                    defaultValue=""
                  >
                    <option value="">Select a Deployment Engineer</option>
                    {deploymentList.map((deploy) => (
                      <option key={deploy.admin_id} value={deploy.admin_id}>
                        {deploy.admin_name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>
            </div>
            <br />
            <Button variant="primary" onClick={handleAssignTeam}>
              Assign Team
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default RegisteredUsers;
