import React, { useState, useEffect, Fragment } from "react";
import { useCookies } from "react-cookie";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { get_json_data, assign_products } from "../../utils/api";
import googleLogo from "../../public/assets/images/product_images/google2.png";
import jumpcloudLogo from "../../public/assets/images/product_images/jumpcloud2.png";
import microsoftLogo from "../../public/assets/images/product_images/microsoft2.png";
import spinLogo from "../../public/assets/images/product_images/spin_ai2.png";
import scaleDinoLogo from "../../public/assets/images/product_images/scale_dino2.png";

const providerLogos = {
  Google: googleLogo,
  Jumpcloud: jumpcloudLogo,
  Microsoft: microsoftLogo,
  "Scale Dino": scaleDinoLogo,
  Spin_ai: spinLogo,
};

// const defaultData = {
//   Google: ["Chrome H.W", "Chrome Licence", "GCP", "GWS"],
//   Jumpcloud: ["JC-Platform"],
//   Microsoft: ["Azure", "M365"],
//   "Scale Dino": ["CRM", "Email Sign", "Social Media Manager"],
//   "Spin.ai": ["Spin"],
// };

const AdminAssignProduct = () => {
  const [jsonData, setJsonData] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [savedProducts, setSavedProducts] = useState({});
  //   const [cookies] = useCookies(["products"]);
  const [selectedProducts, setSelectedProducts] = useState({});
  const [providerToSave, setProviderToSave] = useState(null);
  const [cookies] = useCookies(["products", "user_id", "custID", "domainName"]);
  const loginDataFromStorage = localStorage.getItem("admindetails");

  console.log(
    "asda",
    loginDataFromStorage.email,
    cookies.user_id,
    cookies.custID
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await get_json_data();
        console.log(data);
        setJsonData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    document.title = "Product Assign Page";
    // fetchData();
  }, []);

  

  const handleYesNoOption = (provider, productName, option) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [provider]: {
        ...(prevOptions[provider] || {}),
        [productName]: option,
      },
    }));

    setSelectedProducts((prevSelectedProducts) => ({
      ...prevSelectedProducts,
      [provider]: {
        ...(prevSelectedProducts[provider] || {}),
        [productName]: option === "Yes",
      },
    }));

    if (option === "Yes") {
      setSavedProducts((prevSavedProducts) => ({
        ...prevSavedProducts,
        [provider]: [...(prevSavedProducts[provider] || []), productName],
      }));
      toast.success(`Selected product: ${productName}`);
    } else if (option === "No") {
      setSavedProducts((prevSavedProducts) => {
        const updatedProducts = { ...prevSavedProducts };
        if (updatedProducts[provider]) {
          updatedProducts[provider] = updatedProducts[provider].filter(
            (product) => product !== productName
          );
        }
        return updatedProducts;
      });
      toast.info(`Deselected product: ${productName}`);
    }
  };

  

  const handleSubmit = async () => {
    if (!jsonData) {
      console.error("JSON data is null.");
      return;
    }

    const isAnyProductSelected = Object.values(selectedOptions).some(
      (options) => Object.values(options).includes("Yes")
    );
    if (!isAnyProductSelected) {
      alert("Please select at least one product.");
      return;
    }

    if (providerToSave) {
      alert(`Please save changes for ${providerToSave} before submitting.`);
      return;
    }

    console.log("Submitting...");
    console.log("Saved Products:", savedProducts);

    try {
      const productsList = Object.keys(savedProducts).flatMap(
        (provider) => savedProducts[provider]
      );

      const requestBody = {
        products_list: productsList,
        user_id: cookies.user_id,
        cust_id: cookies.custID,
      };

      console.log("Request Body:", requestBody); // Log the request body

      const response = await assign_products(requestBody);
      console.log("Response:", response); // Log the response

      if (response.status === 200) {
        toast.success("Product Assigned Successfully");
        window.location.href = "/adminmaincomponent";
      } else {
        window.alert(response.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("An error occurred while submitting the data."); // Display error toast
    }
  };

  const handleBackClick = () => {
    window.location.href = "/adminmaincomponent";
  };

  const handleReset = () => {
    setSelectedOptions({});
    setSavedProducts({});
    window.location.reload();
  };

  if (!jsonData) {
    return <div>Loading...</div>;
  }

  return (
    <Fragment>
      <ToastContainer />
      <div className="bg_image">
        <div className="container-fluid full-page-wrapper">
          <div className="row">
            <div className="col-lg-12">
              <br></br>
              <br></br>
              <br></br>

              <div className="card">
                <div className="card-body">
                  <h1 className="font-color mb-4">Products List</h1>

                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          {Object.keys(jsonData).map((provider, index) => (
                            <td key={index}>
                              <img
                                src={providerLogos[provider]}
                                alt={provider}
                                className="thumb-image"
                              />
                            </td>
                          ))}
                        </tr>
                      </thead>
                      <tbody style={{ maxHeight: "300px", overflowY: "auto" }}>
                        <tr>
                          {Object.keys(jsonData).map((provider, index) => (
                            <td key={index} style={{ width: "300px" }}>
                              <div>
                                <table className="table table-hover">
                                  <tbody>
                                    {jsonData[provider].map(
                                      (product, index) => (
                                        <tr
                                          key={index}
                                          style={{
                                            backgroundColor: selectedProducts[
                                              provider
                                            ]?.[product]
                                              ? "#ffffcc"
                                              : "transparent",
                                          }}
                                        >
                                          <td>{product}</td>
                                          <td>
                                            <i
                                              className="fa fa-check"
                                              style={{
                                                color: "#219000",
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                handleYesNoOption(
                                                  provider,
                                                  product,
                                                  "Yes"
                                                )
                                              }
                                            ></i>{" "}
                                          </td>
                                          <td>
                                            <i
                                              className="fa fa-times"
                                              style={{
                                                color: "#ff0000",
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                handleYesNoOption(
                                                  provider,
                                                  product,
                                                  "No"
                                                )
                                              }
                                            ></i>{" "}
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                              <br></br>
                              {/* <button
                                  type="button"
                                  className="btn btn-primary btn-lg mr-2"
                                  onClick={() => handleSaveProvider(provider)}
                                >
                                  Save
                                </button> */}
                            </td>
                          ))}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <button
            type="button"
            className="btn btn-success btn-lg mr-2"
            onClick={handleBackClick}
            style={{ top: "10px" }} // Add top style here
          >
            Back
          </button>
          <button
            type="button"
            className="btn btn-primary btn-lg mr-2"
            onClick={handleSubmit}
            disabled={!!providerToSave}
            style={{ top: "10px" }} // Add top style here
          >
            Submit
          </button>
          <button
            type="button"
            className="btn btn-warning btn-lg mr-2"
            onClick={handleReset}
            style={{ top: "10px" }} // Add top style here
          >
            Reset
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default AdminAssignProduct;
