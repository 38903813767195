import React from "react";
import { Link } from "react-router-dom";

const SessionTimeout = () => {
  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>Your session has timed out</h1>
      <p>Please log in again to continue.</p>
      <Link to="/userlogin">Login</Link>
    </div>
  );
};

export default SessionTimeout;
