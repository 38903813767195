import React, { useState } from "react";
import { Modal, Table, Tabs, Tab, Form } from "react-bootstrap";
const DetailModal = ({ data, isOpen, onClose }) => {
  const [selectedCase, setSelectedCase] = useState(null);
  const [showCaseDetailsModal, setShowCaseDetailsModal] = useState(false);
  const [selectedQuotation, setSelectedQuotation] = useState(null);
  const [showQuotationDetailsModal, setShowQuotationDetailsModal] =
    useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showOrderDetailsModal, setShowOrderDetailsModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [showInvoiceDetailsModal, setShowInvoiceDetailsModal] = useState(false);

  if (!data) {
    return null;
  }

  const resellers_customers = data.data.resellers_details
    ?.resellers_customers || {
    customers: [],
  };
  const cases_details = data.data.cases_details || [];
  const quotation_details = data.data.quotation_details || [];
  const orders_details = data.data.orders_details || [];
  const invoice_details = data.data.invoices_lists || [];
  const discount_details = data.data.discount_lists || [];
  const added_licence_details = data.data.added_licence_details || [];

  const handleCaseClick = (caseDetail) => {
    setSelectedCase(caseDetail);
    setShowCaseDetailsModal(true);
  };

  const handleCloseCaseDetailsModal = () => {
    setShowCaseDetailsModal(false);
    setSelectedCase(null);
  };

  const handleQuotationClick = (quotationDetail) => {
    setSelectedQuotation(quotationDetail);
    setShowQuotationDetailsModal(true);
  };

  const handleCloseQuotationDetailsModal = () => {
    setShowQuotationDetailsModal(false);
    setSelectedQuotation(null);
  };
  const handleOrderClick = (orderDetail) => {
    setSelectedOrder(orderDetail);
    setShowOrderDetailsModal(true);
  };

  const handleCloseOrderDetailsModal = () => {
    setShowOrderDetailsModal(false);
    setSelectedOrder(null);
  };
  const handleInvoiceClick = (invoiceDetail) => {
    setSelectedInvoice(invoiceDetail);
    setShowInvoiceDetailsModal(true);
  };

  const handleCloseInvoiceDetailsModal = () => {
    setShowInvoiceDetailsModal(false);
    setSelectedInvoice(null);
  };
  return (
    <>
      <Modal show={isOpen} onHide={onClose} size="lg" scrollable>
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div style={{ flex: 1, textAlign: "left" }}>Details </div>
            <div style={{ flex: 1, textAlign: "center" }}>
              <img
                src={data.data.resellers_details.logopath}
                alt="Logo"
                style={{ height: 40, width: 40 }}
              />
            </div>
            <div style={{ flex: 1 }}></div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ display: "flex", maxHeight: "600px", overflowY: "auto" }}
        >
          {/* Left partition */}
          <div
            style={{
              flex: "0 0 300px",
              paddingRight: "10px",
              maxHeight: "400px",
              overflowY: "auto",
              padding: "20px",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          >
            <h5 style={{ marginBottom: "20px" }}>Reseller Details</h5>

            <Form.Group controlId="resellerName">
              <Form.Label>Reseller Name</Form.Label>
              <Form.Control
                type="text"
                value={data.data.resellers_details.reseller_name || ""}
                readOnly
              />
            </Form.Group>
            <Form.Group controlId="resellerName">
              <Form.Label>Reseller Name</Form.Label>
              <Form.Control
                type="text"
                value={data.data.resellers_details.discount_in + "%" || ""}
                readOnly
              />
            </Form.Group>

            <Form.Group controlId="companyName">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                value={data.data.resellers_details.company_name || ""}
                readOnly
              />
            </Form.Group>

            <Form.Group controlId="phoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                value={data.data.resellers_details.phone_number || ""}
                readOnly
              />
            </Form.Group>

            <Form.Group controlId="secondaryContactName">
              <Form.Label>Secondary Contact Name</Form.Label>
              <Form.Control
                type="text"
                value={data.data.resellers_details.secondary_contact_name || ""}
                readOnly
              />
            </Form.Group>

            <Form.Group controlId="secondaryContactEmail">
              <Form.Label>Secondary Contact Email</Form.Label>
              <Form.Control
                type="text"
                value={
                  data.data.resellers_details.secondary_contact_email || ""
                }
                readOnly
              />
            </Form.Group>

            <Form.Group controlId="accountContactName">
              <Form.Label>Account Contact Name</Form.Label>
              <Form.Control
                type="text"
                value={data.data.resellers_details.account_contact_name || ""}
                readOnly
              />
            </Form.Group>

            <Form.Group controlId="accountContactEmail">
              <Form.Label>Account Contact Email</Form.Label>
              <Form.Control
                type="text"
                value={data.data.resellers_details.account_contact_email || ""}
                readOnly
              />
            </Form.Group>

            <Form.Group controlId="address">
              <Form.Label>Address</Form.Label>
              <textarea
                className="form-control"
                rows="3"
                value={`${data.data.resellers_details.address || ""} ${
                  data.data.resellers_details.state || ""
                } ${data.data.resellers_details.pincode || ""}`}
                readOnly
              />
            </Form.Group>
            <Form.Group controlId="panNumber">
              <Form.Label>Pan Number</Form.Label>
              <Form.Control
                type="text"
                value={data.data.resellers_details.pan_number || ""}
                readOnly
              />
            </Form.Group>

            <Form.Group controlId="gstNumber">
              <Form.Label>GST Number</Form.Label>
              <Form.Control
                type="text"
                value={data.data.resellers_details.gst_number || ""}
                readOnly
              />
            </Form.Group>

            <Form.Group controlId="tanNumber">
              <Form.Label>TAN Number</Form.Label>
              <Form.Control
                type="text"
                value={data.data.resellers_details.tan_number || ""}
                readOnly
              />
            </Form.Group>
          </div>
          <div style={{ flex: 7 }}>
            <Tabs
              defaultActiveKey="resellers_customers"
              id="detail-tabs"
              style={{ overflowX: "auto" }}
            >
              <Tab eventKey="resellers_customers" title="Resellers Customers">
                <div
                  className="table-responsive"
                  style={{ maxHeight: "300px", overflowX: "auto" }}
                >
                  <Table
                    striped
                    bordered
                    hover
                    style={{
                      minWidth: "100%",
                      tableLayout: "auto",
                      maxHeight: "300px", // Example height, adjust as needed
                      overflowY: "auto",
                    }}
                  >
                    <thead
                      style={{
                        position: "sticky",
                        top: 0,
                        background: "white",
                      }}
                    >
                      <tr>
                        <th>Customer ID</th>
                        <th>Name</th>
                        <th>Company</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Domain Name</th>
                        <th>Industry</th>
                        <th>Customer Priority</th>
                      </tr>
                    </thead>
                    <tbody>
                      {resellers_customers.map((customer, index) => (
                        <tr key={index}>
                          {customer.map((item, idx) => (
                            <td key={idx} style={{ whiteSpace: "normal" }}>
                              {item}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Tab>
              <Tab eventKey="cases_details" title="Cases Details">
                <div
                  className="table-responsive"
                  style={{ maxHeight: "300px", overflowY: "auto" }}
                >
                  <Table
                    striped
                    bordered
                    hover
                    style={{
                      minWidth: "100%",
                      tableLayout: "auto",
                      maxHeight: "300px", // Example height, adjust as needed
                      overflowY: "auto",
                    }}
                  >
                    <thead
                      style={{
                        position: "sticky",
                        top: 0,
                        background: "white",
                      }}
                    >
                      <tr>
                        <th>Case Name</th>
                        <th>Customer Name</th>
                        <th>Email ID</th>
                        <th>Status</th>
                        <th>Created Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cases_details.map((caseDetail, index) => (
                        <tr
                          key={index}
                          onClick={() => handleCaseClick(caseDetail)}
                        >
                          <td style={{ whiteSpace: "normal" }}>
                            {caseDetail.case_name}
                          </td>
                          <td style={{ whiteSpace: "normal" }}>
                            {caseDetail.customer_name}
                          </td>
                          <td style={{ whiteSpace: "normal" }}>
                            {caseDetail.customer_emailid}
                          </td>
                          <td style={{ whiteSpace: "normal" }}>
                            {caseDetail.status}
                          </td>
                          <td style={{ whiteSpace: "normal" }}>
                            {new Date(
                              caseDetail.created_date
                            ).toLocaleDateString()}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Tab>
              <Tab eventKey="quotation_details" title="Quotation Details">
                <div
                  className="table-responsive"
                  style={{ maxHeight: "300px", overflowX: "auto" }}
                >
                  <Table
                    striped
                    bordered
                    hover
                    style={{
                      minWidth: "100%",
                      tableLayout: "auto",
                      maxHeight: "300px", // Example height, adjust as needed
                      overflowY: "auto",
                    }}
                  >
                    <thead
                      style={{
                        position: "sticky",
                        top: 0,
                        background: "white",
                      }}
                    >
                      <tr>
                        <th>Quotation ID</th>
                        <th>Plan type</th>
                        <th>Plan duration</th>
                        <th>Total price</th>
                        <th>Discount</th>
                        <th>Discount_amt</th>
                        <th>Discounted price</th>
                        <th>Final price(18%)</th>
                        <th>Created date</th>
                        <th>Quotation expiry</th>
                      </tr>
                    </thead>
                    <tbody>
                      {quotation_details.map((quotationDetail, index) => (
                        <tr
                          key={index}
                          onClick={() => handleQuotationClick(quotationDetail)}
                        >
                          <td style={{ whiteSpace: "normal" }}>
                            {quotationDetail.quotation_id}
                          </td>
                          <td style={{ whiteSpace: "normal" }}>
                            {quotationDetail.plan_type}
                          </td>
                          <td style={{ whiteSpace: "normal" }}>
                            {quotationDetail.plan_duration}
                          </td>
                          <td style={{ whiteSpace: "normal" }}>
                            {quotationDetail.final_price_wto_gst}
                          </td>
                          <td style={{ whiteSpace: "normal" }}>
                            {quotationDetail.discount}
                          </td>
                          <td style={{ whiteSpace: "normal" }}>
                            {quotationDetail.discount_amt}
                          </td>
                          <td style={{ whiteSpace: "normal" }}>
                            {quotationDetail.discounted_price}
                          </td>
                          <td style={{ whiteSpace: "normal" }}>
                            {quotationDetail.final_price_wt_gst}
                          </td>
                          <td style={{ whiteSpace: "normal" }}>
                            {new Date(
                              quotationDetail.created_date
                            ).toLocaleDateString()}
                          </td>
                          <td style={{ whiteSpace: "normal" }}>
                            {
                              new Date(quotationDetail.quotation_expiry)
                                .toLocaleDateString
                            }
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Tab>
              <Tab eventKey="orders_details" title="Orders Details">
                <div
                  className="table-responsive"
                  style={{ maxHeight: "300px", overflowX: "auto" }}
                >
                  <Table
                    striped
                    bordered
                    hover
                    style={{
                      minWidth: "100%",
                      tableLayout: "auto",
                      maxHeight: "300px", // Example height, adjust as needed
                      overflowY: "auto",
                    }}
                  >
                    <thead
                      style={{
                        position: "sticky",
                        top: 0,
                        background: "white",
                      }}
                    >
                      <tr>
                        <th>Order ID</th>
                        <th>Plan type</th>
                        <th>Plan duration</th>
                        <th>Total price</th>
                        <th>Discount</th>
                        <th>Discount_amt</th>
                        <th>Discounted price</th>
                        <th>Final price(18%)</th>
                        <th>Order date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders_details.map((orderDetail, index) => (
                        <tr
                          key={index}
                          onClick={() => handleOrderClick(orderDetail)}
                        >
                          <td style={{ whiteSpace: "normal" }}>
                            {orderDetail.order_id}
                          </td>
                          <td style={{ whiteSpace: "normal" }}>
                            {orderDetail.plan_type}
                          </td>
                          <td style={{ whiteSpace: "normal" }}>
                            {orderDetail.plan_duration}
                          </td>
                          <td style={{ whiteSpace: "normal" }}>
                            {orderDetail.total}
                          </td>
                          <td style={{ whiteSpace: "normal" }}>
                            {orderDetail.discount}
                          </td>
                          <td style={{ whiteSpace: "normal" }}>
                            {orderDetail.discount_amount}
                          </td>
                          <td style={{ whiteSpace: "normal" }}>
                            {orderDetail.discounted_price}
                          </td>
                          <td style={{ whiteSpace: "normal" }}>
                            {orderDetail.final_price_wt_gst}
                          </td>
                          <td style={{ whiteSpace: "normal" }}>
                            {
                              new Date(orderDetail.order_date)
                                .toLocaleDateString
                            }
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Tab>
              <Tab eventKey="invoice_details" title="Invoice Details">
                <div
                  className="table-responsive"
                  style={{ maxHeight: "300px", overflowX: "auto" }}
                >
                  <Table
                    striped
                    bordered
                    hover
                    style={{
                      minWidth: "100%",
                      tableLayout: "auto",
                      maxHeight: "300px", // Example height, adjust as needed
                      overflowY: "auto",
                    }}
                  >
                    <thead
                      style={{
                        position: "sticky",
                        top: 0,
                        background: "white",
                      }}
                    >
                      <tr>
                        <th>Invoice No.</th>
                        <th>Invoice Date</th>
                        <th>Period Start Date</th>
                        <th>Period Start Date</th>
                        <th>Payment Status</th>
                        <th>Total Price</th>
                        <th>Reseller Discount</th>
                        <th>Discount Amount</th>
                        <th>Discounted Price</th>
                        <th>Deal Discount</th>
                        <th>Final Discounted Price</th>
                        <th>Final price(18%)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoice_details.map((invoiceDetail, index) => (
                        <tr
                          key={index}
                          onClick={() => handleInvoiceClick(invoiceDetail)}
                        >
                          <td style={{ whiteSpace: "normal" }}>
                            {invoiceDetail.invoice_no}
                          </td>
                          <td style={{ whiteSpace: "normal" }}>
                            {new Date(
                              invoiceDetail.invoice_date
                            ).toLocaleDateString()}
                          </td>
                          <td style={{ whiteSpace: "normal" }}>
                            {new Date(
                              invoiceDetail.period_start_date
                            ).toLocaleDateString()}
                          </td>
                          <td style={{ whiteSpace: "normal" }}>
                            {new Date(
                              invoiceDetail.period_end_date
                            ).toLocaleDateString()}
                          </td>
                          <td style={{ whiteSpace: "normal" }}>
                            {invoiceDetail.payment_status}
                          </td>
                          <td style={{ whiteSpace: "normal" }}>
                            {invoiceDetail.total}
                          </td>
                          <td style={{ whiteSpace: "normal" }}>
                            {invoiceDetail.discount}
                          </td>
                          <td style={{ whiteSpace: "normal" }}>
                            {invoiceDetail.discount_amount}
                          </td>
                          <td style={{ whiteSpace: "normal" }}>
                            {invoiceDetail.discounted_price}
                          </td>
                          <td style={{ whiteSpace: "normal" }}>
                            {invoiceDetail.additional_deal_discount}
                          </td>
                          <td style={{ whiteSpace: "normal" }}>
                            {invoiceDetail.final_discounted_price}
                          </td>
                          <td style={{ whiteSpace: "normal" }}>
                            {invoiceDetail.total_wt_gst}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Tab>
              <Tab
                eventKey="partner_discount_details"
                title="Partner Discount Details"
              >
                <div
                  className="table-responsive"
                  style={{ maxHeight: "300px", overflowX: "auto" }}
                >
                  <Table
                    striped
                    bordered
                    hover
                    style={{
                      minWidth: "100%",
                      tableLayout: "auto",
                      maxHeight: "300px", // Example height, adjust as needed
                      overflowY: "auto",
                    }}
                  >
                    <thead
                      style={{
                        position: "sticky",
                        top: 0,
                        background: "white",
                      }}
                    >
                      <tr>
                        <th>Discount</th>
                        <th>Updated On</th>
                      </tr>
                    </thead>
                    <tbody>
                      {discount_details.map((discountDetail, index) => (
                        <tr
                          key={index}
                          // onClick={() => handleInvoiceClick(invoiceDetail)}
                        >
                          <td style={{ whiteSpace: "normal" }}>
                            {discountDetail.discount_in}
                          </td>
                          <td style={{ whiteSpace: "normal" }}>
                            {new Date(
                              discountDetail.updated_on
                            ).toLocaleDateString()}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Tab>
              <Tab eventKey="added_licences" title="Added Licences">
                <div
                  className="table-responsive"
                  style={{ maxHeight: "300px", overflowX: "auto" }}
                >
                  <Table
                    striped
                    bordered
                    hover
                    style={{
                      minWidth: "100%",
                      tableLayout: "auto",
                      maxHeight: "300px", // Example height, adjust as needed
                      overflowY: "auto",
                    }}
                  >
                    <thead
                      style={{
                        position: "sticky",
                        top: 0,
                        background: "white",
                      }}
                    >
                      <tr>
                        <th>Domain Name</th>
                        <th>Product Name</th>
                        <th>Initial Licences</th>
                        <th>Current Licences</th>
                        <th>Changed Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {added_licence_details.map(
                        (addedlicenceDetail, index) => (
                          <tr
                            key={index}
                            // onClick={() => handleInvoiceClick(invoiceDetail)}
                          >
                            <td style={{ whiteSpace: "normal" }}>
                              {addedlicenceDetail.customer_domain_name}
                            </td>
                            <td style={{ whiteSpace: "normal" }}>
                              {addedlicenceDetail.product_name}
                            </td>
                            <td style={{ whiteSpace: "normal" }}>
                              {addedlicenceDetail.olduser_count}
                            </td>
                            <td style={{ whiteSpace: "normal" }}>
                              {addedlicenceDetail.newuser_count}
                            </td>
                            <td style={{ whiteSpace: "normal" }}>
                              {new Date(
                                addedlicenceDetail.changed_date
                              ).toLocaleDateString()}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </div>
              </Tab>
            </Tabs>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showCaseDetailsModal}
        onHide={handleCloseCaseDetailsModal}
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title>Selected Case Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedCase && (
            <div
              className="table-responsive"
              style={{ maxHeight: "300px", overflowX: "auto" }}
            >
              <Table
                striped
                bordered
                hover
                style={{ minWidth: "100%", tableLayout: "auto" }}
              >
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>User Count</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedCase.prod_details.map((detail, index) => (
                    <tr key={index}>
                      <td>{Object.keys(detail)[0]}</td>
                      <td>{detail[Object.keys(detail)[0]]}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        show={showQuotationDetailsModal}
        onHide={handleCloseQuotationDetailsModal}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Selected Quotation Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedQuotation && (
            <div
              className="table-responsive"
              style={{ maxHeight: "300px", overflowX: "auto" }}
            >
              <Table
                striped
                bordered
                hover
                style={{ minWidth: "100%", tableLayout: "auto" }}
              >
                <thead>
                  <tr>
                    <th>Sku Name</th>
                    <th>User Count</th>
                    <th>List Price</th>
                    <th>Shiviom Price</th>
                    <th>Subtotal</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedQuotation.concatenated_products.map(
                    (detail, index) => (
                      <tr key={index}>
                        <td>{detail[0]}</td>
                        <td>{detail[1]}</td>
                        <td>{detail[2]}</td>
                        <td>{detail[3]}</td>
                        <td>{detail[5]}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </div>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        show={showOrderDetailsModal}
        onHide={handleCloseOrderDetailsModal}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Selected Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedOrder && (
            <div
              className="table-responsive"
              style={{ maxHeight: "300px", overflowX: "auto" }}
            >
              <Table
                striped
                bordered
                hover
                style={{ minWidth: "100%", tableLayout: "auto" }}
              >
                <thead>
                  <tr>
                    <th>Sku Name</th>
                    <th>User Count</th>
                    <th>List Price</th>
                    <th>Shiviom Price</th>
                    <th>Subtotal</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedOrder.prod_details.map((detail, index) => (
                    <tr key={index}>
                      <td>{detail[Object.keys(detail)[1]]}</td>
                      <td>{detail[Object.keys(detail)[2]]}</td>
                      <td>{detail[Object.keys(detail)[3]]}</td>
                      <td>{detail[Object.keys(detail)[4]]}</td>
                      <td>{detail[Object.keys(detail)[5]]}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        show={showInvoiceDetailsModal}
        onHide={handleCloseInvoiceDetailsModal}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Selected Invoice Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedInvoice && (
            <div
              className="table-responsive"
              style={{ maxHeight: "300px", overflowX: "auto" }}
            >
              <Table
                striped
                bordered
                hover
                style={{ minWidth: "100%", tableLayout: "auto" }}
              >
                <thead>
                  <tr>
                    <th>Sku Name</th>
                    <th>User Count</th>
                    <th>List Price</th>
                    <th>Shiviom Price</th>
                    <th>Subtotal</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedInvoice.products_details.map((detail, index) => (
                    <tr key={index}>
                      <td>{detail[Object.keys(detail)[0]]}</td>
                      <td>{detail[Object.keys(detail)[1]]}</td>
                      <td>{detail[Object.keys(detail)[2]]}</td>
                      <td>{detail[Object.keys(detail)[3]]}</td>
                      <td>{detail[Object.keys(detail)[4]]}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DetailModal;
