import React, { Fragment, useState, useEffect } from "react";
import { Modal, Button, Spinner, Form } from "react-bootstrap";
import {
  getResellersCustomersList,
  importCustomerData,
  getRegisteredList,
} from "../../utils/api";
import { FaSortUp, FaSortDown, FaSort } from "react-icons/fa";
import { CiImport, CiExport } from "react-icons/ci";
import { common } from "@mui/material/colors";

const ResellersCustomers = () => {
  const [customerList, setCustomerList] = useState([]);
  const [filteredCustomerList, setFilteredCustomerList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showViewModal, setShowViewModal] = useState(false);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [loading, setLoading] = useState(false);
  const admindetailsmodules = localStorage.getItem("admindetails");
  const admin_email = JSON.parse(admindetailsmodules).email;
  const [showImportModal, setShowImportModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [resellerList, setResellerList] = useState([]);
  const [selectedReseller, setSelectedReseller] = useState("");

  useEffect(() => {
    async function fetchCustomerList() {
      try {
        setLoading(true);
        const data = await getResellersCustomersList(admin_email);
        setCustomerList(data);
        setFilteredCustomerList(data);
      } catch (error) {
        console.error(
          "Failed to fetch resellers customers list:",
          error.message
        );
      } finally {
        setLoading(false);
      }
    }

    fetchCustomerList();
    fetchResellerList();
  }, []);
  const fetchResellerList = async () => {
    try {
      setLoading(true);
      const response = await getRegisteredList(admin_email);
      console.log(response);
      setResellerList(response || []);
    } catch (error) {
      console.error("Error fetching user list:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleSearchInputChange = (value) => {
    setSearchInput(value.toLowerCase());
    const filteredList = customerList.filter((customer) => {
      if (customer) {
        return (
          (customer.company_name &&
            customer.company_name.toLowerCase().includes(value)) ||
          (customer.customer_company_name &&
            customer.customer_company_name.toLowerCase().includes(value)) ||
          (customer.customer_domainname &&
            customer.customer_domainname.toLowerCase().includes(value)) ||
          (customer.customer_name &&
            customer.customer_name.toLowerCase().includes(value))
        );
      }
      return false;
    });
    setFilteredCustomerList(filteredList);
  };

  const handleReset = () => {
    setSearchInput("");
    setFilteredCustomerList(customerList);
  };

  const handleSort = (column) => {
    let sortedList = [...filteredCustomerList];
    if (sortColumn === column) {
      sortedList.reverse(); // Toggle the sorting order
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      sortedList.sort((a, b) => {
        if (a[column] < b[column]) return -1;
        if (a[column] > b[column]) return 1;
        return 0;
      });
      setSortColumn(column);
      setSortOrder("asc");
    }
    setFilteredCustomerList(sortedList);
  };

  const handleViewDetails = (customer) => {
    setSelectedCustomer(customer);
    setShowViewModal(true);
  };

  const handleCloseModal = () => {
    setShowViewModal(false);
  };
  const handleFileUpload = async () => {
    const fileInput = document.getElementById("fileInput"); // Get the file input element
    if (!fileInput || !fileInput.files || fileInput.files.length === 0) {
      window.alert("Please upload the file first.");
      return; // Exit function if no file is selected
    }
    setUploading(true); // Show loader when upload starts
    const file = fileInput.files[0]; // Get the uploaded file
    console.log(selectedReseller);
    try {
      const response = await importCustomerData(
        file,
        selectedReseller
      );
      if (response.status === 200) {
        const data = await response.json();
        window.alert(data.message);
        const newCustomers = await getResellersCustomersList(admin_email);
        setCustomerList(newCustomers);
        setShowImportModal(false);
        setSelectedReseller("");
      } else {
        const data = await response.json();
        window.alert(data.message);
        setUploading(false);
      }
    } catch (error) {
      console.error("Error uploading file:", error.message);
      window.alert("An error occurred while uploading the file.");
    } finally {
      setUploading(false); // Hide loader after upload completes (whether success or failure)
    }
  };
  const handleCloseImportModal = () => {
    setLoading(false);
    setShowImportModal(false);
  };
  const handleFileDownload = () => {
    // Create a temporary anchor element
    const downloadLink = document.createElement("a");
    // Set the href attribute to the URL of the file to be downloaded
    downloadLink.href = "/customerdata-template.xlsx"; // Replace with the actual file path
    // Set the download attribute to specify the file name when downloaded
    downloadLink.download = "customerdata-template.xlsx"; // Replace with the desired file name

    // Append the anchor element to the body
    document.body.appendChild(downloadLink);

    // Trigger a click event on the anchor element to initiate the download
    downloadLink.click();

    // Remove the anchor element from the body after the download
    document.body.removeChild(downloadLink);
  };
  const handleOpenImportModal = () => {
    setShowImportModal(true);
  };
  // Function to handle reseller selection
  const handleResellerChange = (e) => {
    const selectedResellerEmail = e.target.value;
    setSelectedReseller(selectedResellerEmail);
  };

  return (
    <Fragment>
      <style>
        {`
          .sortable-header {
            cursor: pointer;
            position: relative;
          }

          .sort-icon {
            margin-left: 5px;
            color: #6c757d; /* Default color */
            transition: color 0.2s ease;
          }

          .sortable-header:hover .sort-icon {
            color: #007bff; /* Change this to your preferred hover color */
          }

          .table-responsive {
            max-height: 500px;
            overflow-y: auto;
          }
        `}
      </style>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="mb-1">Resellers Customers Lists</h4>
            <br />
            <div className="search-container mb-3">
              <input
                type="text"
                placeholder="Search"
                value={searchInput}
                onChange={(e) => handleSearchInputChange(e.target.value)}
                style={{ float: "left" }}
              />
              <button
                className="btn btn-primary ml-2"
                onClick={handleReset}
                style={{ float: "left" }}
              >
                Reset
              </button>
              <Button
                className="btn btn-primary ml-2"
                style={{ float: "right" }}
                onClick={handleOpenImportModal}
                title="Import Customer Data"
              >
                <CiImport size={15} />
              </Button>
            </div>
            <br />
            <div className="table-responsive">
              <table className="table table-hover sticky-header">
                <thead>
                  <tr>
                    <th
                      className="sortable-header"
                      onClick={() => handleSort("customer_name")}
                    >
                      Customer Name
                      {sortColumn === "customer_name" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" /> // Default sort icon
                      )}
                    </th>
                    <th
                      className="sortable-header"
                      onClick={() => handleSort("customer_company_name")}
                    >
                      Customer Company Name
                      {sortColumn === "customer_company_name" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" />
                      )}
                    </th>
                    <th
                      className="sortable-header"
                      onClick={() => handleSort("customer_domainname")}
                    >
                      Customer Domain Name
                      {sortColumn === "customer_domainname" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" />
                      )}
                    </th>
                    <th
                      className="sortable-header"
                      onClick={() => handleSort("company_name")}
                    >
                      Reseller Company Name
                      {sortColumn === "company_name" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" />
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredCustomerList.map((customer) => (
                    <tr
                      key={customer.cust_id}
                      onClick={() => handleViewDetails(customer)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{customer.customer_name}</td>
                      <td>{customer.customer_company_name}</td>
                      <td>{customer.customer_domainname}</td>
                      <td>{customer.company_name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Viewing Customer Details */}
      <Modal show={showViewModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Customer Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedCustomer && (
            <div>
              <p>
                <strong>Customer Name:</strong> {selectedCustomer.customer_name}
              </p>
              <p>
                <strong>Customer Email:</strong>{" "}
                {selectedCustomer.customer_emailid}
              </p>
              <p>
                <strong>Customer Company Name:</strong>{" "}
                {selectedCustomer.customer_company_name}
              </p>
              <p>
                <strong>Customer Domain Name:</strong>{" "}
                {selectedCustomer.customer_domainname}
              </p>
              <p>
                <strong>Reseller Company Name:</strong>{" "}
                {selectedCustomer.company_name}
              </p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showImportModal} onHide={handleCloseImportModal}>
        <Modal.Header closeButton>
          <Modal.Title>Import Customer Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ color: "red" }}>
            Note:- If you want to upload bulk data then first you need to
            download the file template fill the data in that file and upload it.
          </p>
          <Form.Group controlId="salespersonselect">
            <Form.Label>Select Sales Co-ordinator</Form.Label>
            <Form.Control
              as="select"
              onChange={handleResellerChange}
              defaultValue=""
            >
              <option value="">Select Reseller</option>
              {resellerList.map((reseller) => (
                <option
                  key={reseller.reseller_id}
                  value={reseller.reseller_email}
                >
                  {reseller.reseller_name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <br></br>
          <p>Download the file template:</p>
          <i
            class="fa fa-download"
            aria-hidden="true"
            style={{ fontSize: "24px" }}
            onClick={handleFileDownload}
            title="Download"
          ></i>{" "}
          <hr />
          <p>Upload the filled data file:</p>
          <input
            type="file"
            className="form-control"
            id="fileInput"
            // onChange={handleFileUpload}
            accept=".xlsx,.csv"
            required
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-secondary"
            onClick={handleCloseImportModal}
          >
            Close
          </Button>
          <Button className="btn btn-primary ml-2" onClick={handleFileUpload}>
            Import
          </Button>
          {uploading && (
            <div className="loader">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          )}
          {/* Conditionally render the loader */}
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default ResellersCustomers;
