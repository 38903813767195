import React, { Fragment, useState, useEffect } from "react";
import {
  submitFormData,
  get_bank_details,
  getCompanyTypes,
} from "../../utils/api";
import { useCookies } from "react-cookie";

const UserRegistrationkysdetailsmodal = () => {
  const [formData, setFormData] = useState({
    ownerfullname: "",
    owneremailid: "",
    secondcontactname: "",
    secondcontactemailid: "",
    accountcontactname: "",
    accountcontactemailid: "",
    udyogadharflag: "",
    udyogadhaarnumber: "",
    pannumber: "",
    gstnumber: "",
    tannumber: "",
    pr_address: "",
    pr_city: "",
    pr_state: "",
    pr_pincode: "",
    contactnumber: "",
    bankname: "",
    branchname: "",
    accountnumber: "",
    ifsccode: "",
    reseller_email: "",
    gstcertificate: null,
    panCard: null,
    cancelCheque: null,
    gstcertificateURL: "",
    pancardURL: "",
    cancelChequeURL: "",
    userRegID: "",
    secondarycontactnumber: "",
    accountcontactnumber: "",
    region: "",
    accountholdername: "",
    typeofcompany: "",
  });
  const [updatedFields, setUpdatedFields] = useState({});
  const [cookies] = useCookies(["email"]);
  const email = cookies.email;
  const [ifscError, setIfscError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [contactNumberError, setContactNumberError] = useState("");
  const [secondaryNumberError, setSecondaryNumberError] = useState("");
  const [accountNumberError, setAccountNumberError] = useState("");
  const [panNumberError, setPanNumberError] = useState("");
  const [aadhaarNumberError, setAadhaarNumberError] = useState("");
  const [gstNumberError, setGstNumberError] = useState("");
  const [bankAccountNumberError, setBankAccountNumberError] = useState("");
  const [tanNumberError, setTanNumberError] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [loading, setLoading] = useState(false);
  const [companyTypeList, setCompanyTypeList] = useState([]);
  // const [parsedLoginData, setParsedLoginData] = useState(/* Initial value */);
  const loginDataStatusFromStorage = JSON.parse(
    localStorage.getItem("loginDatastatus")
  );
  useEffect(() => {
    // Fetch product data when component mounts
    const fetchCompanyTypes = async () => {
      try {
        setLoading(true);
        const complist = await getCompanyTypes(); // Call the API function to get product data
        setCompanyTypeList(complist); // Set the fetched product data in state
      } catch (error) {
        window.alert("Error fetching company types:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyTypes(); // Invoke the fetchProductData function
  }, []);
  useEffect(() => {
    document.title = "Registration Page"; // Set the document title

    // Retrieve data from local storage
    const loginDataFromStorage = localStorage.getItem("loginData");

    if (loginDataFromStorage) {
      try {
        const parsedLoginData = JSON.parse(loginDataFromStorage);
        // console.log("yedi porsdada", parsedLoginData);
        setFormData((prevFormData) => ({
          ...prevFormData,

          ownerfullname: parsedLoginData.owner_full_name || "",
          owneremailid: parsedLoginData.owner_email || "",
          secondcontactname: parsedLoginData.secondary_contact_name || "",
          secondcontactemailid: parsedLoginData.secondary_contact_email || "",
          accountcontactname: parsedLoginData.account_contact_name || "",
          accountcontactemailid: parsedLoginData.account_contact_email || "",
          udyogadharflag: parsedLoginData.udyog_adhaar || "",
          udyogadhaarnumber: parsedLoginData.udyog_adhaar_number || "",
          pannumber: parsedLoginData.pan_number || "",
          gstnumber: parsedLoginData.gst_number || "",
          tannumber: parsedLoginData.tan_number || "",
          pr_address: parsedLoginData.address || "",
          pr_city: parsedLoginData.city || "",
          pr_state: parsedLoginData.state || "",
          pr_pincode: parsedLoginData.pincode || "",
          contactnumber: parsedLoginData.contact_number || "",
          bankname: parsedLoginData.bank_name || "",
          branchname: parsedLoginData.branch_name || "",
          accountnumber: parsedLoginData.acc_number || "",
          ifsccode: parsedLoginData.ifsc_code || "",
          userRegID: parsedLoginData.registration_id || "",
          pancardURL: parsedLoginData.documents[2]?.panCard || "",
          gstcertificateURL: parsedLoginData.documents[1]?.gstcertificate || "",
          cancelChequeURL: parsedLoginData.documents[0]?.cancelCheque || "",
          secondarycontactnumber:
            parsedLoginData.secondary_contact_number || "",
          accountcontactnumber: parsedLoginData.account_contact_number || "",
          region: parsedLoginData.region || "",
          accountholdername: parsedLoginData.account_holder_name || "",
          typeofcompany: parsedLoginData.type_of_company || "",
        }));
        // Set bankDetails based on bank_name and branch_name
        setBankDetails({
          bankname: parsedLoginData.bank_name || "", // Use bank_name from parsedLoginData if available
          branchname: parsedLoginData.branch_name || "", // Use branch_name from parsedLoginData if available
        });
      } catch (error) {
        console.error("Error parsing JSON from local storage:", error);
      }
    }
  }, []);
  const validateEmail = (email) => {
    // Email regex pattern for basic validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validateContactNumber = (contactNumber) => {
    // Contact number regex pattern for basic validation
    const contactNumberPattern = /^[0-9]{10}$/;
    return contactNumberPattern.test(contactNumber);
  };

  const validatePANNumber = (panNumber) => {
    // PAN card number regex pattern for basic validation
    const panNumberPattern = /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/;
    return panNumberPattern.test(panNumber);
  };

  const validateAadhaarNumber = (aadhaarNumber) => {
    // Aadhaar card number regex pattern for UUID format validation
    const aadhaarNumberPattern = /^[0-9]{12}$/;
    return aadhaarNumberPattern.test(aadhaarNumber);
  };

  const validateGSTNumber = (gstNumber) => {
    // GST number regex pattern for basic validation
    const gstNumberPattern =
      /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Z]{1}[0-9A-Za-z]{1}$/;
    return gstNumberPattern.test(gstNumber);
  };

  const validateBankAccountNumber = (bankAccountNumber) => {
    // Bank account number regex pattern for basic validation
    const bankAccountNumberPattern = /^[0-9]{9,18}$/;
    return bankAccountNumberPattern.test(bankAccountNumber);
  };

  const validateTANNumber = (tanNumber) => {
    // TAN number regex pattern for basic validation
    const tanNumberPattern = /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/;
    return tanNumberPattern.test(tanNumber);
  };

  const validateIFSCCode = (ifscCode) => {
    const ifscRegex = /^[A-Z]{4}[0][A-Z0-9]{6}$/;
    return ifscRegex.test(ifscCode.toUpperCase());
  };
  const validatePincode = (pincode) => {
    const pincodeRegex = /^\d{6}$/;
    return pincodeRegex.test(pincode);
  };

  const handleownerEmailChange = (e) => {
    const emailValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      owneremailid: emailValue,
    }));
    setEmailError(validateEmail(emailValue) ? "" : "Invalid email format");
  };
  const handlesecondaryEmailChange = (e) => {
    const emailValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      secondcontactemailid: emailValue,
    }));
    setEmailError(validateEmail(emailValue) ? "" : "Invalid email format");
  };
  const handleaccountEmailChange = (e) => {
    const emailValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      accountcontactemailid: emailValue,
    }));
    setEmailError(validateEmail(emailValue) ? "" : "Invalid email format");
  };

  const handleContactNumberChange = (e) => {
    const contactNumberValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      contactnumber: contactNumberValue,
    }));
    setContactNumberError(
      validateContactNumber(contactNumberValue)
        ? ""
        : "Invalid contact number format"
    );
  };
  const handleSecondaryContactNumberChange = (e) => {
    const secondaryContactNumberValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      secondarycontactnumber: secondaryContactNumberValue,
    }));
    setSecondaryNumberError(
      validateContactNumber(secondaryContactNumberValue)
        ? ""
        : "Invalid contact number format"
    );
  };

  const handleAccountContactNumberChange = (e) => {
    const accountContactNumberValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      accountcontactnumber: accountContactNumberValue,
    }));
    setAccountNumberError(
      validateContactNumber(accountContactNumberValue)
        ? ""
        : "Invalid contact number format"
    );
  };

  const handlePANNumberChange = (e) => {
    const panNumberValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      pannumber: panNumberValue.toUpperCase(),
    }));
    setPanNumberError(
      validatePANNumber(panNumberValue) ? "" : "Invalid PAN card number format"
    );
  };

  const handleAadhaarNumberChange = (e) => {
    const aadhaarNumberValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      udyogadhaarnumber: aadhaarNumberValue,
    }));
    setAadhaarNumberError(
      validateAadhaarNumber(aadhaarNumberValue)
        ? ""
        : "Invalid Aadhaar card number format"
    );
  };

  const handleGSTNumberChange = (e) => {
    const gstNumberValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      gstnumber: gstNumberValue.toUpperCase(),
    }));
    setGstNumberError(
      validateGSTNumber(gstNumberValue) ? "" : "Invalid GST number format"
    );
  };

  const handleBankAccountNumberChange = (e) => {
    const bankAccountNumberValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      accountnumber: bankAccountNumberValue,
    }));
    setBankAccountNumberError(
      validateBankAccountNumber(bankAccountNumberValue)
        ? ""
        : "Invalid bank account number format"
    );
  };

  const handleTANNumberChange = (e) => {
    const tanNumberValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      tannumber: tanNumberValue.toUpperCase(),
    }));
    setTanNumberError(
      validateTANNumber(tanNumberValue) ? "" : "Invalid TAN number format"
    );
  };
  const handleIFSCCodeChange = (e) => {
    const ifscCodeValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      ifsccode: ifscCodeValue,
    }));

    // IFSC code validation
    const isValidIFSC = validateIFSCCode(ifscCodeValue);
    setIfscError(isValidIFSC ? "" : "Invalid IFSC code format");
  };
  const [bankDetails, setBankDetails] = useState({
    bankname: "",
    branchname: "",
  });

  const handlePincodeChange = (e) => {
    const pincodeValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      pr_pincode: pincodeValue,
    }));

    // Pincode validation
    const isValidPincode = validatePincode(pincodeValue);
    setPincodeError(isValidPincode ? "" : "Invalid Pincode format");
  };

  const verifyIFSCCode = async () => {
    try {
      setLoading(true);
      // If bank_name and branch_name are not present, make the API call
      const responseData = await get_bank_details(formData.ifsccode);
      setBankDetails({
        bankname: responseData.BANK,
        branchname: responseData.BRANCH,
      });
      setIfscError(""); // Clear IFSC error if successful
    } catch (error) {
      console.error("Error verifying IFSC code:", error);
      setIfscError("IFSC code not found or invalid."); // Set IFSC error message
    } finally {
      setLoading(false);
    }
  };
  const handleFileUpload = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: file,
      [`${name}status`]: "", // Set the corresponding status to empty when file is uploaded
    }));

    // Update updatedFields with the new file data
    const updatedFieldName = name.replace("Card", "");
    setUpdatedFields((prevUpdatedFields) => ({
      ...prevUpdatedFields,
      [updatedFieldName]: file,
    }));
  };
  const getBorderStyle = (status) => {
    let borderStyle = {};
    if (status === "accepted") {
      borderStyle = {
        border: "2px solid green",
        boxShadow: "0px 0px 5px 2px rgba(0, 255, 0, 0.5)",
      };
    } else if (status === "rejected") {
      borderStyle = {
        border: "2px solid red",
        boxShadow: "0px 0px 5px 2px rgba(255, 0, 0, 0.5)",
      };
    } else if (status === "pending") {
      borderStyle = {
        border: "2px solid orange",
        boxShadow: "0px 0px 5px 2px rgba(255, 165, 0, 0.5)",
      };
    }
    return borderStyle;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const loginDataFromStorage = localStorage.getItem("loginData");
      const parsedLoginData = JSON.parse(loginDataFromStorage);
      const formDataToSend = new FormData(event.target);
      formDataToSend.append("reseller_email", email);
      formDataToSend.append("registration_id", parsedLoginData.registration_id);
      const data = Object.fromEntries(formDataToSend.entries());

      // console.log("sadasdas", formDataToSend);
      const response = await submitFormData(formDataToSend);

      if (response.status === 200) {
        window.alert(
          "User Registration Completed Successfully. It is under review."
        );
        window.close();
        // window.location.href = "/usermaincomponent";
      } else {
        const data = await response.json();
        window.alert(data.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };

  const openFilePopup = (fileUrl) => {
    // Open a new window to display the file
    window.open(fileUrl, "_blank", "width=800,height=600");
  };
  return (
    <Fragment>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="col-12 grid-margin">
        <div className="card">
          <div className="card-body">
            {/* <h4 className="mb-1">Reseller KYC Regiatration</h4> */}
            <br></br>
            <form className="form-sample" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Owner Full Name <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="ownerfullname"
                        className="form-control"
                        // style={getBorderStyle(formData.ownerNamestatus)}
                        placeholder="Owner Full Name"
                        value={formData.ownerfullname}
                        onChange={(e) =>
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            ownerfullname: e.target.value,
                          }))
                        }
                        required
                      />
                      <small className="text-danger">
                        Note:-Enter the name as per PAN card document
                      </small>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Owner Email<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className={`form-control ${
                          formData.owneremailid
                            ? emailError
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        // style={getBorderStyle(formData.ownerEmailstatus)}
                        placeholder="Owner Email"
                        name="owneremailid"
                        value={formData.owneremailid}
                        onChange={handleownerEmailChange}
                        required
                      />
                      {emailError && (
                        <small className="text-danger">{emailError}</small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Secondary Contact Name
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        name="secondcontactname"
                        // style={getBorderStyle(formData.contactNamestatus)}
                        placeholder="Secondary Contact Name"
                        value={formData.secondcontactname}
                        onChange={(e) =>
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            secondcontactname: e.target.value,
                          }))
                        }
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Account Contact Name<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="accountcontactname"
                        className="form-control"
                        // style={getBorderStyle(formData.accountContactNamestatus)}
                        placeholder="Account Contact Name"
                        value={formData.accountcontactname}
                        onChange={(e) =>
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            accountcontactname: e.target.value,
                          }))
                        }
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Secondary Contact Email
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="secondcontactemailid"
                        className={`form-control ${
                          formData.secondcontactemailid
                            ? emailError
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        // style={getBorderStyle(formData.contactEmailstatus)}
                        placeholder="Secondary Contact Email"
                        value={formData.secondcontactemailid}
                        onChange={handlesecondaryEmailChange}
                        required
                      />
                      {emailError && (
                        <small className="text-danger">{emailError}</small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Account Contact Email
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="accountcontactemailid"
                        className={`form-control ${
                          formData.accountcontactemailid
                            ? emailError
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        // style={getBorderStyle(formData.accountContactEmailstatus)}
                        placeholder="Account Contact Email"
                        value={formData.accountcontactemailid}
                        onChange={handleaccountEmailChange}
                        required
                      />
                      {emailError && (
                        <small className="text-danger">{emailError}</small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Secondary Contact Number
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="secondarycontactnumber"
                        className={`form-control ${
                          formData.secondarycontactnumber
                            ? secondaryNumberError
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        // style={getBorderStyle(formData.accountContactNamestatus)}
                        placeholder="Secondary Contact Number"
                        value={formData.secondarycontactnumber}
                        onChange={handleSecondaryContactNumberChange}
                        required
                      />
                      {secondaryNumberError && (
                        <small className="text-danger">
                          {secondaryNumberError}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Account Contact Number
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="accountcontactnumber"
                        className={`form-control ${
                          formData.accountcontactnumber
                            ? accountNumberError
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        // style={getBorderStyle(formData.accountContactEmailstatus)}
                        placeholder="Account Contact Number"
                        value={formData.accountcontactnumber}
                        onChange={handleAccountContactNumberChange}
                        required
                      />
                      {accountNumberError && (
                        <small className="text-danger">
                          {accountNumberError}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Udyog Adhaar
                    </label>
                    <div className="col-sm-2">
                      <input
                        type="radio"
                        name="udyogadharflag"
                        value="Yes"
                        onChange={(e) =>
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            udyogadharflag: e.target.value,
                          }))
                        }
                        checked={formData.udyogadharflag === "Yes"}
                      />
                      Yes{" "}
                      <input
                        type="radio"
                        name="udyogadharflag"
                        value="No"
                        onChange={(e) => {
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            udyogadharflag: e.target.value,
                            udyogadhaarnumber: null, // Set udyogadhaarnumber to null when "No" is selected
                          }));
                        }}
                        checked={formData.udyogadharflag === "No"}
                      />{" "}
                      No
                    </div>
                  </div>
                </div>

                {formData.udyogadharflag === "Yes" && (
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label text-left">
                        Udyog Adhaar Number
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          name="udyogadhaarnumber"
                          className={`form-control ${
                            formData.udyogadhaarnumber
                              ? aadhaarNumberError
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}
                          placeholder="Udyog Adhaar Number"
                          value={formData.udyogadhaarnumber}
                          onChange={handleAadhaarNumberChange}
                          required={formData.udyogadharflag === "Yes"}
                        />
                        {aadhaarNumberError && (
                          <small className="text-danger">
                            {aadhaarNumberError}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Pancard Number<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="pannumber"
                        className={`form-control ${
                          formData.pannumber
                            ? panNumberError
                              ? "is-invalid"
                              : "is-valid"
                            : // : loginDataStatusFromStorage.fields_names &&
                              //   loginDataStatusFromStorage.fields_names
                              //     .pannumber === "rejected"
                              // ? "border-danger"
                              // : loginDataStatusFromStorage.fields_names &&
                              //   loginDataStatusFromStorage.fields_names
                              //     .pannumber === "accepted"
                              // ? "border-success"
                              ""
                        }`}
                        style={getBorderStyle(
                          loginDataStatusFromStorage.pannumber
                        )}
                        placeholder="Pancard Number"
                        value={formData.pannumber}
                        onChange={handlePANNumberChange}
                        required
                      />
                      {panNumberError && (
                        <small className="text-danger">{panNumberError}</small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      GST Number
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="gstnumber"
                        className={`form-control ${
                          formData.gstnumber
                            ? gstNumberError
                              ? "is-invalid"
                              : "is-valid"
                            : // : loginDataStatusFromStorage.fields_names &&
                              //   loginDataStatusFromStorage.fields_names
                              //     .gstnumber === "rejected"
                              // ? "border-danger"
                              // : loginDataStatusFromStorage.fields_names &&
                              //   loginDataStatusFromStorage.fields_names
                              //     .gstnumber === "accepted"
                              // ? "border-success"
                              ""
                        }`}
                        style={getBorderStyle(
                          loginDataStatusFromStorage.gstnumber
                        )}
                        placeholder="GST Number"
                        value={formData.gstnumber}
                        onChange={handleGSTNumberChange}
                        required
                      />
                      {gstNumberError && (
                        <small className="text-danger">{gstNumberError}</small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Upload Pan Card<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      {loginDataStatusFromStorage &&
                      loginDataStatusFromStorage.panCard === "rejected" ? (
                        <input
                          type="file"
                          className="form-control"
                          name="panCard"
                          onChange={handleFileUpload}
                          accept=".pdf,.jpg,.jpeg,.png"
                          style={getBorderStyle(
                            loginDataStatusFromStorage.panCard
                          )}
                          required
                        />
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => openFilePopup(formData.pancardURL)}
                          title="View Document"
                          style={getBorderStyle(
                            loginDataStatusFromStorage.panCard
                          )}
                        >
                          View
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Upload GST Certificate
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      {loginDataStatusFromStorage &&
                      loginDataStatusFromStorage.gstcertificate ===
                        "rejected" ? (
                        <input
                          type="file"
                          className="form-control"
                          name="gstcertificate"
                          onChange={handleFileUpload}
                          accept=".pdf,.jpg,.jpeg,.png"
                          style={getBorderStyle(
                            loginDataStatusFromStorage.gstcertificate
                          )}
                          required
                        />
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() =>
                            openFilePopup(formData.gstcertificateURL)
                          }
                          title="View Document"
                          style={getBorderStyle(
                            loginDataStatusFromStorage.gstcertificate
                          )}
                        >
                          View
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      TAN Number<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="tannumber"
                        className={`form-control ${
                          formData.tannumber
                            ? tanNumberError
                              ? "is-invalid"
                              : "is-valid"
                            : // : loginDataStatusFromStorage.fields_names &&
                              //   loginDataStatusFromStorage.fields_names
                              //     .tannumber === "rejected"
                              // ? "border-danger"
                              // : loginDataStatusFromStorage.fields_names &&
                              //   loginDataStatusFromStorage.fields_names
                              //     .tannumber === "accepted"
                              // ? "border-success"
                              ""
                        }`}
                        style={getBorderStyle(
                          loginDataStatusFromStorage.tannumber
                        )}
                        placeholder="TAN Number"
                        value={formData.tannumber}
                        onChange={handleTANNumberChange}
                        required
                      />
                      {tanNumberError && (
                        <small className="text-danger">{tanNumberError}</small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Address <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        className="form-control"
                        rows="2"
                        name="pr_address"
                        value={formData.pr_address}
                        onChange={(e) =>
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            pr_address: e.target.value,
                          }))
                        }
                        required
                        style={{ resize: "vertical" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Pincode<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="pr_pincode"
                        className={`form-control ${
                          formData.pr_pincode
                            ? pincodeError
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        // style={getBorderStyle(formData.accountContactNamestatus)}
                        placeholder="Pincode"
                        value={formData.pr_pincode}
                        onChange={handlePincodeChange}
                        required
                      />
                      {pincodeError && (
                        <small className="text-danger">{pincodeError}</small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      City<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="pr_city"
                        className="form-control"
                        // style={getBorderStyle(formData.accountContactNamestatus)}
                        placeholder="City"
                        value={formData.pr_city}
                        onChange={(e) =>
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            pr_city: e.target.value,
                          }))
                        }
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      State<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="pr_state"
                        className="form-control"
                        // style={getBorderStyle(formData.accountContactNamestatus)}
                        placeholder="State"
                        value={formData.pr_state}
                        onChange={(e) =>
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            pr_state: e.target.value,
                          }))
                        }
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Contact Number<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="contactnumber"
                        className={`form-control ${
                          formData.contactnumber
                            ? contactNumberError
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        // style={getBorderStyle(formData.accountContactNamestatus)}
                        placeholder="Contact Number"
                        value={formData.contactnumber}
                        onChange={handleContactNumberChange}
                        required
                      />
                      {contactNumberError && (
                        <small className="text-danger">
                          {contactNumberError}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Company Type<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <select
                        value={formData.typeofcompany}
                        onChange={(e) =>
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            typeofcompany: e.target.value,
                          }))
                        }
                        style={{ width: "100%" }}
                        name="typeofcompany"
                        className="form-control"
                        required
                      >
                        <option value="">Select Company Type</option>
                        {companyTypeList.map((company) => (
                          <option key={company} value={company}>
                            {company}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Region<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <select
                        value={formData.region}
                        onChange={(e) =>
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            region: e.target.value,
                          }))
                        }
                        name="region"
                        className="form-control"
                        required
                      >
                        <option value="">Select Region</option>
                        <option value="East">East</option>
                        <option value="West">West</option>
                        <option value="North">North</option>
                        <option value="South">South</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label text-left">
                      IFSC Code<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-6 d-flex align-items-center">
                      <input
                        type="text"
                        name="ifsccode"
                        className={`form-control ${
                          formData.ifsccode
                            ? ifscError
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        placeholder="IFSC Code"
                        value={formData.ifsccode}
                        onChange={handleIFSCCodeChange}
                        required
                      />
                      <button
                        className="btn btn-primary ml-2"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault(); // Prevent default form submission
                          verifyIFSCCode(); // Call the verifyIFSCCode function
                        }}
                      >
                        Verify
                      </button>
                    </div>
                    {ifscError && (
                      <div className="col-sm-2">
                        <small className="text-danger">{ifscError}</small>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Bank Name<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="bankname"
                        className="form-control"
                        placeholder="Bank Name"
                        value={bankDetails.bankname}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Branch Name<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="branchname"
                        className="form-control"
                        placeholder="Branch Name"
                        value={bankDetails.branchname}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Bank Account Number<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="accountnumber"
                        className={`form-control ${
                          formData.accountnumber
                            ? bankAccountNumberError
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        // style={getBorderStyle(formData.accountContactNamestatus)}
                        placeholder="Bank Account Number"
                        value={formData.accountnumber}
                        onChange={handleBankAccountNumberChange}
                        required
                      />
                      {bankAccountNumberError && (
                        <small className="text-danger">
                          {bankAccountNumberError}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Account Holder Name<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="accountholdername"
                        className="form-control"
                        placeholder="Account Holder Name"
                        value={formData.accountholdername}
                        onChange={(e) =>
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            accountholdername: e.target.value,
                          }))
                        }
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-left">
                      Canceled Cheque<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      {loginDataStatusFromStorage &&
                      loginDataStatusFromStorage.cancelCheque === "rejected" ? (
                        <input
                          type="file"
                          className="form-control"
                          name="cancelCheque"
                          onChange={handleFileUpload}
                          accept=".pdf,.jpg,.jpeg,.png"
                          style={getBorderStyle(
                            loginDataStatusFromStorage.cancelCheque
                          )}
                          required
                        />
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() =>
                            openFilePopup(formData.cancelChequeURL)
                          }
                          title="View Document"
                          style={getBorderStyle(
                            loginDataStatusFromStorage.cancelCheque
                          )}
                        >
                          View
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <button className="btn btn-primary submit-btn" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UserRegistrationkysdetailsmodal;
