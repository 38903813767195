import React, { Fragment, useState, useEffect, useRef } from "react";
import { Modal, Button, Dropdown, Form } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  getAdminsideCustomerCaseslist,
  updateCasestatus,
  getRegisteredList,
  transferCase,
  add_deal_discount,
  getDiscount,
  addCaseInterval,
  getCaseIntervalHist,
  getRemainderHist,
  sendCaseRemainder,
} from "../../utils/api";

import {
  FaClock,
  FaSortUp,
  FaSortDown,
  FaSort,
  FaEnvelope,
  FaFilter,
} from "react-icons/fa";
import { CiImport, CiExport } from "react-icons/ci";
import { Collapse, Checkbox } from "antd"; // using antd for UI, can also be custom
import { casedetails_modules } from "./reportModulesjson";
import { exportReports } from "../../utils/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import { subYears } from "date-fns";
const { Panel } = Collapse;

const CaseLists = () => {
  const [cases, setCases] = useState([]);
  const [bulkReminderCases, setBulkReminderCases] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [showAddDiscountModal, setShowAddDiscountModal] = useState(false);
  const [showRemainderModal, setShowRemainderModal] = useState(false);
  const [selectedCase, setSelectedCase] = useState(null);
  const [selectedCaseID, setSelectedCaseID] = useState("");
  const [registeredResellers, setRegisteredResellers] = useState([]);
  const [selectedReseller, setSelectedReseller] = useState(null);
  const [selectedResellerCompany, setSelectedResellerCompany] = useState("");
  const [resellerDiscountDetails, setResellerDiscountDetails] = useState(null);
  const [dealDiscountAmount, setDealDiscountAmount] = useState([]);

  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [intervalValue, setIntervalValue] = useState("");
  const [intervalHistory, setIntervalHistory] = useState([]);
  const [remainderHistory, setRemainderHistory] = useState([]);
  const [showAddIntervalModal, setShowAddIntervalModal] = useState(false);
  const [showSendBulkReminderModal, setShowSendBulkReminderModal] =
    useState(false);
  const [loading, setLoading] = useState(false); // Loading state
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const admindetailsmodules = localStorage.getItem("admindetails");
  const role = JSON.parse(admindetailsmodules).role;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedSubOption, setSelectedSubOption] = useState(null);
  const admin_email = JSON.parse(admindetailsmodules).email;
  const [selectedMonthYear, setSelectedMonthYear] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [statusFilter, setStatusFilter] = useState(""); // For filtering by status
  const [showStatusFilterOptions, setShowStatusFilterOptions] = useState(false); // Toggle for status filter dropdown
  const statusFilterRef = useRef(null); // Ref for the status filter dropdown

  const handleSearchInputChange = (value) => {
    setSearchInput(value.toLowerCase());
    const filteredCases = cases.filter((item) => {
      if (item) {
        return (
          (item.domain_name &&
            item.domain_name.toLowerCase().includes(value)) ||
          (item.case_name && item.case_name.toLowerCase().includes(value))
        );
      }
      return false;
    });
    setCases(filteredCases);
  };

  const handleReset = async () => {
    setSearchInput("");
    await fetchCases();
  };
  const fetchRegisteredResellers = async () => {
    try {
      setLoading(true);
      const resellers = await getRegisteredList(admin_email);
      setRegisteredResellers(resellers);
    } catch (error) {
      console.error("Error fetching registered resellers:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchRegisteredResellers();
  }, []);
  const fetchCases = async () => {
    setLoading(true);
    try {
      const cases = await getAdminsideCustomerCaseslist(admin_email);
      // Filter cases based on the criteria
      const filteredCases = cases.filter(
        (item) =>
          item.case_time_expired === "Yes" &&
          item.case_transfer_remainder < 3 &&
          item.status === "Accepted" &&
          item.quotation_count === null
      );

      // Map to return only the required fields
      const selectedFields = filteredCases.map((item) => ({
        case_name: item.case_name,
        case_id: item.case_id,
        company_name: item.company_name,
        domain_name: item.domain_name,
        reminder_count: item.case_transfer_remainder,
      }));
      setBulkReminderCases(selectedFields);
      setCases(cases);
    } catch (error) {
      console.error("Error fetching cases:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCases();
  }, []);

  const handleAddCaseInterval = async () => {
    try {
      setLoading(true);
      if (!intervalValue) return;

      await addCaseInterval(intervalValue);
      fetchCaseIntervalHistory();
      setIntervalValue("");
      window.alert("Interval time Added Successfully");
    } catch (error) {
      window.alert("Error adding Interval:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSendCaseRemainder = async () => {
    try {
      setLoading(true);
      await sendCaseRemainder(selectedCaseID);
      fetchCaseRemainderHistory();
      fetchCases();
      window.alert("Case Reminder Sent on Reseller EmailID");
      setShowRemainderModal(false);
    } catch (error) {
      window.alert("Error sending the case reminder:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSendCaseBulkRemainder = async () => {
    try {
      setLoading(true); // Optional: Show a loading spinner if needed.

      // Initialize a status object to track the result for each case
      const statusUpdates = {};

      // Loop through the selected case IDs and send reminders one by one
      for (let caseId of selectedRows) {
        try {
          const response = await sendCaseRemainder(caseId); // API call for each case

          // If the API returns 200, mark the status as 'Sent'
          if (response.status === 200) {
            statusUpdates[caseId] = "Sent";
          } else {
            statusUpdates[caseId] = "Failed"; // Any other status mark as 'Failed'
          }
        } catch (error) {
          statusUpdates[caseId] = "Failed"; // If error occurs during the API call
        }
      }

      // After all reminders are sent, update the bulkReminderCases with the new statuses
      const updatedCases = bulkReminderCases.map((caseItem) => ({
        ...caseItem,
        reminder_status: statusUpdates[caseItem.case_id] || "Pending", // Default to 'Pending' if not processed
      }));

      // Update the state with the updated case statuses
      setBulkReminderCases(updatedCases);

      alert("Bulk reminders sent!");
    } catch (error) {
      console.error("Error sending bulk reminders:", error);
      alert("An error occurred while sending reminders.");
    } finally {
      setLoading(false); // Hide the loading spinner
    }
  };

  const fetchCaseIntervalHistory = async () => {
    try {
      setLoading(true);
      const intervalhistory = await getCaseIntervalHist();
      setIntervalHistory(intervalhistory);
    } catch (error) {
      window.alert("Error fetching interval history:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCaseRemainderHistory = async (caseid) => {
    try {
      setLoading(true);
      const remainderhistory = await getRemainderHist(caseid);
      setRemainderHistory(remainderhistory);
    } catch (error) {
      window.alert("Error fetching case reminder history:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSort = (column) => {
    let sortedList = [...cases];
    if (sortColumn === column) {
      sortedList.reverse(); // Toggle the sorting order
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      sortedList.sort((a, b) => {
        if (a[column] < b[column]) return -1;
        if (a[column] > b[column]) return 1;
        return 0;
      });
      setSortColumn(column);
      setSortOrder("asc");
    }
    setCases(sortedList);
  };
  const handleAccept = async (item) => {
    try {
      setLoading(true);
      const reason = null;
      const response = await updateCasestatus(
        item.case_id,
        "Accepted",
        item.reseller_email,
        item.domain_name,
        reason
      );

      if (response.status === 200) {
        const data = await response.json();
        window.alert(data.message);
        // Refresh the cases list after updating status
        await fetchCases();
      }
    } catch (error) {
      console.error("Error updating case status:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleReject = async (item) => {
    try {
      setLoading(true);
      const reason = window.prompt("Please provide a reason for rejection:");
      if (reason) {
        const response = await updateCasestatus(
          item.case_id,
          "Rejected",
          item.reseller_email,
          item.domain_name,
          reason // Pass the reason to the API call
        );
        if (response.status === 200) {
          const data = await response.json();
          window.alert(data.message);
          // Refresh the cases list after updating status
          await fetchCases();
        }
      } else {
        // If the user cancels or doesn't provide a reason, show a message
        window.alert("Rejection reason is required.");
      }
    } catch (error) {
      console.error("Error updating case status:", error);
    } finally {
      setLoading(false);
    }
  };
  const thStyles = {
    position: "sticky",
    top: 0,
    backgroundColor: "#f1f1f1",
    zIndex: 100,
  };
  const handleTransferCase = async () => {
    setLoading(true);
    if (selectedCase && selectedReseller) {
      try {
        const oldResellerId = selectedCase.reseller_id;
        const newResellerId = selectedReseller.reseller_id;
        const caseId = selectedCase.case_id;

        const response = await transferCase(
          oldResellerId,
          newResellerId,
          caseId
        );

        if (response.status === 200) {
          const data = await response.json();
          window.alert(data.message);
          // Refresh the cases list after transferring the case
          await fetchCases();
        } else {
          window.alert("Failed to transfer the case.");
        }
      } catch (error) {
        console.error("Error transferring case:", error);
        window.alert("Failed to transfer the case. Please try again later.");
      } finally {
        setLoading(false);
      }
    } else {
      window.alert("Please select a case and a reseller to transfer the case.");
    }
    setShowTransferModal(false);
  };
  const handleResellerChange = (event) => {
    const resellerId = event.target.value;
    const reseller = registeredResellers.find(
      (reseller) => reseller.reseller_id === resellerId
    );
    setSelectedReseller(reseller);
    setSelectedResellerCompany(reseller.company_name || "");
  };
  const renderActionButtons = (item) => {
    const { status } = item;
    if (status.toLowerCase() === "pending") {
      return (
        <Fragment>
          <Button
            variant="success"
            onClick={() => handleAccept(item)}
            title="Accept the Case"
          >
            ✔
          </Button>{" "}
          <Button
            variant="danger"
            onClick={() => handleReject(item)}
            title="Reject the Case"
          >
            ✘
          </Button>
        </Fragment>
      );
    } else if (status.toLowerCase() === "accepted") {
      return (
        <Button
          variant="danger"
          onClick={() => handleReject(item)}
          title="Reject the Case"
        >
          ✘
        </Button>
      );
    } else if (status.toLowerCase() === "rejected") {
      return (
        <Button
          variant="success"
          onClick={() => handleAccept(item)}
          title="Accept the Case"
        >
          ✔
        </Button>
      );
    }
    return null;
  };
  const handleView = (caseId) => {
    const selected = cases.find((c) => c.case_id === caseId);
    setSelectedCase(selected);
    setShowModal(true);
  };

  const renderActionOptions = (user) => {
    const {
      status,
      case_transfer_remainder,
      case_time_expired,
      quotation_flag,
    } = user;

    const shouldShowSendRemainder =
      case_time_expired === "Yes" &&
      quotation_flag === "No" &&
      case_transfer_remainder < 3 &&
      (role === "SuperAdmin" || role === "Admin");

    // Updated condition to also include case_transfer_remainder > 3
    const shouldShowTransferCase =
      case_time_expired === "Yes" &&
      quotation_flag === "No" &&
      case_transfer_remainder >= 3 &&
      (role === "SuperAdmin" || role === "Admin");

    return (
      <div className="d-flex justify-content-center">
        <Dropdown>
          <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
            <BsThreeDotsVertical />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleView(user.case_id)}>
              View Case Details
            </Dropdown.Item>

            {/* Conditions for Transfer Case and Send Reminder options */}
            {status !== "pending" && status !== "rejected" && (
              <>
                {shouldShowSendRemainder && (
                  <Dropdown.Item onClick={() => handleReminderModal(user)}>
                    Send Reminder
                  </Dropdown.Item>
                )}

                {shouldShowTransferCase && (
                  <Dropdown.Item onClick={() => handleTransferModal(user)}>
                    Transfer Case
                  </Dropdown.Item>
                )}
              </>
            )}

            <Dropdown.Item onClick={() => handleGiveExtraDiscountModal(user)}>
              Add Extra Discount
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  const handleTransferModal = (user) => {
    setSelectedCase(user);
    setShowTransferModal(true);
  };
  const handleCaseIntervalModal = () => {
    fetchCaseIntervalHistory();
    setShowAddIntervalModal(true);
  };
  const handleBulkReminderModal = () => {
    fetchCases();
    setShowSendBulkReminderModal(true);
  };
  const handleReminderModal = (user) => {
    setSelectedCaseID(user.case_id);
    fetchCaseRemainderHistory(user.case_id);
    setShowRemainderModal(true);
  };
  const handleGiveExtraDiscountModal = async (user) => {
    setLoading(true);
    setSelectedCase(user);
    try {
      const response = await getDiscount(
        // user.case_id,
        // user.plan_type,
        // user.plan_duration,
        user.reseller_email
      );
      if (response.status === 200) {
        const data = await response.json();
        if (data.data === null) {
          setResellerDiscountDetails("None");
          setShowAddDiscountModal(true);
        } else {
          setResellerDiscountDetails(data.data.discount_in);
          setShowAddDiscountModal(true);
        }
      } else {
        window.alert("Failed to get discount.");
      }
    } catch (error) {
      window.alert("Failed to get the discount details");
    } finally {
      setLoading(false);
    }
  };

  const getStatusStyle = (status) => {
    let color = "";
    let fontWeight = "normal";

    switch (status.toLowerCase()) {
      case "pending":
        color = "orange";
        fontWeight = "bold";
        break;
      case "accepted":
        color = "green";
        fontWeight = "bold";
        break;
      case "rejected":
        color = "red";
        fontWeight = "bold";
        break;
      case "yes":
        color = "green";
        fontWeight = "bold";
        break;
      case "no":
        color = "red";
        fontWeight = "bold";
        break;
      case "failed":
        color = "red";
        fontWeight = "bold";
        break;
      case "sent":
        color = "green";
        fontWeight = "bold";
        break;
      default:
        break;
    }

    return { color, fontWeight };
  };
  const handleAddDiscount = async () => {
    try {
      setLoading(true);
      const response = await add_deal_discount(
        selectedCase.reseller_id,
        selectedCase.case_id,
        dealDiscountAmount
      );

      if (response.status === 200) {
        const data = await response.json();
        window.alert(data.message);
        // Refresh the cases list after adding discount
        await fetchCases();
        setShowAddDiscountModal(false);
        setDealDiscountAmount(""); // Clear deal discount amount
      } else {
        window.alert("Failed to add discount.");
      }
    } catch (error) {
      console.error("Error adding discount:", error);
      window.alert("Failed to add discount. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  // Handle selecting a single row
  const handleRowSelect = (caseId) => {
    if (selectedRows.includes(caseId)) {
      setSelectedRows(selectedRows.filter((id) => id !== caseId)); // Deselect row
    } else {
      setSelectedRows([...selectedRows, caseId]); // Select row
    }
  };

  // Handle selecting all rows
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]); // Deselect all
    } else {
      const allCaseIds = bulkReminderCases.map((caseItem) => caseItem.case_id); // Select all case IDs
      setSelectedRows(allCaseIds);
    }
    setSelectAll(!selectAll); // Toggle select all state
  };
  const handleCloseExportModal = () => {
    setIsModalVisible(false);
    // Reset all fields to their initial state
    setSelectedModule("");
    setSelectedOption("");
    setSelectedSubOption("");
  };
  const toggleModal = () => {
    console.log("toogle");
    setIsModalVisible(true);
  };

  const handleModuleChange = (moduleName) => {
    setSelectedModule(moduleName);
    setSelectedOption(null); // Reset selected option when module changes
    setSelectedSubOption(null); // Reset selected sub-option
  };

  const handleOptionChange = (optionName) => {
    setSelectedOption(optionName);
    setSelectedSubOption(null); // Reset selected sub-option when option changes
  };

  const handleSubOptionChange = (subOptionName) => {
    setSelectedSubOption((prev) =>
      prev === subOptionName ? null : subOptionName
    );
  };
  const handleSubmit = async () => {
    if (!selectedOption) {
      alert("Please select at least one report type.");
      return;
    }
    console.log("Selected Module:", selectedModule);
    console.log("Selected Option:", selectedOption);
    console.log("Selected Sub-Option:", selectedSubOption);
    try {
      setLoading(true);
      const response = await exportReports(
        selectedModule,
        selectedOption ? selectedOption : null,
        selectedSubOption ? selectedSubOption : null,
        selectedMonthYear ? selectedMonthYear : null,
        selectedYear ? selectedYear : null,
        admin_email
      );
      if (response.status === 200) {
        const responsedata = await response.json();
        if (responsedata.flag_value === 1) {
          window.alert(responsedata.message);
          setSelectedModule(null);
          setSelectedOption(null);
          setSelectedSubOption(null);
          setSelectedMonthYear(null);
          setSelectedYear(null);
          setIsModalVisible(false);
        } else if (responsedata.flag_value === 2) {
          window.alert(responsedata.message);
        }
      } else {
        console.error("Failed to generate report:", response.statusText);
      }
    } catch (error) {
      console.error("Error generate report", error);
    } finally {
      setLoading(false);
    }
  };
  // Handle the filter by status
  const handleStatusFilter = (status) => {
    setStatusFilter(status);
    const filteredList = cases.filter((user) => user.status === status);
    setCases(filteredList);
    setShowStatusFilterOptions(false); // Hide dropdown after selection
  };
  return (
    <Fragment>
      <style>
        {`
          .sortable-header {
            cursor: pointer;
            position: relative;
          }

          .sort-icon {
            margin-left: 5px;
            color: #6c757d; /* Default color */
            transition: color 0.2s ease;
          }

          .sortable-header:hover .sort-icon {
            color: #007bff; /* Change this to your preferred hover color */
          }

          .table-responsive {
            max-height: 500px;
            overflow-y: auto;
          }
            .status-filter-dropdown {
            position: absolute;
            background-color: white;
            border: 1px solid #ccc;
            padding: 10px;
            z-index: 150;
          }
          .status-filter-dropdown p {
            color: #000; /* Default text color */
            transition: color 0.2s ease-in-out;
          }

          .status-filter-dropdown p:hover {
            color: #007bff; /* Change this to your preferred hover color */
            cursor: pointer; /* Add pointer cursor on hover */
          }
          .status-filter-item {
            padding: 5px;
            cursor: pointer;
            transition: background-color 0.2s ease, color 0.2s ease;
          }


          .filter-icon {
            cursor: pointer;
          }
          .date-filter-container {
            display: flex;
            align-items: center;
            margin-left: 700px;
            gap: 10px;
            margin-bottom: 20px;
            position: relative;
            z-index: 1000; /* Ensure date picker is above other elements */
          }

          .date-filter-container .form-control {
            width: 150px;
          }

          .date-filter-container button {
            margin-left: 17px;
          }
          .text-danger {
            color: #dc3545;
          }
        `}
      </style>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="mb-1">Registered Cases</h4>
            <br></br>
            <div className="search-container mb-3">
              <input
                type="text"
                placeholder="Search"
                value={searchInput}
                onChange={(e) => handleSearchInputChange(e.target.value)}
                style={{ float: "left" }}
              />
              <button
                className="btn btn-primary ml-2"
                onClick={handleReset}
                style={{ float: "left" }}
              >
                Reset
              </button>
              {role === "Admin" ||
                (role === "SuperAdmin" && (
                  <button
                    className="btn btn-primary ml-2"
                    style={{ float: "right" }}
                    onClick={() => handleCaseIntervalModal()} // Placeholder for interval time modal or action
                    title="Set Case Expiry Interval"
                  >
                    <FaClock
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </button>
                ))}
              {role === "Admin" ||
                (role === "SuperAdmin" && (
                  <button
                    className="btn btn-primary ml-2"
                    style={{ float: "right" }}
                    onClick={() => handleBulkReminderModal()} // Placeholder for interval time modal or action
                    title="Send Bulk Reminder"
                  >
                    <FaEnvelope
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </button>
                ))}
              <button
                className="btn btn-primary ml-2"
                style={{ float: "right" }}
                onClick={toggleModal}
                title="Export Reseller Data"
              >
                <CiExport size={15} />
              </button>

              <br></br>
            </div>
            <div
              className="table-responsive"
              style={{ maxHeight: "500px", overflowY: "auto" }}
            >
              <table className="table table-hover sticky-header">
                <thead>
                  <tr>
                    {/* <th style={thStyles}>Case ID</th> */}
                    <th
                      style={thStyles}
                      className="sortable-header"
                      onClick={() => handleSort("customer_domainname")}
                    >
                      Domain
                      {sortColumn === "customer_domainname" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" />
                      )}
                    </th>
                    <th style={thStyles}>Reg. Date</th>
                    <th style={thStyles} className="sortable-header">
                      Status
                      <FaFilter
                        className="filter-icon"
                        onClick={() =>
                          setShowStatusFilterOptions(!showStatusFilterOptions)
                        }
                      />
                      {/* Status Filter Dropdown */}
                      {showStatusFilterOptions && (
                        <div
                          ref={statusFilterRef}
                          className="status-filter-dropdown"
                        >
                          <p
                            className="status-filter-item"
                            onClick={() => handleStatusFilter("Accepted")}
                          >
                            Accepted
                          </p>
                          <p
                            className="status-filter-item"
                            onClick={() => handleStatusFilter("pending")}
                          >
                            Pending
                          </p>
                          <p
                            className="status-filter-item"
                            onClick={() => handleStatusFilter("Rejected")}
                          >
                            Rejected
                          </p>
                        </div>
                      )}
                    </th>
                    <th
                      style={thStyles}
                      className="sortable-header"
                      onClick={() => handleSort("company_name")}
                    >
                      Reseller Company Name
                      {sortColumn === "company_name" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" />
                      )}
                    </th>
                    <th style={thStyles}>Quotation Count</th>
                    <th style={thStyles}>Case Action</th>
                    <th style={thStyles}>Case Time Expired</th>
                    <th style={thStyles}>Case Reminder Count</th>
                    <th style={thStyles}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {cases.map((item) => (
                    <tr key={item.case_id}>
                      {/* <td>{item.case_id}</td> */}
                      <td>{item.domain_name}</td>
                      <td>
                        {new Date(item.created_date).toLocaleDateString()}
                      </td>
                      <td style={getStatusStyle(item.status)}>{item.status}</td>
                      <td>{item.company_name}</td>
                      <td>{item.quotation_count}</td>
                      <td>{renderActionButtons(item)}</td>
                      <td style={getStatusStyle(item.case_time_expired)}>
                        {item.case_time_expired}
                      </td>
                      <td>
                        {item.case_transfer_remainder === 0 &&
                        item.quotation_flag === "Yes" ? (
                          <span style={{ color: "orange", fontWeight: "bold" }}>
                            N/A
                          </span>
                        ) : (
                          item.case_transfer_remainder
                        )}
                      </td>

                      <td>{renderActionOptions(item)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {selectedCase && (
        <Modal show={showModal} onHide={() => setShowModal(false)} size="xl">
          <Modal.Header>
            <Modal.Title>
              <strong>Case Details - </strong>
              {selectedCase.case_name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="form-sample">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Customer Name</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        value={selectedCase.customer_name || ""}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Email ID</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        value={selectedCase.customer_emailid || ""}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Reseller Name</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="reseller_id"
                        value={selectedCase.reseller_name || ""}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Status</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="status"
                        value={selectedCase.status || ""}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Additional Information</strong>
                    </label>
                    <div className="col-sm-8">
                      <textarea
                        className="form-control"
                        id="additional_information"
                        value={selectedCase.additional_information || ""}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Customer ID</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="cust_id"
                        value={selectedCase.cust_id || ""}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Created Date</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="created_date"
                        value={selectedCase.created_date || ""}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Domain Name</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="domain_name"
                        value={selectedCase.domain_name || ""}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Plan Type</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="plan_type"
                        value={selectedCase.plan_type || ""}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Plan Duration</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="plan_duration"
                        value={selectedCase.plan_duration || ""}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <h5>Product Details</h5>
            <div style={{ maxHeight: "200px", overflowY: "auto" }}>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>User Count</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedCase.prod_details &&
                  selectedCase.prod_details.length > 0 ? (
                    selectedCase.prod_details.map((prodDetail, index) => (
                      <tr key={index}>
                        <td>{Object.keys(prodDetail)[0]}</td>
                        <td>{Object.values(prodDetail)[0]}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2">No product details available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <Modal
        show={showTransferModal}
        onHide={() => setShowTransferModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Transfer Case</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label htmlFor="resellerDropdown">Select Reseller:</label>
          <select
            id="resellerDropdown"
            onChange={handleResellerChange}
            className="form-control"
          >
            <option value="">Select...</option>
            {registeredResellers.map((reseller) => (
              <option key={reseller.reseller_id} value={reseller.reseller_id}>
                {`${reseller.reseller_id} - ${reseller.reseller_name}`}
              </option>
            ))}
          </select>
          <div className="form-group mt-3">
            <label htmlFor="companyName">Company Name:</label>
            <input
              type="text"
              id="companyName"
              className="form-control"
              value={selectedResellerCompany}
              readOnly
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button> */}
          <Button variant="primary" onClick={handleTransferCase}>
            Transfer Case
          </Button>
        </Modal.Footer>
      </Modal>
      {resellerDiscountDetails && (
        <Modal
          show={showAddDiscountModal}
          onHide={() => setShowAddDiscountModal(false)}
          size="xl"
        >
          <Modal.Header>
            <Modal.Title>Add Extra Discount for Deal</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Reseller Current Discount(%)</strong>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="reseller_discount"
                      value={resellerDiscountDetails}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    <strong>Enter Deal Discount(%) </strong>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="deal_discount"
                      value={dealDiscountAmount}
                      onChange={(e) => setDealDiscountAmount(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShowAddDiscountModal(false);
                setDealDiscountAmount(""); // Clear deal discount amount
              }}
            >
              Close
            </Button>
            <Button variant="primary" onClick={() => handleAddDiscount()}>
              Add Discount
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <Modal
        show={showAddIntervalModal}
        onHide={() => {
          setShowAddIntervalModal(false);
          setIntervalValue(""); // Clear discount value
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Interval Time</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="discountValue">
              <Form.Label>Interval Value</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter interval value"
                value={intervalValue}
                onChange={(e) => setIntervalValue(e.target.value)}
              />
            </Form.Group>
            <br></br>
            <Button variant="primary" onClick={handleAddCaseInterval}>
              Add Interval
            </Button>
          </Form>
          <hr />
          <h5>Interval History</h5>
          <div style={{ maxHeight: "150px", overflowY: "auto" }}>
            <table className="table">
              <thead>
                <tr>
                  <th>Interval</th>
                  <th>Updated On</th>
                </tr>
              </thead>
              <tbody>
                {intervalHistory.map((interval, index) => (
                  <tr key={index}>
                    <td>{interval.interval_value}</td>
                    <td>{interval.created_date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showRemainderModal}
        onHide={() => {
          setShowRemainderModal(false);
          setSelectedCase("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Case Reminder Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <br></br>
            <Button variant="primary" onClick={handleSendCaseRemainder}>
              Send Reminder
            </Button>
          </Form>
          <hr />
          <h5>Case Reminder History</h5>
          <div style={{ maxHeight: "150px", overflowY: "auto" }}>
            <table className="table">
              <thead>
                <tr>
                  <th>Reminder Date</th>
                </tr>
              </thead>
              <tbody>
                {remainderHistory.map((reminder, index) => (
                  <tr key={index}>
                    <td>{reminder.remainder_date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showSendBulkReminderModal}
        onHide={() => {
          setShowSendBulkReminderModal(false);
          setSelectedRows([]);
          setSelectAll(false);
        }}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Cases List For Bulk Reminder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div style={{ maxHeight: "200px", overflowY: "auto" }}>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />{" "}
                    </th>
                    <th>Case Name</th>
                    <th>Reseller Company Name</th>
                    <th>Domain Name</th>
                    {/* <th>Reminder Count</th> */}
                    <th>Reminder Status</th>
                  </tr>
                </thead>
                <tbody>
                  {bulkReminderCases.map((caseItem, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedRows.includes(caseItem.case_id)}
                          onChange={() => handleRowSelect(caseItem.case_id)}
                        />
                      </td>
                      <td>{caseItem.case_name}</td>
                      <td>{caseItem.company_name}</td>
                      <td>{caseItem.domain_name}</td>
                      {/* <td>{caseItem.reminder_count}</td> */}
                      <td
                        style={getStatusStyle(
                          caseItem.reminder_status || "Pending"
                        )}
                      >
                        {caseItem.reminder_status || "Pending"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <br />
            <Button
              variant="primary"
              onClick={handleSendCaseBulkRemainder}
              disabled={selectedRows.length === 0}
              style={{ float: "right" }}
            >
              Send Reminder
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        title="Export Data"
        onHide={handleCloseExportModal}
        show={isModalVisible}
      >
        <Modal.Header closeButton>
          <Modal.Title>Export Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Collapse>
            {casedetails_modules.map((module, moduleIndex) => (
              <Panel header={module.name} key={moduleIndex}>
                <Checkbox
                  checked={selectedModule === module.name}
                  onChange={() => handleModuleChange(module.name)}
                >
                  Select Report Type
                </Checkbox>
                {selectedModule === module.name && (
                  <div style={{ paddingLeft: "20px" }}>
                    {module.options.map((option, optionIndex) => (
                      <div key={optionIndex}>
                        <Checkbox
                          onChange={() => handleOptionChange(option.name)}
                          checked={selectedOption === option.name}
                        >
                          {option.name}
                        </Checkbox>
                        {selectedOption === option.name &&
                          option.subOptions && (
                            <div style={{ paddingLeft: "20px" }}>
                              {option.subOptions.map((subOption, subIndex) => (
                                <div key={subIndex}>
                                  <Checkbox
                                    onChange={() =>
                                      handleSubOptionChange(subOption)
                                    }
                                    checked={selectedSubOption === subOption}
                                  >
                                    {subOption}
                                  </Checkbox>
                                </div>
                              ))}
                            </div>
                          )}
                      </div>
                    ))}
                  </div>
                )}
                <br></br>
                {selectedOption === "Get Case Registered by Month" && (
                  <>
                    <h4 className="card-title">Select Month and Year</h4>
                    <DatePicker
                      selected={selectedMonthYear}
                      onChange={(date) => setSelectedMonthYear(date)}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                      locale="en-GB"
                      maxDate={new Date()}
                      placeholderText="Select Month and Year"
                      className="form-control"
                      popperPlacement="bottom"
                      popperModifiers={[
                        {
                          name: "preventOverflow",
                          options: {
                            boundary: "viewport",
                          },
                        },
                        {
                          name: "offset",
                          options: {
                            offset: [0, 10],
                          },
                        },
                      ]}
                      withPortal // Forces the calendar to open in a portal
                    />
                  </>
                )}

                {selectedOption === "Get Case Registered by Year" && (
                  <>
                    <h4 className="card-title">Select Year</h4>
                    <DatePicker
                      selected={selectedYear}
                      onChange={(date) => setSelectedYear(date)}
                      dateFormat="yyyy"
                      showYearPicker
                      locale="en-GB"
                      minDate={subYears(new Date(), 20)} // Adjust for minimum year range
                      maxDate={new Date()}
                      placeholderText="Select Year"
                      className="form-control"
                      popperPlacement="bottom"
                      popperModifiers={[
                        {
                          name: "preventOverflow",
                          options: {
                            boundary: "viewport",
                          },
                        },
                        {
                          name: "offset",
                          options: {
                            offset: [0, 10],
                          },
                        },
                      ]}
                      withPortal // Forces the calendar to open in a portal
                    />
                  </>
                )}
              </Panel>
            ))}
          </Collapse>
        </Modal.Body>
        <Modal.Footer>
          <Button key="submit" type="primary" onClick={handleSubmit}>
            Get Report
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default CaseLists;
