import React, { Fragment, useState } from "react";
import { add_department_module } from "../../utils/api";
const DepartmentModuleManagement = ({ handleClose }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSelectionChange = (event) => {
    setSelectedOption(event.target.value);
    setInputValue("");
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleAdd = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      if (selectedOption && inputValue) {
        const response = await add_department_module(
          selectedOption,
          inputValue
        );
        if (response.status === 200) {
          const data = await response.json();
          window.alert(data.message);
          // window.location.reload();
          handleClose();
        } else {
          const data = await response.json();
          window.alert(data.message);
        }
        setInputValue("");
      } else {
        window.alert("Please Select or enter any value");
      }
    } catch (error) {
      console.error("Error for adding values:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="mb-1">Department & Module & Role Management</h4>
            <br />
            <div>
              <select
                value={selectedOption}
                onChange={handleSelectionChange}
                className="form-control"
                style={{ width: "350px" }}
              >
                <option value="">Select an option</option>
                <option value="Department">Department</option>
                <option value="Module">Module</option>
                <option value="Role">Role</option>
              </select>
            </div>
            {selectedOption && (
              <div style={{ marginTop: "10px" }}>
                <input
                  type="text"
                  className="form-control"
                  value={inputValue}
                  onChange={handleInputChange}
                  placeholder={`Enter ${selectedOption} name`}
                  style={{ width: "200px" }}
                />
                <button
                  onClick={handleAdd}
                  style={{ marginLeft: "10px" }}
                  className="btn btn-primary mr-2"
                >
                  Add {selectedOption}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DepartmentModuleManagement;
