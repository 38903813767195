import React, { Fragment, useState, useEffect } from "react";
import {
  resellersignup,
  resellersendOTP,
  resellerverifyOTP,
} from "../../utils/api"; // Import the signup and sendOTP API functions

const UserSignupForm = () => {
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true); // State to track email validity
  const [password, setPassword] = useState("");
  const [cnfpassword, setConfirmPassword] = useState("");
  const [otpSent, setOtpSent] = useState(false); // State to manage OTP sent status
  const [otp, setOtp] = useState("");
  const [phnno, setPhnno] = useState("");
  const [phoneValid, setPhoneValid] = useState(true);
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false);
  const [otpVerificationResult, setOtpVerificationResult] = useState("");
  const [logo, setLogo] = useState(null); // State for managing logo file
  const [logoError, setLogoError] = useState(""); // State for managing logo file errors
  const [logoPlaceholder, setLogoPlaceholder] = useState("Choose file...");

  useEffect(() => {
    document.title = "Signup Page"; // Set the document title
  }, []);

  const validatePhoneNumber = (phone) => {
    const pattern = /^\d{10}$/;
    return pattern.test(phone);
  };

  const handlePhoneChange = (e) => {
    const phoneValue = e.target.value;
    setPhnno(phoneValue);
    setPhoneValid(validatePhoneNumber(phoneValue));

    const phoneInput = e.target;
    if (validatePhoneNumber(phoneValue)) {
      phoneInput.style.borderColor = "green";
      setPhoneError("");
    } else {
      phoneInput.style.borderColor = "red";
      setPhoneError("Please enter a valid phone number.");
    }
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setEmailValid(validateEmail(emailValue));

    const emailInput = e.target;
    if (validateEmail(emailValue)) {
      emailInput.style.borderColor = "green";
      setEmailError("");
    } else {
      emailInput.style.borderColor = "red";
      setEmailError("Please enter a valid email address.");
    }
  };

  const validateEmail = (email) => {
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png", "image/svg+xml"];
    const maxSize = 5 * 1024 * 1024; // 5 MB

    if (file) {
      if (!allowedTypes.includes(file.type)) {
        setLogoError("Invalid file type. Only JPEG, PNG, and SVG are allowed.");
        setLogo(null);
        setLogoPlaceholder("Choose file...");
        return;
      }
      if (file.size > maxSize) {
        setLogoError("File size exceeds the maximum limit of 5 MB.");
        setLogo(null);
        setLogoPlaceholder("Choose file...");
        return;
      }
      setLogo(file);
      setLogoPlaceholder(file.name);
      setLogoError("");
    }
  };

  const handleSendOTP = async () => {
    try {
      setLoading(true);
      if (!email) {
        window.alert("Please insert your email ID.");
        return;
      }
      const response = await resellersendOTP(email); // Pass email value to sendOTP function
      if (response.status === 200) {
        setOtpSent(true); // Update the state to indicate OTP has been sent
        setOtpVerificationResult("success-sent-otp");
        window.alert("OTP Sent on EmailID");
      } else {
        const data = await response.json(); // Extract error message from response body
        window.alert(data.message); // Display error message in alert
      }
    } catch (error) {
      window.alert("Failed to send OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOTP = async () => {
    try {
      setLoading(true);
      const response = await resellerverifyOTP(email, otp); // Pass email value to verifyOTP function
      if (response.status === 200) {
        setOtpVerificationResult("Success"); // Update state to indicate successful OTP verification
        window.alert("OTP verified successfully!");
      } else {
        const data = await response.json(); // Extract error message from response body
        window.alert(data.message); // Display error message in alert
      }
    } catch (error) {
      window.alert("Failed to verify OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!password || !cnfpassword) {
      window.alert("Please enter password and confirm password.");
      return;
    }

    if (password !== cnfpassword) {
      window.alert("Passwords do not match.");
      return;
    }

    setLoading(true);
    try {
      // const formData = new FormData();
      // formData.append("name", name);
      // formData.append("company", company);
      // formData.append("email", email);
      // formData.append("password", password);
      // formData.append("cnfpassword", cnfpassword);
      // formData.append("phnno", phnno);
      // if (logo) {
      //   formData.append("logo", logo); // Append logo to form data if available
      // }

      const response = await resellersignup(
        name,
        company,
        email,
        password,
        cnfpassword,
        phnno,
        logo
      );
      if (response.status === 200) {
        window.alert(
          "User registered successfully. Now wait for Activate Your Account"
        );
        window.location.href = "/userlogin";
      } else {
        const data = await response.json(); // Extract error message from response body
        window.alert(data.message); // Display error message in alert
      }
      setName("");
      setCompany("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setOtp("");
      setPhnno("");
      setLogo(null); // Reset logo file state
      setLogoPlaceholder("Choose file...");
      setLoading(false);
    } catch (error) {
      window.alert("Signup failed. Please try again.");
      setLoading(false);
    }
  };

  const handleLoginLinkClick = () => {
    window.location.href = "/userlogin"; // Redirect to the login page
  };

  return (
    <Fragment>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth register-bg-1 theme-one">
            <div className="row w-100">
              <div className="col-lg-4 mx-auto text-center">
                <img
                  src="../../assets/images/Shiviom-Logo.png"
                  className="img"
                  alt=""
                />
                <h1 className="mb-5">Reseller Account Register</h1>
                <div className="auto-form-wrapper">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="mdi mdi-check-circle-outline"></i>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Company Name"
                          value={company}
                          onChange={(e) => setCompany(e.target.value)}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="mdi mdi-check-circle-outline"></i>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="input-group">
                        <input
                          type="tel"
                          className={`form-control ${
                            phoneValid ? "valid-input" : "invalid-input"
                          }`}
                          placeholder="Phone Number"
                          value={phnno}
                          onChange={handlePhoneChange}
                          required
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="mdi mdi-check-circle-outline"></i>
                          </span>
                        </div>
                      </div>
                      {phoneError && (
                        <div className="text-danger">{phoneError}</div>
                      )}
                    </div>

                    <div className="form-group">
                      <label className="custom-file-upload">
                        <input
                          type="file"
                          className="form-control"
                          accept=".jpeg,.png,.svg"
                          onChange={handleLogoChange}
                        />
                        <span>
                          Here you have to upload your company Logo(optional)
                        </span>
                      </label>
                      {logoError && (
                        <div className="text-danger">{logoError}</div>
                      )}
                    </div>

                    <div className="form-group row">
                      <div className="col-md-8">
                        <input
                          type="email"
                          className={`form-control ${
                            emailValid ? "" : "is-invalid"
                          }`}
                          placeholder="Email ID"
                          value={email}
                          onChange={handleEmailChange}
                          title="Enter a valid email address"
                          required
                        />
                        {!emailValid && (
                          <div className="invalid-feedback">
                            Please enter a valid email address.
                          </div>
                        )}
                      </div>
                      <div className="col-md-4">
                        {!otpSent ? (
                          <button
                            className="btn btn-primary submit-btn btn-block"
                            type="button"
                            onClick={handleSendOTP}
                          >
                            Send OTP
                          </button>
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter OTP"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                          />
                        )}
                      </div>
                    </div>

                    {otpSent && (
                      <div className="mt-2">
                        <button
                          className="btn btn-success mr-2"
                          type="button"
                          onClick={handleVerifyOTP}
                        >
                          Verify OTP
                        </button>
                        <button
                          className="btn btn-info"
                          type="button"
                          onClick={handleSendOTP}
                        >
                          Resend OTP
                        </button>
                      </div>
                    )}
                    <br></br>
                    {otpVerificationResult === "Success" && (
                      <div>
                        <div className="form-group">
                          <div className="input-group">
                            <input
                              type="password"
                              className="form-control"
                              placeholder="Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">
                                <i className="mdi mdi-check-circle-outline"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="input-group">
                            <input
                              type="password"
                              className="form-control"
                              placeholder="Confirm Password"
                              value={cnfpassword}
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">
                                <i className="mdi mdi-check-circle-outline"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="form-group">
                      <button
                        className="btn btn-primary submit-btn btn-block"
                        type="submit"
                      >
                        Register
                      </button>
                    </div>
                    <div className="text-block text-center my-3">
                      <span className="text-small font-weight-semibold">
                        Already have an account?
                      </span>
                      <a
                        href="/userLogin"
                        className="text-black text-small"
                        onClick={handleLoginLinkClick}
                      >
                        Login
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <script src="../../assets/vendors/js/vendor.bundle.base.js"></script>
      <script src="../../assets/vendors/js/vendor.bundle.addons.js"></script>
      <script src="../../assets/js/shared/off-canvas.js"></script>
      <script src="../../assets/js/shared/misc.js"></script>
      <script
        src="../../assets/js/shared/jquery.cookie.js"
        type="text/javascript"
      ></script>
    </Fragment>
  );
};
export default UserSignupForm;
