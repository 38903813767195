import React, { Fragment, useState } from "react";
import { ListGroup, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import { subYears } from "date-fns";
import enGB from "date-fns/locale/en-GB";
import { exportReports } from "../../utils/api";

registerLocale("en-GB", enGB);

const AdminReportsMaster = () => {
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedSubOption, setSelectedSubOption] = useState(null);
  const [selectedMonthYear, setSelectedMonthYear] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const admindetailsmodules = localStorage.getItem("admindetails");
  const admin_email = JSON.parse(admindetailsmodules).email;
  const [loading, setLoading] = useState(false);

  // Mock data for modules, options, and sub-options
  const modules = [
    {
      name: "Reseller Details",
      options: [
        {
          name: "Get Resellers by Account Status",
          subOptions: [
            "Active Resellers",
            "Rejected Resellers",
            "Pending Approval",
          ],
        },
        { name: "Get Resellers with Current Partner Discount" },
        {
          name: "Get KYC details of Resellers",
          subOptions: [
            "Pending Resellers",
            "Approved Resellers",
            "Rejected Resellers",
            "Permanently Rejected Resellers",
          ],
        },
      ],
    },
    {
      name: "Sales Details",
      options: [
        { name: "Get Sales by Month" },
        { name: "Get Sales by Year" },
        { name: "Sales by Resellers" },
        {
          name: "Sales by Products",
          subOptions: [
            "Jumpcloud",
            "Spin_ai",
            "Atera",
            "Patronum",
            "Florbs IO",
            "1password",
            "Scale Dino",
            "bow.chat",
          ],
        },
      ],
    },
    {
      name: "Case Details",
      options: [
        {
          name: "Get Cases report by Status",
          subOptions: ["Open", "Closed", "Pending", "Rejected"],
        },
        { name: "Get Case Registered by Month" },
        { name: "Get Case Registered by Year" },
      ],
    },
    {
      name: "Quotation Details",
      options: [
        {
          name: "Get Quotation by Status",
          subOptions: ["Pending Quotation", "Completed Quotation"],
        },
      ],
    },
    // {
    //   name: "Order Details",
    //   options: [
    //     { name: "Get Order Details by Month" },
    //     { name: "Get Order Details by Year" },
    //   ],
    // },
  ];

  // Function to handle module selection
  const handleModuleClick = (module) => {
    setSelectedModule(module);
    setSelectedOption(null); // Reset the option selection when the module changes
    setSelectedSubOption(null);
  };

  // Function to handle option selection
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setSelectedSubOption(null); // Reset the sub-option selection when a new option is selected
    setSelectedMonthYear(null); // Reset month-year selection when a new option is selected
    setSelectedYear(null); // Reset year selection
  };

  // Function to handle report generation
  const handleGenerateReport = async () => {
    if (!selectedOption) {
      alert("Please select at least one report type.");
      return;
    }

    try {
      setLoading(true);
      const response = await exportReports(
        selectedModule?.name,
        selectedOption.name,
        selectedSubOption ? selectedSubOption : null,
        selectedMonthYear,
        selectedYear,
        admin_email
      );
      if (response.status === 200) {
        const responsedata = await response.json();
        if (responsedata.flag_value === 1) {
          window.alert(responsedata.message);
          setSelectedModule(null);
          setSelectedOption(null);
          setSelectedSubOption(null);
          setSelectedMonthYear(null);
          setSelectedYear(null);
        } else if (responsedata.flag_value === 2) {
          window.alert(responsedata.message);
        }
      } else {
        console.error("Failed to generate report:", response.statusText);
      }
    } catch (error) {
      console.error("Error generate report", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="container-fluid">
        <div className="row">
          {/* Left-side panel for module types */}
          <div className="col-md-3">
            <div
              className="card"
              style={{ maxHeight: "400px", overflowY: "auto" }}
            >
              <div className="card-body">
                <h4 className="card-title">Modules</h4>
                <ListGroup>
                  {modules.map((module, index) => (
                    <ListGroup.Item
                      key={index}
                      action
                      onClick={() => handleModuleClick(module)}
                      className={
                        selectedModule?.name === module.name ? "active" : ""
                      }
                    >
                      {module.name}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </div>
            </div>
          </div>

          {/* Middle panel for options based on selected module */}
          <div className="col-md-4">
            <div
              className="card"
              style={{ maxHeight: "400px", overflowY: "auto" }}
            >
              <div className="card-body">
                {selectedModule ? (
                  <>
                    <h4 className="card-title">
                      Options for {selectedModule.name}
                    </h4>
                    <ListGroup>
                      {selectedModule.options.map((option, index) => (
                        <ListGroup.Item
                          key={index}
                          action
                          onClick={() => handleOptionClick(option)}
                          className={
                            selectedOption?.name === option.name ? "active" : ""
                          }
                        >
                          {option.name}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </>
                ) : (
                  <h4 className="card-title">Select a module to see options</h4>
                )}
              </div>
            </div>
          </div>

          {selectedOption && (
            <div className="col-md-5">
              <div
                className="card"
                style={{ maxHeight: "400px", overflowY: "auto" }}
              >
                <div className="card-body">
                  <h4 className="card-title">{selectedOption.name}</h4>

                  {selectedOption.subOptions ? (
                    <ListGroup>
                      {selectedOption.subOptions.map((subOption, index) => (
                        <ListGroup.Item
                          key={index}
                          action
                          onClick={() => setSelectedSubOption(subOption)} // Correctly set the selected sub-option
                          className={
                            selectedSubOption === subOption ? "active" : ""
                          }
                        >
                          {subOption}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  ) : selectedOption.name === "Get Sales by Month" ||
                    selectedOption.name === "Get Sales by Year" ||
                    selectedOption.name === "Get Case Registered by Year" ||
                    selectedOption.name === "Get Case Registered by Month" ||
                    selectedOption.name === "Get Order Details by Month" ||
                    selectedOption.name === "Get Order Details by Year" ? (
                    // Don't show message if the selected option is "View Sales by Month" or "View Sales by Year"
                    <p></p> // Optionally, you can keep this as a placeholder or remove it
                  ) : (
                    <p>No sub-options available for this selection.</p> // Message if there are no sub-options and it's not one of the two specified
                  )}
                  {(selectedOption.name === "Get Sales by Month" ||
                    selectedOption.name === "Get Case Registered by Month" ||
                    selectedOption.name === "Get Order Details by Month") && (
                    <>
                      <h4 className="card-title">Select Month and Year</h4>
                      <DatePicker
                        selected={selectedMonthYear}
                        onChange={(date) => setSelectedMonthYear(date)}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        locale="en-GB"
                        maxDate={new Date()}
                        placeholderText="Select Month and Year"
                        className="form-control"
                        popperPlacement="bottom"
                        popperModifiers={[
                          {
                            name: "preventOverflow",
                            options: {
                              boundary: "viewport",
                            },
                          },
                          {
                            name: "offset",
                            options: {
                              offset: [0, 10],
                            },
                          },
                        ]}
                        withPortal // Forces the calendar to open in a portal
                      />
                    </>
                  )}

                  {(selectedOption.name === "Get Sales by Year" ||
                    selectedOption.name === "Get Case Registered by Year" ||
                    selectedOption.name === "Get Order Details by Year") && (
                    <>
                      <h4 className="card-title">Select Year</h4>
                      <DatePicker
                        selected={selectedYear}
                        onChange={(date) => setSelectedYear(date)}
                        dateFormat="yyyy"
                        showYearPicker
                        locale="en-GB"
                        minDate={subYears(new Date(), 20)} // Adjust for minimum year range
                        maxDate={new Date()}
                        placeholderText="Select Year"
                        className="form-control"
                        popperPlacement="bottom"
                        popperModifiers={[
                          {
                            name: "preventOverflow",
                            options: {
                              boundary: "viewport",
                            },
                          },
                          {
                            name: "offset",
                            options: {
                              offset: [0, 10],
                            },
                          },
                        ]}
                        withPortal // Forces the calendar to open in a portal
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Button to generate report */}
      <div className="text-center mt-4">
        <Button variant="primary" onClick={handleGenerateReport}>
          Generate Report
        </Button>
      </div>
    </Fragment>
  );
};

export default AdminReportsMaster;
