import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import UserRegistrationkysdetailsmodalverify from "../pages/userkycregistrationmodalverification";

const UserRegistrationFormModalverify = ({ show, onHide }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const modalBodyStyle = {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "180%",
    pointerEvents: "auto",
    backgroundColor: "#fff",
    backgroundClip: "padding-box",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    borderRadius: "0.3rem",
    outline: "0",
    padding: "20px", // Added padding to match your CSS
  };

  const handleClose = () => {
    setIsSubmitted(false);
    onHide();
  };

  return (
    <Modal
      show={show && !isSubmitted}
      onHide={handleClose}
      style={{ width: "101%" }}
    >
      <Modal.Header
        closeButton
        style={{ backgroundColor: "#007bff", color: "#fff", width: "180%" }}
      >
        <Modal.Title>Reseller KYC Registration</Modal.Title>
      </Modal.Header>
      <Modal.Body style={modalBodyStyle}>
        <UserRegistrationkysdetailsmodalverify
          onSuccess={() => setIsSubmitted(true)}
        />{" "}
        {/* Pass a callback to handle successful submission */}
      </Modal.Body>
    </Modal>
  );
};

export default UserRegistrationFormModalverify;
