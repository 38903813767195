import React, { Fragment, useState, useEffect } from "react";
import { getListAssignProduct, view_assigned_products } from "../../utils/api";
import { useCookies } from "react-cookie";

const Modal = ({
  isOpen,
  onClose,
  assignedProducts,
  userRegId,
  onAddOrUpdateClick,
}) => {
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedProducts([]);
    }
  }, [isOpen]);

  

  return (
    isOpen && (
      <div
        className="modal-popup"
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 1000,
          backgroundColor: "#fff",
          borderRadius: "5px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
          padding: "50px",
          maxWidth: "80%",
          overflowY: "auto",
        }}
      >
        <span
          className="close-btn"
          onClick={onClose}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer",
          }}
        >
          &times;
        </span>
        <div className="modal-content">
          <h4 className="admin_all_list_container_h2">Assigned Products</h4>

          <ul>
            {assignedProducts.map((product, index) => (
              <li key={index}>
                <div className="checkbox-container">
                  <label>{product}</label>
                </div>
              </li>
            ))}
          </ul>
          <button
            className="btn btn-info"
            onClick={() => onAddOrUpdateClick(userRegId)}
          >
            Add/Update
          </button>
        </div>
      </div>
    )
  );
};

const AssignProductlist = () => {
  const [registrations, setRegistrations] = useState([]);

  const [cookies, setCookie] = useCookies([
    "products",
    "user_id",
    "custID",
    "domainName",
  ]);
  const email = cookies.email;
  const [error, setError] = useState("");
  const [showButtonRegId, setShowButtonRegId] = useState(null);
  const [assignedProducts, setAssignedProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentUserRegId, setCurrentUserRegId] = useState(null);
  const [currentCustId, setCurrentCustId] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [initialRegistrations, setInitialRegistrations] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getListAssignProduct();
        if (data && data.length > 0) {
          setRegistrations(data);
          setInitialRegistrations(data);
        } else {
          console.error("No data returned from getListAssignProduct");
        }
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);

  const handleViewAssignedProducts = async (userId, custID) => {
    try {
      const response = await view_assigned_products(userId, custID);
      setAssignedProducts(response.products);
      setCurrentUserRegId(userId);
      setCurrentCustId(custID);
      setCookie("products", response.products, { path: "/" });
      setCookie("user_id", userId, { path: "/" });
      setCookie("custID", custID, { path: "/" });
      setShowModal(true);
    } catch (error) {
      console.error("Error fetching assigned products:", error);
    }
  };

  const handleButtonClick = async (
    userId,
    productAssignStatus,
    custID,
    domainName
  ) => {
    try {
      if (productAssignStatus === "Yes") {
        setShowButtonRegId(userId);
      } else {
        setCookie("user_id", userId, { path: "/" });
        setCookie("custID", custID, { path: "/" });
        setCookie("domainName", domainName, { path: "/" });
        setCookie("email", email, { path: "/" });
        window.location.href = "/assignproducts";
      }
    } catch (error) {
      console.error("Login error:", error);
      setError("Login failed. Please try again.");
    }
  };

  const handleSearch = (value) => {
    setSearchInput(value);
    const filteredRegistrations = initialRegistrations.filter((registration) =>
      registration.companyname.toLowerCase().includes(value.toLowerCase())
    );
    setRegistrations(filteredRegistrations);
  };

  const handleReset = () => {
    setRegistrations(initialRegistrations);
    setSearchInput("");
  };

  const thStyles = {
    position: "sticky",
    top: 0,
    backgroundColor: "#f1f1f1",
    zIndex: 100,
  };

  return (
    <Fragment>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="mb-1">Assign Products</h4>
            <br></br>
            <div className="search-container mb-3">
              <input
                type="text"
                placeholder="Search by company name..."
                value={searchInput}
                onChange={(e) => handleSearch(e.target.value)}
              />
              <button className="btn btn-primary ml-2" onClick={handleReset}>
                Reset
              </button>
            </div>
            <div
              className="table-responsive"
              style={{ maxHeight: "500px", overflowY: "auto" }}
            >
              <table className="table table-hover sticky-header">
                <thead>
                  <tr>
                    <th style={thStyles}>Reseller ID</th>
                    <th style={thStyles}>Domain Name</th>
                    <th style={thStyles}>Reseller Company Name</th>
                    <th style={thStyles}>Registered</th>
                    <th style={thStyles}>Approved</th>
                    <th style={thStyles}>Product Assigned</th>
                    <th style={thStyles}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {registrations.length > 0 &&
                    registrations.map((registration) => (
                      <tr key={registration.user_id} className="hover-row">
                        <td>{registration.user_id}</td>
                        <td>{registration.domain_name}</td>
                        <td>{registration.companyname}</td>
                        <td
                          style={{
                            color:
                              registration.registered === "Yes"
                                ? "green"
                                : registration.registered === "No"
                                ? "red"
                                : "black",
                          }}
                        >
                          <b>{registration.registered}</b>
                        </td>
                        <td
                          className={`status-${registration.approved}`}
                          style={{
                            color:
                              registration.approved === "Yes"
                                ? "green"
                                : registration.approved === "No"
                                ? "red"
                                : "black",
                          }}
                        >
                          <b>{registration.approved}</b>
                        </td>
                        <td
                          className={`status-${registration.product_assign_status}`}
                          style={{
                            color:
                              registration.product_assign_status === "Yes"
                                ? "green"
                                : registration.product_assign_status === "No"
                                ? "red"
                                : "black",
                          }}
                        >
                          <b>{registration.product_assign_status}</b>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {registration.approved === "Yes" && (
                            <div className="button-container">
                              <button
                                className="btn btn-info mr-2"
                                onClick={() =>
                                  handleButtonClick(
                                    registration.user_id,
                                    registration.product_assign_status,
                                    registration.cust_id,
                                    registration.domain_name
                                  )
                                }
                                title="Assign Product"
                              >
                                Assign
                              </button>
                              {registration.product_assign_status === "Yes" && (
                                <button
                                  className="btn btn-info"
                                  onClick={() =>
                                    handleViewAssignedProducts(
                                      registration.user_id,
                                      registration.cust_id,
                                      registration.domain_name
                                    )
                                  }
                                  title="View Assigned Products"
                                >
                                  Show
                                </button>
                              )}
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        assignedProducts={assignedProducts}
        userRegId={currentUserRegId}
        onAddOrUpdateClick={() => {
          window.location.href = `/assignproducts`;
        }}
      />
    </Fragment>
  );
};

export default AssignProductlist;
