import React, { useEffect, useState } from "react";
import { Bar, Pie, Doughnut, Line } from "react-chartjs-2"; // Import Pie chart\
import {
  Chart,
  CategoryScale, // Required for x-axis
  LinearScale, // Required for y-axis (linear)
  BarElement, // Required for Bar charts
  ArcElement, // Required for Pie and Doughnut charts
  PointElement, // Required for Line charts
  LineElement, // Required for Line charts
  Title,
  Tooltip,
  Legend,
} from "chart.js"; // Import necessary components from Chart.js
import {
  getTotalCaseCount,
  getTotalCustomerCount,
  getTotalSalesByProducts,
  getProductsWiseCustomer,
  getYearlyAquiredCustomer,
  getTotalResellerProfit,
  getTotalResellerSalesRevenue,
  getMonthYearwiseSalesRevenue,
  getTopSalesCustomers,
  fetchDetailsDashboardData,
} from "../../utils/api"; // Import the API function
import ChartDataLabels from "chartjs-plugin-datalabels"; // Import the plugin
import { Modal, Button } from "react-bootstrap";
// Register the required components for charts
Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
  // ChartDataLabels // Register the datalabels plugin if you're using it
);

const ResellerDashboard = () => {
  const [totalCustomerCount, settotalCustomerCount] = useState(null);
  const [totalCaseCount, settotalCaseCount] = useState(null);
  const [totalSalesByProducts, settotalSalesByProducts] = useState(null);
  const [totalProductwiseCustomer, settotalProductwiseCustomer] =
    useState(null);
  const [totalYearlyCustomer, settotalYearlyCustomer] = useState(null);
  const [totalResellerProfit, settotalResellerProfit] = useState(null);
  const [totalResellerSalesRevenue, settotalResellerSalesRevenue] =
    useState(null);
  const [yearMonthwiseSalesRevenue, setYearMonthwiseSalesRevenue] =
    useState(null);
  const [topSalesCustomers, setTopSalesCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCustomerCount, setLoadingCustomerCount] = useState(false);
  const [loadingCaseCount, setLoadingCaseCount] = useState(false);
  const [loadingSalesByProducts, setLoadingSalesByProducts] = useState(false);
  const [loadingProductwiseCustomer, setLoadingProductwiseCustomer] =
    useState(false);
  const [loadingYearlyCustomer, setLoadingYearlyCustomer] = useState(false);
  const [loadingSalesRevenue, setLoadingSalesRevenue] = useState(false);
  const [label, setLabel] = useState(false);

  const [yearwiseCustomerDetails, setYearwiseCustomerDetails] = useState([]);
  const [showYearlCustomerModal, setShowYearlCustomerModal] = useState(false);

  const [customerCasesDetails, setCustomerCasesDetails] = useState([]);
  const [showCustomerCaseModal, setShowCustomerCaseModal] = useState(false);

  const [productwiseCustomerAquired, setProductwiseCustomerAquired] = useState(
    []
  );
  const [showProductCustomerAquiredModal, setShowProductCustomerAquiredModal] =
    useState(false);

  const [yearlySales, setYearlySales] = useState([]);
  const [showYearlySalesModal, setShowYearlySalesModal] = useState(false);

  const [custProdSalesDetails, setCustProdSalesDetails] = useState([]);
  const [showCustProdSalesDetailsModal, setShowCustProdSalesDetailsModal] =
    useState(false);

  const loginDataFromStorage = localStorage.getItem("userDetails");
  const parsedLoginData = JSON.parse(loginDataFromStorage);

  const handleChartClick = async (chartName, e) => {
    const chart = e.chart;
    const elements = chart.getElementsAtEventForMode(
      e,
      "nearest",
      { intersect: true },
      false
    );

    if (elements.length) {
      const index = elements[0].index;
      const datasetIndex = elements[0].datasetIndex;
      const value = chart.data.datasets[datasetIndex].data[index];
      const label = chart.data.labels[index];

      // alert(`Chart: ${chartName}, Label: ${label}, Value: ${value}`);

      // API calls based on chart name
      switch (chartName) {
        case "Yearly Customer Acquisitions":
          // Fetching dashboard data based on chart name
          try {
            const responseData = await fetchDetailsDashboardData(
              parsedLoginData.reseller_email,
              chartName,
              label
            );
            setLabel(label);
            setYearwiseCustomerDetails(responseData.yearly_customer_details);

            setShowYearlCustomerModal(true);
            // Process the response data as needed
          } catch (error) {
            console.error("Error fetching dashboard data:", error);
          }
          break;
        case "Total Cases Count":
          // Fetching dashboard data based on chart name
          try {
            const responseData = await fetchDetailsDashboardData(
              parsedLoginData.reseller_email,
              chartName,
              label
            );
            setLabel(label);
            setCustomerCasesDetails(responseData.case_statuswise_details);

            setShowCustomerCaseModal(true);
            // Process the response data as needed
          } catch (error) {
            console.error("Error fetching dashboard data:", error);
          }
          break;
        case "Productwise Customer Aquired":
          // Fetching dashboard data based on chart name
          try {
            const responseData = await fetchDetailsDashboardData(
              parsedLoginData.reseller_email,
              chartName,
              label
            );
            setLabel(label);
            setProductwiseCustomerAquired(
              responseData.productwise_customers_details
            );

            setShowProductCustomerAquiredModal(true);
            // Process the response data as needed
          } catch (error) {
            console.error("Error fetching dashboard data:", error);
          }
          break;
        case "Yearwise Sales Revenue":
          // Fetching dashboard data based on chart name
          try {
            const responseData = await fetchDetailsDashboardData(
              parsedLoginData.reseller_email,
              chartName,
              label
            );
            setLabel(label);
            setYearlySales(responseData.yearwise_sales_revenue_custdetails);

            setShowYearlySalesModal(true);
            // Process the response data as needed
          } catch (error) {
            console.error("Error fetching dashboard data:", error);
          }
          break;
        // Add more cases for other charts
        default:
          console.log("No specific API call for this chart.");
          break;
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingCustomerCount(true);
        const response = await getTotalCustomerCount(
          parsedLoginData.reseller_email
        ); // Call the API function
        settotalCustomerCount(response.data); // Set the API data in state
        setLoadingCustomerCount(false);
      } catch (error) {
        setLoadingCustomerCount(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [parsedLoginData.reseller_email]); // Ensure correct dependencies

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingCaseCount(true);
        const response = await getTotalCaseCount(
          parsedLoginData.reseller_email
        ); // Call the API function
        console.log(response);
        settotalCaseCount(response.data); // Set the API data in state
        setLoadingCaseCount(false);
      } catch (error) {
        setLoadingCaseCount(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [parsedLoginData.reseller_email]); // Ensure correct dependencies

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingSalesByProducts(true);
        const response = await getTotalSalesByProducts(
          parsedLoginData.reseller_email
        ); // Call the API function
        settotalSalesByProducts(response.data); // Set the API data in state
        setLoadingSalesByProducts(false);
      } catch (error) {
        setLoadingSalesByProducts(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [parsedLoginData.reseller_email]); // Ensure correct dependencies

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingYearlyCustomer(true);
        const response = await getYearlyAquiredCustomer(
          parsedLoginData.reseller_email
        ); // Call the API function
        settotalYearlyCustomer(response.data); // Set the API data in state
        setLoadingYearlyCustomer(false);
      } catch (error) {
        setLoadingYearlyCustomer(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [parsedLoginData.reseller_email]); // Ensure correct dependencies

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingProductwiseCustomer(true);
        const response = await getProductsWiseCustomer(
          parsedLoginData.reseller_email
        ); // Call the API function
        settotalProductwiseCustomer(response.data); // Set the API data in state
        setLoadingProductwiseCustomer(false);
      } catch (error) {
        setLoadingProductwiseCustomer(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [parsedLoginData.reseller_email]); // Ensure correct dependencies

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getTotalResellerProfit(
          parsedLoginData.reseller_email
        ); // Call the API function
        settotalResellerProfit(response.data); // Set the API data in state
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [parsedLoginData.reseller_email]); // Ensure correct dependencies

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getTotalResellerSalesRevenue(
          parsedLoginData.reseller_email
        ); // Call the API function
        settotalResellerSalesRevenue(response.data); // Set the API data in state
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [parsedLoginData.reseller_email]); // Ensure correct dependencies

  useEffect(() => {
    const fetchSalesRevenue = async () => {
      try {
        setLoadingSalesRevenue(true);
        const response = await getMonthYearwiseSalesRevenue(
          parsedLoginData.reseller_email
        );
        setYearMonthwiseSalesRevenue(response.data.yearmonthwise_sales_revenue); // Set the API data in state
        setLoadingSalesRevenue(false);
      } catch (error) {
        setLoadingSalesRevenue(false);
        console.error("Error fetching sales revenue data:", error);
      }
    };

    fetchSalesRevenue();
  }, [parsedLoginData.reseller_email]);

  useEffect(() => {
    const fetchTopSalesCustomers = async () => {
      try {
        const response = await getTopSalesCustomers(
          parsedLoginData.reseller_email
        );
        setTopSalesCustomers(response.data.top_sales_customer); // Set the API data in state
      } catch (error) {
        console.error("Error fetching top sales customers:", error);
      }
    };

    fetchTopSalesCustomers();
  }, [parsedLoginData.reseller_email]);

  const loader = (
    <div className="loader-container">
      <div className="loader">
        <div className="tick"></div>
      </div>
    </div>
  );

  // Inline styles for card and layout
  const cardStyle = {
    border: "1px solid #ddd",
    borderRadius: "10px",
    padding: "20px",
    margin: "10px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#f9f9f9",
    textAlign: "center",
    width: "250px",
    transition: "transform 0.2s, box-shadow 0.2s",
    cursor: "pointer",
    justifyContent: "center",
    marginTop: "-20px",
  };

  const cardTitleStyle = {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "10px",
    color: "#49b8ea", // Bootstrap primary color
  };

  const cardTextStyle = {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#333",
    margin: "0",
  };

  const dashboardContainerStyle = {
    display: "flex", // Flexbox layout to align items in a row
    justifyContent: "space-around", // Optional: Space between the cards
    alignItems: "center", // Align items vertically in the center
    flexWrap: "wrap", // Wrap to the next line if necessary
    margin: "20px 0", // Add some margin to the container
  };

  const chartsContainerStyle = {
    display: "flex", // Display charts side by side
    justifyContent: "space-between", // Maintain space between the charts
    width: "150%", // Ensure full width for charts
    maxWidth: "1900px", // Set a max width for the charts container
    marginTop: "-10px", // Add some space at the top
    marginLeft: "-250px", // Shift the charts container 20px to the left
  };

  const chartStyle = {
    width: "48%", // Ensure each chart takes up 48% of the container width
    height: "420px",
    border: "1px solid #ddd", // Add border to the chart
    borderRadius: "10px", // Rounded corners for the chart
    padding: "15px", // Padding inside the chart container
    backgroundColor: "#fff", // Background color for better visibility
  };

  const handleMouseEnter = (e) => {
    e.currentTarget.style.transform = "scale(1.05)";
    e.currentTarget.style.boxShadow = "0 8px 16px rgba(0, 0, 0, 0.2)";
  };

  const handleMouseLeave = (e) => {
    e.currentTarget.style.transform = "scale(1)";
    e.currentTarget.style.boxShadow = "0 4px 12px rgba(0, 0, 0, 0.1)";
  };

  // Prepare data for the first Bar chart (Customer Acquisitions)
  const customerChartData = {
    labels: totalYearlyCustomer?.yearwise_customer_acquired
      ? totalYearlyCustomer.yearwise_customer_acquired.map(
          (entry) => Object.keys(entry)[0]
        )
      : [], // Get the years or an empty array if null
    datasets: [
      {
        label: "Customers Acquired",
        data: totalYearlyCustomer?.yearwise_customer_acquired
          ? totalYearlyCustomer.yearwise_customer_acquired.map(
              (entry) => Object.values(entry)[0]
            )
          : [], // Get the number of customers or an empty array if null
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };
  // Prepare data for the Pie chart (Total Cases Count)
  const totalCasesData = {
    labels: ["Open", "Closed", "Pending", "Rejected"],
    datasets: [
      {
        data: [
          totalCaseCount?.total_cases_count[0]?.open_count || 0,
          totalCaseCount?.total_cases_count[1]?.closed_count || 0,
          totalCaseCount?.total_cases_count[2]?.pending_count || 0,
          totalCaseCount?.total_cases_count[3]?.rejected_count || 0,
        ],
        backgroundColor: [
          "rgba(75, 192, 192, 0.6)", // Open cases color
          "rgba(153, 102, 255, 0.6)", // Closed cases color
          "rgba(255, 159, 64, 0.6)", // Pending cases color
          "rgba(255, 99, 132, 0.6)", // Rejected cases color
        ],
        hoverBackgroundColor: [
          "rgba(75, 192, 192, 0.8)",
          "rgba(153, 102, 255, 0.8)",
          "rgba(255, 159, 64, 0.8)",
          "rgba(255, 99, 132, 0.8)",
        ],
      },
    ],
  };
  // Prepare data for the Doughnut chart (Total Sales by Products)
  const salesByProductsLabels = totalSalesByProducts?.total_sales_byproducts
    ? totalSalesByProducts.total_sales_byproducts.map(
        (entry) => Object.keys(entry)[0]
      )
    : []; // Get product names or an empty array if null

  const salesByProductsData = {
    labels: salesByProductsLabels,
    datasets: [
      {
        data: totalSalesByProducts?.total_sales_byproducts
          ? totalSalesByProducts.total_sales_byproducts.map(
              (entry) => Object.values(entry)[0]
            )
          : [], // Get sales figures or an empty array if null
        backgroundColor: salesByProductsLabels.map(() => {
          // Generate random colors for each product dynamically
          return `hsl(${Math.random() * 360}, 70%, 50%)`; // Random color using HSL
        }),
        hoverBackgroundColor: salesByProductsLabels.map(() => {
          return `hsl(${Math.random() * 360}, 70%, 60%)`; // Slightly lighter color on hover
        }),
      },
    ],
  };
  // Prepare data for the Horizontal Bar chart (Productwise Customers Acquired)
  const productwiseCustomerData = {
    labels: totalProductwiseCustomer?.productwise_cust_acquired
      ? totalProductwiseCustomer.productwise_cust_acquired.map(
          (entry) => Object.keys(entry)[0]
        )
      : [], // Get product names or an empty array if null
    datasets: [
      {
        label: "Customers Acquired",
        data: totalProductwiseCustomer?.productwise_cust_acquired
          ? totalProductwiseCustomer.productwise_cust_acquired.map(
              (entry) => Object.values(entry)[0]
            )
          : [], // Get customer counts or an empty array if null
        backgroundColor: "rgba(153, 102, 255, 0.6)", // Set color for the horizontal bars
        borderColor: "rgba(153, 102, 255, 1)", // Border color
        borderWidth: 1,
      },
    ],
  };

  // Prepare data for the Line chart
  const salesRevenueChartData = {
    labels: yearMonthwiseSalesRevenue
      ? yearMonthwiseSalesRevenue.map((entry) => Object.keys(entry)[0])
      : [], // Get the years
    datasets: [
      {
        label: "Yearly Sales Revenue",
        data: yearMonthwiseSalesRevenue
          ? yearMonthwiseSalesRevenue.map((entry) => Object.values(entry)[0])
          : [], // Get the revenue
        backgroundColor: "rgba(75, 192, 192, 0.4)", // Fill color
        borderColor: "rgba(75, 192, 192, 1)", // Line color
        borderWidth: 2,
        fill: false,
      },
    ],
  };

  const pieChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "right",
        align: "start",
        labels: {
          boxWidth: 12,
          padding: 20,
        },
      },
      // Custom plugin to display values on the chart
      datalabels: {
        formatter: (value, context) => {
          const dataset = context.chart.data.datasets[context.datasetIndex];
          const dataIndex = context.dataIndex;

          // Only show the label if the value is greater than 0
          return value > 0 ? `${value}` : "";
        },
        color: "#000000", // Change the color of the labels if needed
      },
    },
  };

  const doughnutChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "right", // Set legend position to the right
        align: "start", // Align legend items vertically
        labels: {
          boxWidth: 12, // Adjust box width for better visibility
          padding: 20, // Adjust padding between legend items
        },
      },
    },
  };
  const barChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    indexAxis: "y", // Make the bar chart horizontal
    plugins: {
      legend: {
        display: true,
        position: "top",
        align: "start",
        labels: {
          boxWidth: 12,
          padding: 20,
        },
      },
    },
  };
  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  const lineChartOptions = {
    plugins: {
      datalabels: {
        display: true,
        color: "#000",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return "₹" + value.toLocaleString(); // Format as currency
          },
        },
      },
    },
  };
  const thStyles = {
    position: "sticky",
    top: 0,
    backgroundColor: "#f1f1f1",
    zIndex: 100,
  };
  const handleCloseCustomerDetailsModal = () => {
    setShowYearlCustomerModal(false);
    setLabel("");
  };
  const handleCloseCustomerCaseDetailsModal = () => {
    setShowCustomerCaseModal(false);
    setLabel("");
  };
  const handleCloseProductwiseCustAquiredDetailsModal = () => {
    setShowProductCustomerAquiredModal(false);
    setLabel("");
  };
  const handleCloseYearlySalesModal = () => {
    setShowYearlySalesModal(false);
    setLabel("");
  };
  const handleCloseCustProdSalesModal = () => {
    setShowCustProdSalesDetailsModal(false);
    setLabel("");
  };
  const handleView = async (custID) => {
    // Fetching dashboard data based on chart name
    try {
      const responseData = await fetchDetailsDashboardData(
        parsedLoginData.reseller_email,
        "Top 10 Customers",
        custID
      );
      setLabel(label);
      setCustProdSalesDetails(responseData.top10_cust_prod_details);

      setShowCustProdSalesDetailsModal(true);
      // Process the response data as needed
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
    setShowCustProdSalesDetailsModal(true);
  };
  return (
    <div>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <h4 className="text-center mb-4">Reseller Dashboard</h4>

          <div style={dashboardContainerStyle}>
            {/* Card for Total Customers */}
            {totalCustomerCount && (
              <div
                style={cardStyle}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <h5 style={cardTitleStyle}>Total Customers</h5>
                <p style={cardTextStyle}>
                  {totalCustomerCount.total_customers
                    ? totalCustomerCount.total_customers
                    : 0}
                </p>
              </div>
            )}
            {/* Card for Total Customers */}
            {totalResellerProfit && (
              <div
                style={cardStyle}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <h5 style={cardTitleStyle}>Total Profit</h5>
                <p style={cardTextStyle}>
                  {totalResellerProfit.total_profit
                    ? new Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).format(totalResellerProfit.total_profit)
                    : "₹0"}
                </p>
              </div>
            )}
            {totalResellerSalesRevenue && (
              <div
                style={cardStyle}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <h5 style={cardTitleStyle}>Total Sales Revenue</h5>
                <p style={cardTextStyle}>
                  {totalResellerSalesRevenue.total_salesrevenue
                    ? new Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).format(totalResellerSalesRevenue.total_salesrevenue)
                    : "₹0"}
                </p>
              </div>
            )}
          </div>

          {/* Charts Container */}
          <div style={chartsContainerStyle}>
            {/* First Chart: Customer Acquisitions */}
            <div style={chartStyle}>
              <h5 style={{ textAlign: "center", marginBottom: "10px" }}>
                Yearly Customer Acquisitions
              </h5>
              {loadingYearlyCustomer ? (
                loader
              ) : (
                <Bar
                  data={customerChartData}
                  // options={chartOptions}
                  options={{
                    ...chartOptions,
                    onClick: (e) =>
                      handleChartClick("Yearly Customer Acquisitions", e),
                    // Add other options here
                  }}
                />
              )}
            </div>

            {/* Second Chart: Total Cases Count (Pie Chart) */}
            <div style={chartStyle}>
              <h5
                style={{
                  textAlign: "center",
                  marginBottom: "4px",
                  marginTop: "-8px",
                }}
              >
                Total Cases Count
              </h5>
              {loadingCaseCount ? (
                loader
              ) : (
                <Pie
                  data={totalCasesData}
                  // options={pieChartOptions}
                  options={{
                    ...pieChartOptions,
                    onClick: (e) => handleChartClick("Total Cases Count", e),
                    // Add other options here
                  }}
                  plugins={[ChartDataLabels]}
                />
              )}
            </div>
          </div>
          <br></br>
          <div style={chartsContainerStyle}>
            <div style={{ ...chartStyle }}>
              <h5
                style={{
                  textAlign: "center",
                  marginBottom: "4px",
                  marginTop: "-8px",
                }}
              >
                Total Sales by Products
              </h5>
              {loadingSalesByProducts ? (
                loader
              ) : (
                <Doughnut
                  data={salesByProductsData}
                  // options={doughnutChartOptions}
                  options={{
                    ...doughnutChartOptions,
                    // onClick: (e) =>
                    //   handleChartClick("Total Sales by Products", e),
                    // Add other options here
                  }}
                />
              )}
            </div>
            <div style={chartStyle}>
              <h5
                style={{
                  textAlign: "center",
                  marginBottom: "4px",
                  marginTop: "-8px",
                }}
              >
                Productwise Customers Acquired
              </h5>
              {loadingProductwiseCustomer ? (
                loader
              ) : (
                <Bar
                  data={productwiseCustomerData}
                  // options={barChartOptions}
                  options={{
                    ...barChartOptions,
                    onClick: (e) =>
                      handleChartClick("Productwise Customer Aquired", e),
                    // Add other options here
                  }}
                />
              )}
            </div>
          </div>
          <br></br>
          <div style={chartsContainerStyle}>
            <div style={{ ...chartStyle }}>
              <h5
                style={{
                  textAlign: "center",
                  marginBottom: "4px",
                  marginTop: "-8px",
                }}
              >
                Yearwise Sales Revenue
              </h5>
              {loadingSalesRevenue ? (
                loader
              ) : (
                <Line
                  data={salesRevenueChartData}
                  // options={lineChartOptions}
                  options={{
                    ...lineChartOptions,
                    onClick: (e) =>
                      handleChartClick("Yearwise Sales Revenue", e),
                    // Add other options here
                  }}
                />
              )}
            </div>
            <div style={{ ...chartStyle }}>
              <div>
                <h5
                  style={{
                    textAlign: "center",
                    marginBottom: "4px",
                    marginTop: "-8px",
                  }}
                >
                  Top 10 Highest Sales Customers
                </h5>
                <div
                  style={{
                    height: "380px",
                    overflowY: "scroll",
                    border: "1px solid #ddd",
                    borderRadius: "10px",
                    padding: "10px",
                    backgroundColor: "#fff",
                  }}
                >
                  <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead
                      style={{ backgroundColor: "#49b8ea", color: "#fff" }}
                    >
                      <tr>
                        <th style={{ padding: "10px", textAlign: "center" }}>
                          Customer Name
                        </th>
                        <th style={{ padding: "10px", textAlign: "center" }}>
                          Customer Domain
                        </th>
                        <th style={{ padding: "10px", textAlign: "center" }}>
                          Total Sales
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {topSalesCustomers.map((customer, index) => (
                        <tr
                          key={customer.cust_id}
                          onClick={() => handleView(customer.cust_id)}
                          style={{ cursor: "pointer" }}
                        >
                          <td
                            style={{
                              padding: "10px",
                              borderBottom: "1px solid #ddd",
                            }}
                          >
                            {customer.customer_name}
                          </td>
                          <td
                            style={{
                              padding: "10px",
                              borderBottom: "1px solid #ddd",
                            }}
                          >
                            {customer.customer_domainname}
                          </td>
                          <td
                            style={{
                              padding: "10px",
                              borderBottom: "1px solid #ddd",
                              textAlign: "right",
                            }}
                          >
                            ₹{customer.total_sales.toLocaleString()}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showYearlCustomerModal}
        onHide={handleCloseCustomerDetailsModal}
        backdrop="static"
        keyboard={true}
        size="xl"
      >
        {loading && (
          <div className="loader-container">
            <div className="loader">
              <div className="tick"></div>
            </div>
          </div>
        )}
        <Modal.Header closeButton>
          <Modal.Title>
            Customers of <b>{label}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form-sample">
            <div className="form-group">
              <div className="row mt-4">
                <div
                  className="table-responsive"
                  style={{ maxHeight: "500px", overflowY: "auto" }}
                >
                  <table className="table table-hover sticky-header">
                    <thead>
                      <tr>
                        <th style={thStyles}>Customer Name</th>
                        <th style={thStyles}>Domain Name</th>
                        <th style={thStyles}>Customer EmailID</th>
                        <th style={thStyles}>Industry Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {yearwiseCustomerDetails.map((customer, index) => (
                        <tr key={index}>
                          <td>{customer.customer_name}</td>
                          <td>{customer.customer_domainname}</td>
                          <td>{customer.customer_emailid}</td>
                          <td>{customer.industry_type}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => handleCloseCustomerDetailsModal(false)}
          >
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>

      <Modal
        show={showCustomerCaseModal}
        onHide={handleCloseCustomerCaseDetailsModal}
        backdrop="static"
        keyboard={true}
        size="xl"
      >
        {loading && (
          <div className="loader-container">
            <div className="loader">
              <div className="tick"></div>
            </div>
          </div>
        )}
        <Modal.Header closeButton>
          <Modal.Title>
            Case Details of <b>{label}</b> Status
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form-sample">
            <div className="form-group">
              <div className="row mt-4">
                <div
                  className="table-responsive"
                  style={{ maxHeight: "500px", overflowY: "auto" }}
                >
                  <table className="table table-hover sticky-header">
                    <thead>
                      <tr>
                        <th style={thStyles}>Customer Name</th>
                        <th style={thStyles}>Case Name</th>
                        <th style={thStyles}>Customer EmailID</th>
                        <th style={thStyles}>Domain Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {customerCasesDetails.map((cases, index) => (
                        <tr key={index}>
                          <td>{cases.customer_name}</td>
                          <td>{cases.case_name}</td>
                          <td>{cases.customer_emailid}</td>
                          <td>{cases.customer_domainname}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => handleCloseCustomerDetailsModal(false)}
          >
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>

      <Modal
        show={showProductCustomerAquiredModal}
        onHide={handleCloseProductwiseCustAquiredDetailsModal}
        backdrop="static"
        keyboard={true}
        size="xl"
      >
        {loading && (
          <div className="loader-container">
            <div className="loader">
              <div className="tick"></div>
            </div>
          </div>
        )}
        <Modal.Header closeButton>
          <Modal.Title>
            Details of <b>{label}</b> Product
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form-sample">
            <div className="form-group">
              <div className="row mt-4">
                <div
                  className="table-responsive"
                  style={{ maxHeight: "500px", overflowY: "auto" }}
                >
                  <table className="table table-hover sticky-header">
                    <thead>
                      <tr>
                        <th style={thStyles}>Customer Name</th>
                        <th style={thStyles}>Customer Company Name</th>
                        <th style={thStyles}>Domain Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productwiseCustomerAquired.map((product, index) => (
                        <tr key={index}>
                          <td>{product.customer_name}</td>
                          <td>{product.customer_domainname}</td>
                          <td>{product.customer_company_name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => handleCloseCustomerDetailsModal(false)}
          >
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>

      <Modal
        show={showYearlySalesModal}
        onHide={handleCloseYearlySalesModal}
        backdrop="static"
        keyboard={true}
        size="xl"
      >
        {loading && (
          <div className="loader-container">
            <div className="loader">
              <div className="tick"></div>
            </div>
          </div>
        )}
        <Modal.Header closeButton>
          <Modal.Title>
            Sales of <b>{label}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form-sample">
            <div className="form-group">
              <div className="row mt-4">
                <div
                  className="table-responsive"
                  style={{ maxHeight: "500px", overflowY: "auto" }}
                >
                  <table className="table table-hover sticky-header">
                    <thead>
                      <tr>
                        <th style={thStyles}>Customer Name</th>
                        <th style={thStyles}>Customer Company Name</th>
                        <th style={thStyles}>Domain Name</th>
                        <th style={thStyles}>Total Sales</th>
                      </tr>
                    </thead>
                    <tbody>
                      {yearlySales.map((yearsale, index) => (
                        <tr key={index}>
                          <td>{yearsale.customer_name}</td>
                          <td>{yearsale.customer_company_name}</td>
                          <td>{yearsale.customer_domainname}</td>
                          <td>₹{yearsale.total_sales.toLocaleString()}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => handleCloseCustomerDetailsModal(false)}
          >
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>

      <Modal
        show={showCustProdSalesDetailsModal}
        onHide={handleCloseCustProdSalesModal}
        backdrop="static"
        keyboard={true}
        size="m"
      >
        {loading && (
          <div className="loader-container">
            <div className="loader">
              <div className="tick"></div>
            </div>
          </div>
        )}
        <Modal.Header closeButton>
          <Modal.Title>Sales Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form-sample">
            <div className="form-group">
              <div className="row mt-4">
                <div
                  className="table-responsive"
                  style={{ maxHeight: "500px", overflowY: "auto" }}
                >
                  <table className="table table-hover sticky-header">
                    <thead>
                      <tr>
                        <th style={thStyles}>Oem Name</th>
                        {/* <th style={thStyles}>Products Name</th> */}
                        <th style={thStyles}>Total Sales</th>
                      </tr>
                    </thead>
                    <tbody>
                      {custProdSalesDetails.map((custprodsale, index) => (
                        <tr key={index}>
                          <td>{custprodsale.oem_name}</td>
                          {/* <td>{custprodsale.product_name}</td> */}
                          <td>₹{custprodsale.total_sales.toLocaleString()}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => handleCloseCustomerDetailsModal(false)}
          >
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default ResellerDashboard;
