import React, { Fragment, useState, useEffect, useCallback } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import UserRegistrationForm from "./userregistrationform";
import CustomerRegister from "./registercustomer";
import CustomerList from "./customerlist";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import ProductPriceCalculator from "./productpricecalculator";
import ResellerDashBoard from "./resellerdashboard";
// import TransferCustomer from "./transfercustomers";
import CaseList from "./caseslists";
import OrderList from "./orderlists";
import RenewalList from "./renewallists";
import KnowledgePage from "./knowledge";
import UserQuotationDetails from "./quotationdetails";
import InvoiceDetails from "./invoicedetails";
import AddLicence from "./addlicence";
import CommisionDetails from "./commisiondetails";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReportsMaster from "./reports";

const UserMainComponent = () => {
  const isLoggedIn = true; // Example: Set to true if the user is logged in

  const [selectedOption, setSelectedOption] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isMobileView, setIsMobileView] = useState(false);
  const [isCustomerDetailsOpen, setIsCustomerDetailsOpen] = useState(false);
  const [isInvoiceDetailsOpen, setIsInvoiceDetailsOpen] = useState(false);
  const [isSupportOpen, setIsSupportOpen] = useState(false);

  const loginDataFromStorage = localStorage.getItem("userDetails");
  const parsedLoginData = JSON.parse(loginDataFromStorage);
  const reggistrationData = localStorage.getItem("loginData");
  const errormessage = localStorage.getItem("errorMessage");
  const parsedreggistrationData = JSON.parse(reggistrationData);

  useEffect(() => {
    if (!isLoggedIn) {
      setSelectedOption(""); // Clear selected option if not logged in
    } else if (parsedreggistrationData.kyc_status === null) {
      setSelectedOption("User Registration Details"); // Set to User Registration Details if kyc_status is null
    } else {
      setSelectedOption("Reseller Dashboard"); // Default to Reseller Dashboard for logged-in users with KYC approved
    }
  }, [isLoggedIn, parsedreggistrationData.kyc_status]);

  const handleOptionClick = (option) => {
    if (
      parsedreggistrationData.kyc_status === "Pending" &&
      [
        "Register Customer",
        "Customer List",
        "Reseller Dashboard",
        "Apply Products",
        "Request for Transfer Customer",
        "Case Lists",
        "Order Lists",
        "Renewal Lists",
        "Reports",
        "Knowledge",
        "Quotation Lists",
        "Invoice Lists",
        "Add Licence",
        "Reports",
        "Knowledge",
      ].includes(option)
    ) {
      alert(
        "Your registration details are in a pending state. You can't use this feature."
      );
    } else if (
      parsedreggistrationData.kyc_status === "Rejected" &&
      [
        "Register Customer",
        "Customer List",
        "Reseller Dashboard",
        "Apply Products",
        "Request for Transfer Customer",
        "Case Lists",
        "Order Lists",
        "Renewal Lists",
        "Reports",
        "Knowledge",
        "Quotation Lists",
        "Invoice Lists",
        "Add Licence",
        "Reports",
        "Knowledge",
      ].includes(option)
    ) {
      alert(
        "Your registration details are Rejected. So You can't use this feature."
      );
    } else if (
      parsedreggistrationData.kyc_status === null &&
      [
        "Register Customer",
        "Customer List",
        "Reseller Dashboard",
        "Apply Products",
        "Request for Transfer Customer",
        "Case Lists",
        "Order Lists",
        "Renewal Lists",
        "Reports",
        "Knowledge",
        "Quotation Lists",
        "Invoice Lists",
        "Add Licence",
        "Reports",
        "Knowledge",
      ].includes(option)
    ) {
      alert(
        "First, you need to register your KYC registration details. After approved by admin you will get access for all features."
      );
      setSelectedOption("User Registration Details");
    } else {
      setSelectedOption(option);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleCustomerDetails = () => {
    if (isCustomerDetailsOpen) {
      setIsCustomerDetailsOpen(false);
    } else {
      setIsCustomerDetailsOpen(true);
      setIsInvoiceDetailsOpen(false);
      setIsSupportOpen(false);
    }
  };

  const toggleInvoiceDetails = () => {
    if (isInvoiceDetailsOpen) {
      setIsInvoiceDetailsOpen(false);
    } else {
      setIsInvoiceDetailsOpen(true);
      setIsCustomerDetailsOpen(false);
      setIsSupportOpen(false);
    }
  };

  const toggleSupport = () => {
    if (isSupportOpen) {
      setIsSupportOpen(false);
    } else {
      setIsSupportOpen(true);
      setIsCustomerDetailsOpen(false);
      setIsInvoiceDetailsOpen(false);
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    handleResize(); // Check initial window width
    window.addEventListener("resize", handleResize); // Add event listener for resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up the event listener
    };
  }, []);

  // const logout = useCallback(() => {
  //   localStorage.removeItem("userDetails");
  //   localStorage.removeItem("loginData");
  //   localStorage.removeItem("errorMessage");
  //   window.location.href = "/session-timeout";
  // }, []);

  // useEffect(() => {
  //   let timeout;
  //   let alertTimeout;
  //   let alertShown = false;

  //   const showStayAlert = () => {
  //     if (alertShown) return;

  //     alertShown = true;
  //     const stayOnPage = window.confirm(
  //       "You have been inactive for a while. Do you want to stay on this page?"
  //     );

  //     if (stayOnPage) {
  //       resetTimeout();
  //     } else {
  //       logout();
  //     }

  //     alertShown = false;
  //   };

  //   const resetTimeout = () => {
  //     clearTimeout(timeout);
  //     clearTimeout(alertTimeout);
  //     timeout = setTimeout(showStayAlert, 100000); // 5 minutes in milliseconds
  //     alertTimeout = setTimeout(logout, 100000); // 10 minutes in milliseconds
  //   };

  //   const events = [
  //     "load",
  //     "mousemove",
  //     "mousedown",
  //     "click",
  //     "scroll",
  //     "keypress",
  //   ];

  //   events.forEach((event) => window.addEventListener(event, resetTimeout));

  //   resetTimeout();

  //   return () => {
  //     clearTimeout(timeout);
  //     clearTimeout(alertTimeout);
  //     events.forEach((event) =>
  //       window.removeEventListener(event, resetTimeout)
  //     );
  //   };
  // }, [logout]);

  return (
    <Fragment>
      <div
        className={`container-scroller ${isSidebarOpen ? "sidebar-open" : ""}`}
      >
        <Navbar data={parsedLoginData} isAdmin={false} />
        <div className="container-fluid page-body-wrapper">
          <nav
            className={`sidebar ${isSidebarOpen ? "sidebar-offcanvas" : ""}`}
            id="sidebar"
          >
            <ul className="nav">
              <li className="nav-item nav-profile">
                <a href="#" className="nav-link">
                  <div className="profile-image">
                    <img
                      className="img-xs rounded-circle"
                      src="assets/images/faces/face8.jpg"
                      alt="profile image"
                    />
                    <div className="dot-indicator bg-success"></div>
                  </div>
                  <div className="text-wrapper">
                    <p className="profile-name">
                      {parsedLoginData.reseller_name}
                    </p>
                    <p className="designation">
                      {parsedLoginData.reseller_type === "Direct"
                        ? "Reseller"
                        : "Indirect Reseller"}
                    </p>
                  </div>
                </a>
              </li>
              <li className="nav-item nav-category">Main Menu</li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="#"
                  onClick={() => handleOptionClick("Reseller Dashboard")}
                >
                  Dashboard
                </Link>
              </li>
              <li className="nav-item">
                <a
                  href="#"
                  className="nav-link"
                  onClick={toggleCustomerDetails}
                >
                  Customer Details{" "}
                  <FontAwesomeIcon
                    icon={isCustomerDetailsOpen ? faChevronUp : faChevronDown}
                    style={{ marginLeft: "9px" }}
                  />
                </a>
                {isCustomerDetailsOpen && (
                  <ul className="nav flex-column ml-3">
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="#"
                        onClick={() => handleOptionClick("Register Customer")}
                      >
                        <span style={{ fontSize: "25px", marginRight: "3px" }}>
                          &#8226;
                        </span>
                        Register Customer
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="#"
                        onClick={() => handleOptionClick("Customer List")}
                      >
                        <span style={{ fontSize: "25px", marginRight: "3px" }}>
                          &#8226;
                        </span>
                        Customers List
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="#"
                  onClick={() => handleOptionClick("Case Lists")}
                >
                  Cases Lists
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="#"
                  onClick={() => handleOptionClick("Apply Products")}
                >
                  Product Price Calculator
                </Link>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link" onClick={toggleInvoiceDetails}>
                  Invoices{" "}
                  <FontAwesomeIcon
                    icon={isInvoiceDetailsOpen ? faChevronUp : faChevronDown}
                    style={{ marginLeft: "9px" }}
                  />
                </a>
                {isInvoiceDetailsOpen && (
                  <ul className="nav flex-column ml-3">
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="#"
                        onClick={() => handleOptionClick("Quotation Lists")}
                      >
                        <span style={{ fontSize: "25px", marginRight: "3px" }}>
                          &#8226;
                        </span>
                        Quotation Lists
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="#"
                        onClick={() => handleOptionClick("Order Lists")}
                      >
                        <span style={{ fontSize: "25px", marginRight: "3px" }}>
                          &#8226;
                        </span>
                        Order Lists
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="#"
                        onClick={() => handleOptionClick("Invoice Lists")}
                      >
                        <span style={{ fontSize: "25px", marginRight: "3px" }}>
                          &#8226;
                        </span>
                        Invoice Lists
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="#"
                        onClick={() => handleOptionClick("Add Licence")}
                      >
                        <span style={{ fontSize: "25px", marginRight: "3px" }}>
                          &#8226;
                        </span>
                        Add Licence
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
              {/* {parsedLoginData.reseller_type === "Indirect" && (
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="#"
                    onClick={() => handleOptionClick("Commission Details")}
                  >
                    Commission Details
                  </Link>
                </li>
              )} */}
              {/* <li className="nav-item">
                <Link
                  className="nav-link"
                  to="#"
                  onClick={() => handleOptionClick("Renewal Lists")}
                >
                  Renewal Details
                </Link>
              </li> */}
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="#"
                  onClick={() => handleOptionClick("Reports")}
                >
                  Reports
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="#"
                  onClick={() => handleOptionClick("Knowledge")}
                >
                  Knowledge Base
                </Link>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link" onClick={toggleSupport}>
                  Support{" "}
                  <FontAwesomeIcon
                    icon={isSupportOpen ? faChevronUp : faChevronDown}
                    style={{ marginLeft: "9px" }}
                  />
                </a>
                {isSupportOpen && (
                  <ul className="nav flex-column ml-3">
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="#"
                        onClick={() => setSelectedOption("Chat Support")}
                      >
                        Chat Support
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="#"
                        onClick={() => setSelectedOption("Email Support")}
                      >
                        Email Support
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="#"
                        onClick={() => setSelectedOption("Call Support")}
                      >
                        Call Support
                      </a>
                    </li>
                  </ul>
                )}
              </li>
            </ul>
          </nav>
          <div className="main-panel">
            {errormessage && (
              <div className="alert alert-danger  my-2 py-1" role="alert">
                {errormessage}
              </div>
            )}
            {isMobileView && (
              <button className="sidebar-toggle-btn" onClick={toggleSidebar}>
                {isSidebarOpen ? (
                  <span>&#x2630;&#x2630;&#x2630;</span>
                ) : (
                  <span>&#x2630;&#x2630;&#x2630;</span>
                )}
              </button>
            )}
            <div className="content-wrapper">
              <div className="row page-title-header">
                <div className="col-12">
                  <div className="page-header">
                    <div className="quick-link-wrapper w-100 d-md-flex flex-md-wrap">
                      {/* <ul className="quick-links">
                        <h4 className="page-title">Shiviom Dashboard</h4>
                      </ul> */}
                    </div>
                  </div>
                </div>
              </div>
              {selectedOption === "Reseller Dashboard" && (
                <div className="customer-dashboard-form-wrapper">
                  <ResellerDashBoard />
                </div>
              )}
              {selectedOption === "Register Customer" && (
                <div className="customer-registration-form-wrapper">
                  <CustomerRegister />
                </div>
              )}
              {selectedOption === "User Registration Details" && (
                <div className="user-registration-form-wrapper">
                  <UserRegistrationForm />
                </div>
              )}
              {selectedOption === "Customer List" && (
                <div className="customer-list-form-wrapper">
                  <CustomerList />
                </div>
              )}
              {selectedOption === "Case Lists" && (
                <div className="case_lists-form-wrapper">
                  <CaseList />
                </div>
              )}
              {selectedOption === "Apply Products" && (
                <div className="apply-products-form-wrapper">
                  <ProductPriceCalculator />
                </div>
              )}
              {selectedOption === "Quotation Lists" && (
                <div className="quotation-lists-form-wrapper">
                  <UserQuotationDetails />
                </div>
              )}
              {selectedOption === "Order Lists" && (
                <div className="order-lists-form-wrapper">
                  <OrderList />
                </div>
              )}
              {selectedOption === "Renewal Lists" && (
                <div className="renewal-lists-form-wrapper">
                  <RenewalList />
                </div>
              )}
              {selectedOption === "Reports" && (
                <div className="reports-form-wrapper">
                  <ReportsMaster />
                </div>
              )}
              {selectedOption === "Knowledge" && (
                <div className="knowledge-form-wrapper">
                  <KnowledgePage />
                </div>
              )}
              {selectedOption === "Invoice Lists" && (
                <div className="invoice-form-wrapper">
                  <InvoiceDetails />
                </div>
              )}
              {selectedOption === "Add Licence" && (
                <div className="add-licence-form-wrapper">
                  <AddLicence />
                </div>
              )}
              {selectedOption === "Commission Details" && (
                <div className="commission-details-form-wrapper">
                  <CommisionDetails />
                </div>
              )}
            </div>
            <Footer />
          </div>
        </div>
      </div>
      <script src="assets/vendors/js/vendor.bundle.base.js"></script>
      <script src="assets/vendors/js/vendor.bundle.addons.js"></script>
      <script src="assets/js/shared/off-canvas.js"></script>
      <script src="assets/js/shared/misc.js"></script>
      <script src="assets/js/demo_1/dashboard.js"></script>
      <script
        src="assets/js/shared/jquery.cookie.js"
        type="text/javascript"
      ></script>
    </Fragment>
  );
};

export default UserMainComponent;
