import React, { Fragment, useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import {
  getAdminlist,
  get_logininvitationparameter,
  updateAdminsDetails,
  getResellersListAccMgr,
  getAccountMgrLists,
  assignAccountMgr,
} from "../../utils/api";
import LogininvitationManagement from "./logininvitationmanagement";

import { FaSortUp, FaSortDown, FaSort } from "react-icons/fa";

const AdminLists = () => {
  const [adminList, setAdminList] = useState([]);
  const [filteredAdminList, setFilteredAdminList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [adminDetails, setAdminDetails] = useState({
    admin_id: "",
    admin_name: "",
    admin_email: "",
    department: "",
    role: "",
    created_on: "",
    modules_details: [],
  });

  const [modulesList, setModulesList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [newModule, setNewModule] = useState("");
  const [originalAdminDetails, setOriginalAdminDetails] = useState(null);
  const [showCreateAdminModal, setShowCreateAdminModal] = useState(false);
  const [showViewAdminResellerModal, setShowViewAdminResellerModal] =
    useState(false);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [resellersList, setResellersList] = useState([]);
  const [loadingResellers, setLoadingResellers] = useState(false);
  const [errorResellers, setErrorResellers] = useState(null);
  const [accountManagerList, setAccountManagerList] = useState([]);
  const [selectedAccountManager, setSelectedAccountManager] = useState(""); // Add this line
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [bulkResellers, setBulkReselles] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state
  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const adminData = await getAdminlist();
        setAdminList(adminData);
        setFilteredAdminList(adminData);

        const loginParams = await get_logininvitationparameter();
        const { modules_name, department_name, role_names } =
          loginParams.data.data_result[0];
        setModulesList(modules_name);
        setDepartmentList(department_name);
        setRoleList(role_names);
      } catch (error) {
        console.error("Failed to fetch data:", error.message);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);
  useEffect(() => {
    async function fetchAdminList() {
      try {
        setLoading(true);
        const data = await getAdminlist();
        console.log(data);
        setAdminList(data);
        setFilteredAdminList(data);
      } catch (error) {
        console.error("Failed to fetch admin list:", error.message);
      } finally {
        setLoading(false);
      }
    }

    fetchAdminList();
  }, []);

  const handleSort = (column) => {
    let sortedList = [...filteredAdminList];
    if (sortColumn === column) {
      sortedList.reverse(); // Toggle the sorting order
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      sortedList.sort((a, b) => {
        if (a[column] < b[column]) return -1;
        if (a[column] > b[column]) return 1;
        return 0;
      });
      setSortColumn(column);
      setSortOrder("asc");
    }
    setFilteredAdminList(sortedList);
  };
  const handleSearchInputChange = (value) => {
    setSearchInput(value.toLowerCase());
    const filteredList = adminList.filter((admin) => {
      if (admin) {
        return (
          (admin.admin_name &&
            admin.admin_name.toLowerCase().includes(value)) ||
          (admin.admin_email &&
            admin.admin_email.toLowerCase().includes(value)) ||
          (admin.department &&
            admin.department.toLowerCase().includes(value)) ||
          (admin.role && admin.role.toLowerCase().includes(value))
        );
      }
      return false;
    });
    setFilteredAdminList(filteredList);
  };
  const handleShowCreateAdminModal = () => {
    setShowCreateAdminModal(true);
  };

  const handleCloseCreateAdminModal = () => {
    setShowCreateAdminModal(false);
  };

  const handleReset = () => {
    setSearchInput("");
    setFilteredAdminList(adminList);
  };

  const thStyles = {
    position: "sticky",
    top: 0,
    backgroundColor: "#f1f1f1",
    zIndex: 100,
  };

  const handleViewDetails = (admin) => {
    setSelectedAdmin(admin);
    setShowViewModal(true);
  };
  const handleAddNewModule = () => {
    if (newModule && !adminDetails.modules_details.includes(newModule)) {
      setAdminDetails({
        ...adminDetails,
        modules_details: [...adminDetails.modules_details, newModule],
      });
      setNewModule(""); // Reset the newModule state after adding
    }
  };
  const handleEditDetails = (admin) => {
    setShowViewModal(false);
    // Create a copy of the admin details
    const originalAdminDetails = { ...admin };

    // Set the admin details in the state
    setAdminDetails(admin);

    // Set the original admin details in state
    setOriginalAdminDetails(originalAdminDetails);

    // Set the showEditModal state to true
    setShowEditModal(true);
  };
  const handleViewResellers = async (admin) => {
    setLoadingResellers(true);
    setErrorResellers(null);
    try {
      setLoading(true);
      const resellersData = await getResellersListAccMgr(admin.admin_id);
      setResellersList(resellersData); // Adjust based on your API response structure
      setShowViewAdminResellerModal(true);
      // Fetch account manager list after resellers are loaded
      const fetchAccountManagerList = async () => {
        try {
          const accMgrList = await getAccountMgrLists();
          // Filter out the manager you want to exclude
          const filteredHistory = accMgrList.filter(
            (manager) => manager.admin_name !== selectedAdmin.admin_name
          );

          setAccountManagerList(filteredHistory);
          // setAccountManagerList(accMgrList);
        } catch (error) {
          window.alert("Error fetching account manager list:", error);
        } finally {
          setLoading(false);
        }
      };

      // Call fetchAccountManagerList
      fetchAccountManagerList();
    } catch (error) {
      setErrorResellers(error.message || "An unexpected error occurred.");
    } finally {
      setLoadingResellers(false);
    }
  };
  // Handle selection of account manager
  const handleAccountManagerSelect = (e) => {
    const selectedId = e.target.value;
    const manager = accountManagerList.find(
      (manager) => manager.admin_id === selectedId
    );
    setSelectedAccountManager(manager);
  };
  const handleCloseViewModal = () => {
    setSelectedAdmin(null);
    setShowViewModal(false);
  };
  const handleCloseResellerListModal = () => {
    setSelectAll(false);
    setSelectedRows([]);
    setSelectedAccountManager("");
    setShowViewAdminResellerModal(false);
  };

  const handleCloseEditModal = () => {
    setAdminDetails({
      admin_id: "",
      admin_name: "",
      admin_email: "",
      department: "",
      role: "",
      created_on: "",
      modules_details: [],
    });
    setShowEditModal(false);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setAdminDetails({ ...adminDetails, [name]: value });
  };

  const handleRemoveModule = (index) => {
    const modules = adminDetails.modules_details.filter((_, i) => i !== index);
    setAdminDetails({ ...adminDetails, modules_details: modules });
  };

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    // Add your save logic here, e.g., sending the updated details to the server
    handleCloseEditModal();
    const changes = {
      "Original Admin Details": originalAdminDetails,
      "Updated Admin Details": adminDetails,
    };
    try {
      const response = await updateAdminsDetails(changes);
      if (response.status === 200) {
        const data = await response.json();
        window.alert(data.message);
        // window.location.href = "/adminmaincomponent";
      } else {
        const data = await response.json();
        window.alert(data.message);
      }
    } catch (error) {
      window.alert("Error for updating admin details:", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle selecting a single row
  const handleRowSelect = (resellerId) => {
    if (selectedRows.includes(resellerId)) {
      setSelectedRows(selectedRows.filter((id) => id !== resellerId)); // Deselect row
    } else {
      setSelectedRows([...selectedRows, resellerId]); // Select row
    }
  };
  // Handle selecting all rows
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]); // Deselect all
    } else {
      const allResellerIds = resellersList.map(
        (reseller) => reseller.reseller_id
      ); // Select all case IDs
      setSelectedRows(allResellerIds);
    }
    setSelectAll(!selectAll); // Toggle select all state
  };
  const handleAssignAccountManager = async () => {
    try {
      setLoading(true); // Optional: Show a loading spinner if needed.

      if (!selectedAccountManager)
        return window.alert("Please Select the Account Manager");

      // Initialize a status object to track the result for each case
      const statusUpdates = {};
      // Loop through the selected case IDs and send reminders one by one
      for (let resellerId of selectedRows) {
        try {
          const response = await assignAccountMgr(
            selectedAccountManager.admin_id,
            resellerId
          ); // API call for each case

          // If the API returns 200, mark the status as 'Sent'
          if (response.status === 200) {
            statusUpdates[resellerId] = "Transfered";
          } else {
            statusUpdates[resellerId] = "Failed"; // Any other status mark as 'Failed'
          }
        } catch (error) {
          statusUpdates[resellerId] = "Failed"; // If error occurs during the API call
        }
      }

      // // Update the state with the updated case statuses
      // setBulkReminderCases(updatedCases);

      window.alert("Reseller Transfered Successfully");
      handleViewResellers(selectedAdmin);
      setSelectedAccountManager("");
    } catch (error) {
      console.error("Error sending bulk reminders:", error);
      alert("An error occurred while sending reminders.");
    } finally {
      setLoading(false); // Hide the loading spinner
    }
  };

  return (
    <Fragment>
      <style>
        {`
          .sortable-header {
            cursor: pointer;
            position: relative;
          }

          .sort-icon {
            margin-left: 5px;
            color: #6c757d; /* Default color */
            transition: color 0.2s ease;
          }

          .sortable-header:hover .sort-icon {
            color: #007bff; /* Change this to your preferred hover color */
          }

          .table-responsive {
            max-height: 500px;
            overflow-y: auto;
          }
        `}
      </style>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="mb-1">Admin Lists</h4>
            <br></br>
            <div className="search-container mb-3">
              <input
                type="text"
                placeholder="Search"
                value={searchInput}
                onChange={(e) => handleSearchInputChange(e.target.value)}
                style={{ float: "left" }}
              />
              <button
                className="btn btn-primary ml-2"
                onClick={handleReset}
                style={{ float: "left" }}
              >
                Reset
              </button>
              <button
                className="btn btn-primary ml-2"
                style={{ float: "right" }}
                onClick={handleShowCreateAdminModal}
              >
                Create Admin Delegation
              </button>
            </div>
            <br></br>
            <div
              className="table-responsive"
              style={{ maxHeight: "500px", overflowY: "auto" }}
            >
              <table className="table table-hover sticky-header">
                <thead>
                  <tr>
                    {/* <th style={thStyles}>Admin ID</th> */}
                    <th
                      style={thStyles}
                      className="sortable-header"
                      onClick={() => handleSort("admin_name")}
                    >
                      Admin Name
                      {sortColumn === "admin_name" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" />
                      )}
                    </th>
                    <th
                      style={thStyles}
                      className="sortable-header"
                      onClick={() => handleSort("admin_email")}
                    >
                      Admin EmailID
                      {sortColumn === "admin_email" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" />
                      )}
                    </th>
                    <th style={thStyles}>Department</th>
                    <th style={thStyles}>Role</th>
                    <th style={thStyles}>Created On</th>
                    {/* <th style={thStyles}>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {filteredAdminList.map((admin) => (
                    <tr
                      key={admin.admin_id}
                      onClick={() => handleViewDetails(admin)}
                      style={{ cursor: "pointer" }}
                    >
                      {/* <td>{admin.admin_id}</td> */}
                      <td>{admin.admin_name}</td>
                      <td>{admin.admin_email}</td>
                      <td>{admin.department}</td>
                      <td>{admin.role}</td>
                      <td>{new Date(admin.created_on).toLocaleString()}</td>
                      {/* <td>{renderActionButtons(admin)}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* View Modal */}
      <Modal show={showViewModal} onHide={handleCloseViewModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Admin Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedAdmin && (
            <div>
              <p>
                <strong>Admin ID:</strong> {selectedAdmin.admin_id}
              </p>
              <p>
                <strong>Admin Name:</strong> {selectedAdmin.admin_name}
              </p>
              <p>
                <strong>Admin Email:</strong> {selectedAdmin.admin_email}
              </p>
              <p>
                <strong>Department:</strong> {selectedAdmin.department}
              </p>
              <p>
                <strong>Role:</strong> {selectedAdmin.role}
              </p>
              <p>
                <strong>Created On: </strong>
                {new Date(selectedAdmin.created_on).toLocaleString()}
              </p>
              <p>
                <strong>Assigned Modules:</strong>{" "}
                {selectedAdmin.modules_details.join(", ")}
              </p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {selectedAdmin?.role === "Account Manager" && (
            <Button
              variant="primary"
              onClick={() => handleViewResellers(selectedAdmin)}
            >
              View Resellers
            </Button>
          )}
          <Button
            variant="primary"
            onClick={() => handleEditDetails(selectedAdmin)}
          >
            Edit Admin Details
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Modal */}
      <Modal show={showEditModal} onHide={handleCloseEditModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Admin Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Admin ID</Form.Label>
              <Form.Control
                type="text"
                name="admin_id"
                value={adminDetails.admin_id}
                onChange={handleEditChange}
                readOnly
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Admin Name</Form.Label>
              <Form.Control
                type="text"
                name="admin_name"
                value={adminDetails.admin_name}
                onChange={handleEditChange}
                disabled
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Admin Email</Form.Label>
              <Form.Control
                type="email"
                name="admin_email"
                value={adminDetails.admin_email}
                onChange={handleEditChange}
                disabled
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Department</Form.Label>
              <Form.Control
                as="select"
                name="department"
                value={adminDetails.department}
                onChange={handleEditChange}
              >
                <option value="">Select Department</option>
                {departmentList.map((dept, index) => (
                  <option key={index} value={dept}>
                    {dept}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Role</Form.Label>
              <Form.Control
                as="select"
                name="role"
                value={adminDetails.role}
                onChange={handleEditChange}
              >
                <option value="">Select Role</option>
                {roleList.map((role, index) => (
                  <option key={index} value={role}>
                    {role}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Assigned Modules</Form.Label>
              {adminDetails.modules_details.map((module, index) => (
                <div key={index} className="d-flex align-items-center mb-2">
                  <span className="mr-2">{module}</span>
                  <span
                    variant="danger"
                    onClick={() => handleRemoveModule(index)}
                    style={{ color: "red", cursor: "pointer" }}
                  >
                    ✘
                  </span>
                </div>
              ))}
              <div className="d-flex align-items-center mb-2">
                <Form.Control
                  as="select"
                  value={newModule}
                  onChange={(e) => setNewModule(e.target.value)}
                  className="mr-2"
                >
                  <option value="">Select Module</option>
                  {modulesList.map((moduleName, i) => (
                    <option key={i} value={moduleName}>
                      {moduleName}
                    </option>
                  ))}
                </Form.Control>
                <Button
                  variant="primary"
                  disabled={!newModule}
                  onClick={handleAddNewModule}
                  title="Add Module"
                >
                  Add
                </Button>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEditModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showCreateAdminModal}
        onHide={handleCloseCreateAdminModal}
        size="xl"
        style={{ width: "102%" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Admin Delegation</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "82vh", overflowY: "hidden" }}>
          <LogininvitationManagement
            handleClose={handleCloseCreateAdminModal}
          />
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleCloseCreateAdminModal}>
            Close
          </Button> */}
        </Modal.Footer>
      </Modal>
      <Modal
        show={showViewAdminResellerModal}
        onHide={handleCloseResellerListModal}
        size="m"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Resellers List of "{selectedAdmin?.admin_name}"
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loadingResellers ? (
            <div className="text-center">
              <span>Loading resellers...</span>
            </div>
          ) : errorResellers ? (
            <div className="alert alert-danger" role="alert">
              {errorResellers}
            </div>
          ) : resellersList.length > 0 ? (
            <div
              className="table-responsive"
              style={{ maxHeight: "400px", overflowY: "auto" }}
            >
              <table className="table table-hover sticky-header">
                <thead>
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />{" "}
                    </th>
                    <th>Reseller Name</th>
                    <th>Reseller Email</th>
                    {/* Add more columns as needed */}
                  </tr>
                </thead>
                <tbody>
                  {resellersList.map((reseller) => (
                    <tr key={reseller.reseller_id}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedRows.includes(reseller.reseller_id)}
                          onChange={() => handleRowSelect(reseller.reseller_id)}
                        />
                      </td>
                      <td>{reseller.reseller_name}</td>
                      <td>{reseller.reseller_email}</td>
                      {/* Add more cells as needed */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="text-center">
              <span>No resellers found for this Account Manager.</span>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col">
                <Form.Group controlId="accountManagerSelect" className="mb-0">
                  {/* <Form.Label>Select Account Manager</Form.Label> */}
                  <Form.Control
                    as="select"
                    onChange={handleAccountManagerSelect}
                    defaultValue=""
                  >
                    <option value="">Select an Account Manager</option>
                    {accountManagerList.map((manager) => (
                      <option key={manager.admin_id} value={manager.admin_id}>
                        {manager.admin_name} ({manager.region})
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>
              <div className="col-auto">
                <Button
                  variant="primary"
                  title="Transfer Reseller to another Account Manager"
                  disabled={selectedRows.length === 0}
                  onClick={handleAssignAccountManager}
                >
                  Transfer Reseller
                </Button>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default AdminLists;
