import React, { Fragment, useState, useEffect } from "react";
import {
  getCommisionDetails,
  getTotalResellerCommision,
} from "../../utils/api";
import { Modal } from "react-bootstrap";

const CommisionDetails = () => {
  const [commissions, setCommisions] = useState([]);
  const [filteredCommisions, setFilteredCommisions] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [email, setEmail] = useState("");
  const [selectedCommision, setSelectedCommision] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [totalEarnedCommision, setTotalEarnedCommision] = useState(null);
  const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   document.title = "Order Lists"; // Set the document title
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const loginDataFromStorage = localStorage.getItem("userDetails");
        if (loginDataFromStorage) {
          const parsedLoginData = JSON.parse(loginDataFromStorage);
          setEmail(parsedLoginData.reseller_email);
          const response = await getCommisionDetails(
            parsedLoginData.reseller_email
          );
          setCommisions(response.data.data_result);
          setFilteredCommisions(response.data.data_result);
          // Fetch total earned commission
          const totalCommisionResponse = await getTotalResellerCommision(
            parsedLoginData.reseller_email
          );
          const totalcomm = await totalCommisionResponse.json();
          setTotalEarnedCommision(parseFloat(totalcomm.data.pr_totalcommision));
        }
      } catch (error) {
        console.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSearchInputChange = (value) => {
    setSearchInput(value);
    const filteredList = commissions.filter((commision) => {
      if (commision && commision.domain_name) {
        return commision.domain_name
          .toLowerCase()
          .includes(value.toLowerCase());
      } else {
        return false;
      }
    });
    setFilteredCommisions(filteredList);
  };

  const handleReset = () => {
    setSearchInput("");
    setFilteredCommisions(commissions);
  };

  const handleViewClick = (commision) => {
    setSelectedCommision(commision);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedCommision(null);
  };

  const thStyles = {
    position: "sticky",
    top: 0,
    backgroundColor: "#f1f1f1",
    zIndex: 100,
  };
  const getStatusStyle = (status) => {
    let style = {};
    if (status === null) {
      return style;
    }
    switch (status.toLowerCase()) {
      case "pending":
        style = { color: "orange", fontWeight: "bold" };
        break;
      case "accepted":
        style = { color: "green", fontWeight: "bold" };
        break;
      case "rejected":
        style = { color: "red", fontWeight: "bold" };
        break;
      case "done":
        style = { color: "green", fontWeight: "bold" };
        break;
      default:
        break;
    }
    return style;
  };

  return (
    <Fragment>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="mb-1">Commision Details</h4>
            <br />
            <div className="search-container mb-3">
              <input
                type="text"
                placeholder="Search by domain name..."
                value={searchInput}
                onChange={(e) => handleSearchInputChange(e.target.value)}
                style={{ float: "left" }}
              />
              <button
                className="btn btn-primary ml-2"
                onClick={handleReset}
                style={{ float: "left" }}
              >
                Reset
              </button>
              <div
                style={{
                  float: "right",
                  backgroundColor: "#f8f9fa", // Light background color
                  padding: "10px 15px", // Padding around the text
                  borderRadius: "5px", // Rounded corners
                  color: "#007bff", // Text color
                  fontWeight: "bold", // Bold text
                  border: "1px solid #007bff", // Border color matching text
                }}
              >
                Your Total Earned Commission:{" "}
                {totalEarnedCommision !== null
                  ? totalEarnedCommision
                  : "Loading..."}
              </div>
            </div>
            <br></br>
            <div
              className="table-responsive"
              style={{ maxHeight: "500px", overflowY: "auto" }}
            >
              <table className="table table-hover sticky-header">
                <thead>
                  <tr>
                    <th style={thStyles}>Case Name</th>
                    <th style={thStyles}>Customer Domain</th>
                    <th style={thStyles}>Commission Percent(%)</th>
                    <th style={thStyles}>Total Amount</th>
                    <th style={thStyles}>Commission Amount</th>
                    <th style={thStyles}>Order Status</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredCommisions.map((commision) => (
                    <tr
                      key={commision.case_name}
                      onClick={() => handleViewClick(commision)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{commision.case_name}</td>
                      <td>{commision.customer_domainname}</td>
                      <td>{commision.commsion_percent}</td>
                      <td>{commision.total_price}</td>
                      <td>{commision.commision_amount}</td>
                      <td style={getStatusStyle(commision.order_status)}>
                        {commision.order_status}
                      </td>{" "}
                      {/* Format the price */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {selectedCommision && (
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          size={
            selectedCommision.products_details.some(
              (commp) => commp.voucheraddedcommision !== "0.00"
            )
              ? "xl" // Use "xl" for more columns
              : "l" // Use "lg" for fewer columns
          }
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Case Name{" - "}
              {selectedCommision.case_name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="form-sample">
              <div className="row justify-content-center">
                <div className="row mt-4">
                  <div className="col-12">
                    <h5>Product Details</h5>
                    {selectedCommision.voucher_type != null && (
                      <div
                        style={{
                          padding: "15px",
                          border: "1px solid #ddd",
                          borderRadius: "5px",
                          backgroundColor: "#f9f9f9",
                        }}
                      >
                        <div
                          style={{ fontWeight: "bold", marginBottom: "10px" }}
                        >
                          Applied Additional Voucher Details:
                        </div>
                        <div style={{ marginBottom: "5px" }}>
                          <span style={{ fontWeight: "bold" }}>
                            Voucher Type:
                          </span>{" "}
                          {selectedCommision.voucher_type}
                        </div>
                        <div style={{ marginBottom: "5px" }}>
                          <span style={{ fontWeight: "bold" }}>
                            Voucher Value:
                          </span>{" "}
                          {selectedCommision.voucher_content}
                        </div>
                        {/* <div style={{ marginBottom: "5px" }}>
                          <span style={{ fontWeight: "bold" }}>
                            Voucher Redeem Date:
                          </span>{" "}
                          {selectedCommision.reedem_date}
                        </div> */}
                        <div style={{ marginBottom: "5px" }}>
                          <span style={{ fontWeight: "bold" }}>
                            Voucher Applied Commission:
                          </span>{" "}
                          {selectedCommision.voucher_applied_total_commision}
                        </div>
                      </div>
                    )}
                    <div
                      style={{
                        maxHeight: "200px",
                        overflowY: "auto",
                        border: "2px solid #000",
                      }}
                    >
                      <table
                        className="table text-center"
                        style={{ width: "100%" }}
                      >
                        <thead>
                          <tr
                            style={{
                              position: "sticky",
                              top: 0,
                              zIndex: 100,
                              backgroundColor: "#dee2e6",
                            }}
                          >
                            <th>Sku Name</th>
                            <th>No.of Licence</th>
                            <th>Unit Price</th>
                            <th>Commision</th>
                            {selectedCommision.products_details.some(
                              (commp) => commp.voucheraddedcommision !== "0.00"
                            ) && <th>Voucher Commision</th>}
                          </tr>
                        </thead>
                        <tbody style={{ paddingTop: "30px" }}>
                          {selectedCommision.products_details.map(
                            (commp, index) => (
                              <tr key={index}>
                                <td>{commp.skuname}</td>
                                <td>{commp.usercount}</td>
                                <td>
                                  {Number(commp.actualprice).toLocaleString()}
                                </td>
                                <td>
                                  {Number(
                                    commp.commisionprice
                                  ).toLocaleString()}
                                </td>
                                {selectedCommision.voucher_type !== null &&
                                  commp.voucheraddedcommision !== "0.00" && (
                                    <td>{commp.voucheraddedcommision}</td>
                                  )}
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <br></br>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      )}
    </Fragment>
  );
};

export default CommisionDetails;
