import logo from "./logo.svg";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "./public/assets/css/loader.css";

import AdminLogin from "../src/adminPanel/pages/adminlogin";
import AdminSignup from "../src/adminPanel/pages/adminsignup";
import AdminForgetPass from "./adminPanel/pages/adminforgetpass";
import AdminMainComponent from "./adminPanel/pages/adminmaincomponent";
import RegisteredUsers from "./adminPanel/pages/resgisteredUsers";
import VerifyUserRegisterDetails from "./adminPanel/pages/verifyuserregistrationdetails";
import ResetPassword from "./adminPanel/pages/adminresetpass";
import UserLogin from "./userPanel/pages/userlogin";
import userForgotPasswordForm from "./userPanel/pages/userforgetpass";
import UserSignupForm from "./userPanel/pages/usersignup";
import UserResetPassword from "./userPanel/pages/userresetpass";
import UserMainComponent from "./userPanel/pages/usermaincomponent";
import UserRegistrationForm from "./userPanel/pages/userregistrationform";
import CustomerRegister from "./userPanel/pages/registercustomer";
import CustomerList from "./userPanel/pages/customerlist";
import CaseList from "./userPanel/pages/caseslists";
import OrderList from "./userPanel/pages/orderlists";
import RenewalList from "./userPanel/pages/renewallists";
import SessionTimeout from "./userPanel/pages/sessiontimeout";
import AdminAssignProduct from "./adminPanel/pages/assignproduct";

import ApplyProducts from "./userPanel/pages/productpricecalculator";
import ProductMaster from "./adminPanel/pages/productmaster";
import Changepassword from "./adminPanel/pages/changepasswordfirsttime";

import AdminDashBoard from "./adminPanel/pages/admindashboard";
import ResellerDashboard from "./userPanel/pages/resellerdashboard";
import MaintenancePage from "./maintenancepage";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          {/* <Route path="/userlogin" component={MaintenancePage} /> */}
          <Route path="/userlogin" component={UserLogin} />
          <Route
            path="/userforgetpass"
            exact
            component={userForgotPasswordForm}
          />
          <Route path="/usersignup" component={UserSignupForm} />
          <Route path="/userreset" component={UserResetPassword} />
          <Route
            path="/userregistration"
            exact
            component={UserRegistrationForm}
          />
          <Route
            path="/usermaincomponent"
            exact
            component={UserMainComponent}
          />
          <Route
            path="/usercustomerregister"
            exact
            component={CustomerRegister}
          />
          <Route path="/usercustomerlist" exact component={CustomerList} />
          <Route path="/caseslist" component={CaseList} />
          <Route path="/orderslist" component={OrderList} />
          <Route path="/renewallist" component={RenewalList} />
          {/* <Route path="/userCustomerList" exact component={CustomerList} /> */}
          <Route path="/applyproducts" exact component={ApplyProducts} />
          <Route path="/session-timeout" component={SessionTimeout} />
          {/* <Route path="/adminlogin" component={MaintenancePage} /> */}
          <Route path="/adminlogin" component={AdminLogin} />
          <Route path="/adminsignup" component={AdminSignup} />
          <Route path="/adminforgetpass" component={AdminForgetPass} />
          <Route path="/adminresetpass" component={ResetPassword} />
          <Route path="/adminmaincomponent" component={AdminMainComponent} />
          <Route path="/assignproducts" component={AdminAssignProduct} />
          <Route path="/registeredusers" component={RegisteredUsers} />
          <Route
            path="/verifyuserdetails"
            component={VerifyUserRegisterDetails}
          />
          <Route path="/admindashbaord" component={AdminDashBoard} />
          <Route path="/resellerdashbaordview" component={ResellerDashboard} />
          <Route path="/productmaster" component={ProductMaster} />
          <Route path="/adminchangepassword" component={Changepassword} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
